import {
    STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_REQUEST,
    STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_SUCCESS,
    STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_FAILURE,
    STAFF_DIRECTORY_EVENTS_HANDLE_INPUT_CHANGE,
} from '../../constants/actionTypesStaffCompanyDetails';
import withQuery from 'with-query';
import fetchlib from '../../../js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../config';

export function staffCompanyDetailsGetEventAttended(companyId, keyword, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getEventsAttendedByCompanyId',
        });
        const body = {
            keyword: keyword || '',
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            companyId: parseInt(companyId),
            sorted,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_REQUEST,
            STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_SUCCESS,
            STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_FAILURE,
        );
    };
}
export function staffCompanyDetailsHandleInputChange(value) {
    return {
        type: STAFF_DIRECTORY_EVENTS_HANDLE_INPUT_CHANGE,
        value,
    };
}

import {
    CYDER_LOGIN_SUCCESS_SET_PROFILE,
    CYDER_PROFILE_SET_ADMIN_MODE,
    CYDER_PROFILE_REMOVE_ADMIN_MODE,
    CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE,
} from '../../actions/constants/actionTypes';

// defaults
const profileDefaultState = {
    user: {},
    validation: {},
    profile: {},
};

export default function profileReducer(state = profileDefaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case CYDER_LOGIN_SUCCESS_SET_PROFILE:
            newState.profile = Object.assign({ logintime: new Date().getTime() }, action.data);
            newState.validation = Object.assign({}, state.validation);
            newState.user = Object.assign({}, action.data);
            newState.authorizationToken = action.authorizationToken;
            return newState;
        case CYDER_PROFILE_SET_ADMIN_MODE:
            newState.adminMode = true;
            return newState;
        case CYDER_PROFILE_REMOVE_ADMIN_MODE:
            newState.adminMode = false;
            return newState;
        case CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE:
            newState.profile = {};
            newState.adminMode = false;
            newState.authorizationToken = null;
            newState.user = {};
            return newState;
        default:
            return state;
    }
}

/**
 * Action
 */
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';

export function getCompanyInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'getcompanyinfobyuser',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getPersonInfo(personId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'findbyid',
            id: personId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function updatePassword(password) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/users`, {
            action: 'update-logged-in-password',
        });

        var data = {
            password: password,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

// --- below is just sample
export function load(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-type`, {
            action: 'find-by-id',
            id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function update(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-type`, {
            action: 'update',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getNumberOfRegisteredAttendeeCountAnalytic } from 'actions/analytics/eventsAnalyticsAction';

class TotalRegisteredAttendees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    componentDidMount() {
        this.props.load().then(res => {
            this.setState({
                data: res.data[0],
            });
        });
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.events.refresh) {
            this.props.load().then(res => {
                this.setState({
                    data: res.data[0],
                    refreshState: this.props.events.refresh,
                });
            });
        }
    }

    render() {
        return (
            <AnalyticsCard title={`Total Registrants`} height={100}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <AnalyticsBigText text={this.state.data.totalRegisteredAttendees} textColor={`#ffac0a`} />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        events: state.eventsAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getNumberOfRegisteredAttendeeCountAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(TotalRegisteredAttendees));

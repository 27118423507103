/**
 * Security action creators
 *
 * Author: Fernando
 */
import {
    CYDER_LOGIN_REQUEST,
    CYDER_LOGIN_SUCCESS,
    CYDER_LOGIN_FAILURE,
    CYDER_LOGIN_SUCCESS_SET_PROFILE,
    CYDER_LOGIN_SUCCESS_MOCK_MEMBERSHIP,
    CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE,
    CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE,
    CYDER_CHANGE_PASSWORD_PAGE_CLEAR_MESSAGE,
    CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE,
    CYDER_LOGIN_PAGE_RESET_STATE,
    CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE,
    CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG,
    CYDER_FORGOT_PASSWORD_REQUEST,
    CYDER_FORGOT_PASSWORD_SUCCESS,
    CYDER_FORGOT_PASSWORD_FAILURE,
    CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE,
    CYDER_LOGIN_PAGE_GET_COMPANIES_REQUEST,
    CYDER_LOGIN_PAGE_GET_COMPANIES_SUCCESS,
    CYDER_LOGIN_PAGE_GET_COMPANIES_FAILURE,
} from '../constants/actionTypes';

import withQuery from 'with-query';
import fetchlib from '../../js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_KEY, SGBC_API_CORS_ROOT } from '../../config';

// import {
//     personalProfileGetProfileInfo,
//     personalProfileSaveProfileInfo,
//     personalProfileResetToDefault,
//     personalProfileHandleInputChange,
//     personalProfileSetModalData,
// } from 'actions/profile/personalProfileAction';

/**
 * Login.js
 */
export function cyderLoginAction(data) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_LOGIN_REQUEST });
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/auth`, {
            action: 'login',
        });
        const options = {
            headers: {
                'x-api-key': SGBC_API_KEY,
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
            method: 'POST',
        };
        return fetchlib
            .fetchV2(dispatch, getState, null, url, null, CYDER_LOGIN_REQUEST, CYDER_LOGIN_SUCCESS, CYDER_LOGIN_FAILURE, options)
            .then(response => {
                dispatch({
                    type: CYDER_LOGIN_SUCCESS_SET_PROFILE,
                    data: response.data,
                    authorizationToken: response.authorizationToken,
                });
                dispatch({
                    type: CYDER_LOGIN_SUCCESS_MOCK_MEMBERSHIP,
                    username: response.data ? response.data.username : null,
                });
                return response;
            });
    };
}

export function getCompanies(input) {
    return (dispatch, getState) => {
        let url_path = `${SGBC_API_CORS_ROOT}/companies_v1/cn/public-companies`;

        if (SGBC_API_CORS_ROOT.includes('sgbc.cyder.com.sg')) {
            url_path = `${SGBC_API_CORS_ROOT}/companies_v1/public-companies`;
        }

        const url = withQuery(url_path, {
            action: 'get-all',
        });

        const params = {
            keyword: input,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function getCompaniesAsActiveMembers(input) {
    return (dispatch, getState) => {
        let url_path = `${SGBC_API_CORS_ROOT}/companies_v1/cn/public-companies`;

        if (SGBC_API_CORS_ROOT.includes('sgbc.cyder.com.sg')) {
            url_path = `${SGBC_API_CORS_ROOT}/companies_v1/public-companies`;
        }

        const url = withQuery(url_path, {
            action: 'get-all',
        });

        const params = {
            keyword: input,
            member: 1,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function loginPageGetCompaniesAction(input) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_LOGIN_PAGE_GET_COMPANIES_REQUEST });

        const url = withQuery(SGBC_API_CORS_ROOT + '/companies_v1/cn/companies', {
            action: 'findbykeyword',
        });
        const body = {
            keyword: input,
        };
        const options = {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({
                            type: CYDER_LOGIN_PAGE_GET_COMPANIES_FAILURE,
                            error,
                        });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({
                            type: CYDER_LOGIN_PAGE_GET_COMPANIES_SUCCESS,
                            response,
                        });
                        let newRes = [];
                        response.data.forEach(each => {
                            newRes.push({
                                value: each.id,
                                label: each.name,
                                companyname: each.name,
                                uen_regno: each.registration_number,
                                country_id: each.country_id,
                                company_id: each.id,
                            });
                        });
                        return newRes;
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: CYDER_LOGIN_PAGE_GET_COMPANIES_FAILURE,
                    error,
                });
                return error;
            });
    };
}
/**
 * Logout function
 *
 * @param {*} fields
 * @param {*} callback
 */
export function cyderLoginSetErrorMessageAction(errorMessage) {
    return {
        type: CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE,
        errorMessage,
    };
}

export function cyderLoginClearErrorMessageAction() {
    return {
        type: CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE,
    };
}
export function cyderChangePasswordPageSetMessageAction(message) {
    return {
        type: CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE,
        message,
    };
}
export function cyderChangePasswordPageClearMessageAction() {
    return {
        type: CYDER_CHANGE_PASSWORD_PAGE_CLEAR_MESSAGE,
    };
}
export function cyderLogoutAction() {
    return function(dispatch, getState) {
        // call api to invalidate tokens
        // on success, remove profile from user
        dispatch({ type: CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE });
        dispatch({ type: CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG });
        return Promise.resolve();
    };
}
export function cyderResetProfileAction() {
    return { type: CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE };
}
export function cyderLoginResetState() {
    return {
        type: CYDER_LOGIN_PAGE_RESET_STATE,
    };
}

/**
 * ForgotPassword.js
 */
export function cyderForgotPasswordAction(email) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_FORGOT_PASSWORD_REQUEST });
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/auth`, {
            action: 'forgotpassword',
        });
        const data = {
            emailaddress: email,
            system: 'djarvis',
        };
        const options = {
            headers: {
                'x-api-key': SGBC_API_KEY,
                'content-type': 'application/json',
            },
            body: JSON.stringify(data),
            method: 'POST',
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    response.json().then(res => {
                        dispatch({ type: CYDER_FORGOT_PASSWORD_FAILURE, message: res.errormessage });
                    });
                } else {
                    response.json().then(res => {
                        dispatch({
                            type: CYDER_FORGOT_PASSWORD_SUCCESS,
                            userData: res,
                        });
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: CYDER_FORGOT_PASSWORD_FAILURE,
                    message: error.message,
                });
                return error;
            });
    };
}
export function cyderForgotPasswordPageSetMessageAction(message) {
    return {
        type: CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE,
        message,
    };
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import moment from 'moment';

// COMPONENT
import { Collapse, Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import Select from 'react-select';
import CyderReactTable from 'cyder/table/CyderReactTable';

// ACTIONS
import {
    getCategoryList,
    getAllOrganisationSubCategory,
    getAllOrganisationalCategory,
} from 'actions/member/certification/applications/memberCertNewApplAction';
import { getDataList } from 'actions/staff/membership/export/staffMembershipExportAction';

import 'react-dates/initialize';
import { exportCSVFile } from 'js/jsonToCsvExport';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const defaultState = {
    // GENERAL
    loading: false,
    isExport: false,
    isOpen: true,
    tableList: [],
    totalNum: 0,
    totalPages: 1,
    // PERIOD INPUT
    periodOptions: [
        { value: 'By Date Range', checked: false },
        { value: 'By Year', checked: false },
    ],
    focusedInput: null,
    issuedFrom: {
        id: 'Issued From',
        focused: false,
        date: null,
        placeholder: 'Issued From',
    },
    issuedTo: {
        id: 'Issued To',
        focused: false,
        date: null,
        placeholder: 'Issue To',
    },
    yearOptions: [],
    issuedByYear: null,
    // OPTIONS
    organisationalServiceTierOptions: [],
    organisationProfileOptions: [],
    organisationProfile: null,
    organisationalCategoryOptions: [],
    organisationalCategory: null,
    organisationalService: null,
    SMEOptions: [],
    isSME: null,
    SGCompanyOptions: [],
    isSGCompany: null,
    membershipStatusOptions: [],
    membershipStatus: null,
    membershipDurationOptions: [],
    membershipDuration: null,
    //CERIFICATION STATUS INPUT
    certStatusOptions: [],
    certStatus: null,
    //CERIFICATION TYPE INPUT
    certTypeOptions: [],
    certType: null,
    //MEMBERSHIP STATUS INPUT
    membershipStatusOptions: [],
    membershipStatus: null,
};

class DynamicExportPanel extends Component {
    table = React.createRef();
    state = defaultState;
    // LIFECYCLE
    componentDidMount() {
        this.getRelevantData();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.activeTab !== this.props.activeTab) {
            this.clearState().then(() => {
                this.getRelevantData();
                this.setState({ isOpen: true });
            });
        }
    }
    // DATA GETTER
    getRelevantData = () => {
        this.showLoading(true);
        const tasks = [
            this.getAllOrganisationalCategory(),
            this.getAllOrganisationSubCategory(),
            this.generateMembershipDurationList(),
            this.generateSGCompanyList(),
            this.generateSMEList(),
            this.generateYears(2000),
            this.generateMembershipStatusList(),
            this.generateProductServiceCertList(),
            this.generateEventAttendedList(),
            this.generateOrganisationProfileList(),
        ];
        return Promise.all(tasks).then(res => this.showLoading(false));
    };
    getAllOrganisationalCategory = async () => {
        try {
            const { data } = await this.props.getAllOrganisationalCategory();
            if (!data) return;
            var obj = {
                id: 0,
                name: 'All',
            };
            data.unshift(obj);
            const newData = data.map(opt => {
                opt.checked = false;
                return opt;
            });
            this.setState({
                organisationalCategoryOptions: newData,
            });
        } catch (error) {
            throw error;
        }
    };
    getAllOrganisationSubCategory = async () => {
        try {
            const { data } = await this.props.getAllOrganisationSubCategory();
            if (!data) return;
            data.unshift({ mainCategory: '', subCategories: [{ id: 'All', name: 'All' }] });
            data.map(outerOption => {
                var subCategories = outerOption.subCategories;
                subCategories.map(innerOption => {
                    innerOption.checked = false;
                });
            });
            this.setState({
                organisationalServiceTierOptions: data,
            });
            console.log(data);
        } catch (error) {
            throw error;
        }
    };
    generateYears = minYear => {
        const currentYear = new Date().getFullYear();
        let yearOptions = new Array(currentYear + 1 - minYear).fill(x => '');
        yearOptions = yearOptions
            .map((x, i) => {
                const year = minYear + i;
                return {
                    label: year,
                    value: year,
                };
            })
            .reverse();
        this.setState({ yearOptions });
    };
    generateOrganisationProfileList = () => {
        const organisationProfileOptions = [
            {
                label: 'All',
                value: 'all',
            },
            {
                label: 'Singapore Owned Company ( >30% shares owned by Singapore)',
                value: 'singaporeGrown',
            },
            {
                label: 'Foreign Company - Presence/Based in Singapore',
                value: 'foreignCompanyBased',
            },
            {
                label: 'Foreign Company',
                value: 'foreignCompany',
            },
            {
                label: 'Professional Bodies, Trade Association and Chamber',
                value: 'professionalBodies',
            },
            {
                label: 'Research and Tertiary Institution',
                value: 'researchInstitution',
            },
            {
                label: 'Government Agency',
                value: 'governmentAgency',
            },
        ];
        this.setState({
            organisationProfileOptions: organisationProfileOptions,
        });
    };
    generateMembershipStatusList = () => {
        const labels = ['All', 'Active', 'Expired'];
        const membershipStatusOptions = labels.map(label => {
            return {
                value: label.toLowerCase(),
                label: label,
            };
        });
        this.setState({
            membershipStatusOptions: membershipStatusOptions,
        });
    };
    generateMembershipDurationList = () => {
        const labels = ['All', '<1', '<2', '>3', '>4'];
        const membershipDurationOptions = labels.map(label => {
            return {
                value: label.toLowerCase(),
                label: label,
            };
        });
        this.setState({
            membershipDurationOptions: membershipDurationOptions,
        });
    };
    generateSMEList = () => {
        const labels = ['All', 'Yes', 'No'];
        const SMEOptions = labels.map(label => {
            return {
                value: label.toLowerCase(),
                label: label,
            };
        });
        this.setState({
            SMEOptions: SMEOptions,
        });
    };
    generateSGCompanyList = () => {
        const labels = ['All', 'Yes', 'No'];
        const SGCompanyOptions = labels.map(label => {
            return {
                value: label.toLowerCase(),
                label: label,
            };
        });
        this.setState({
            SGCompanyOptions: SGCompanyOptions,
        });
    };
    generateProductServiceCertList = () => {
        const labels = ['All', 'Active', 'Inactive'];
        const certOptions = labels.map(label => {
            return {
                value: label.toLowerCase(),
                label: label,
            };
        });
        this.setState({
            serviceCertOptions: certOptions,
            productCertOptions: certOptions,
        });
    };
    generateEventAttendedList = () => {
        const labels = ['All', '0', '1-3', '4-6', '7-9', '10+'];
        const eventAttendedOptions = labels.map(label => {
            return {
                value: label.toLowerCase(),
                label: label,
            };
        });
        this.setState({
            eventAttendedOptions: eventAttendedOptions,
        });
    };
    // UTIL
    showLoading(loading) {
        this.setState({ loading });
    }
    shouldPeriodOptionDisabled = id => {
        const { periodOptions } = this.state;
        const option = periodOptions.find(opt => opt.value === id);
        return !option.checked;
    };
    resetDatesValue = () => {
        let issuedFromState = Object.assign({}, this.state.issuedFrom);
        let issuedToState = Object.assign({}, this.state.issuedTo);
        issuedFromState.date = null;
        issuedToState.date = null;
        this.setState({
            issuedFrom: issuedFromState,
            issuedTo: issuedToState,
        });
    };
    resetYearValue = () => {
        this.setState({
            issuedByYear: null,
        });
    };
    clearState = () => {
        const { isOpen } = this.state;
        const periodOptions = this.state.periodOptions.map(x => {
            x.checked = false;
            return x;
        });
        this.setState({ ...defaultState, isOpen, periodOptions });
        return Promise.resolve();
    };
    constructData = (page, pageSize, sorted) => {
        const {
            membershipStatus,
            membershipDuration,
            isSME,
            isSGCompany,
            productCert,
            serviceCert,
            organisationalCategoryOptions,
            organisationalServiceTierOptions,
            organisationProfile,
            eventAttended,
            issuedByYear,
            issuedFrom,
            issuedTo,
            isExport,
        } = this.state;
        var organisationalCategoryArray = [];
        var organisationalServiceArray = [];
        var organistionalCategorySearchAll = false;
        var organistionalServiceSearchAll = false;
        organisationalCategoryOptions.map(opt => {
            if (opt.name === 'All' && opt.checked === true) {
                organistionalCategorySearchAll = true;
            }
            if (opt.checked === true) {
                organisationalCategoryArray.push(opt.id);
            }
        });
        organisationalServiceTierOptions.map(outerOption => {
            var subCategories = outerOption.subCategories;
            subCategories.map(innerOption => {
                if (innerOption.name === 'All' && innerOption.checked === true) {
                    organistionalServiceSearchAll = true;
                }
                if (innerOption.checked === true) {
                    organisationalServiceArray.push(innerOption.id);
                }
            });
        });
        const data = {
            year: issuedByYear ? issuedByYear.value : '',
            startDate: issuedFrom.date ? issuedFrom.date.format('YYYY-MM-DD') : '',
            endDate: issuedTo.date ? issuedTo.date.format('YYYY-MM-DD') : '',
            membershipStatus: membershipStatus ? membershipStatus.value : '',
            membershipDuration: membershipDuration ? membershipDuration.value : '',
            isSme: isSME ? isSME.value : '',
            isSGCompany: isSGCompany ? isSGCompany.value : '',
            productCertStatus: productCert ? productCert.value : '',
            serviceCertStatus: serviceCert ? serviceCert.value : '',
            organisationProfile: organisationProfile ? organisationProfile.value : '',
            organisationalCategory: organistionalCategorySearchAll === true ? [] : organisationalCategoryArray,
            organisationalService: organistionalServiceSearchAll === true ? [] : organisationalServiceArray,
            eventsAttended: eventAttended ? eventAttended.value : '',
            isExport: isExport ? 1 : 2,
            page: !page ? 1 : page,
            itemsPerPage: pageSize,
            sorted,
        };
        return data;
    };
    // EVENT HANDLER
    onPeriodClick = e => {
        const id = e.target.id;
        if (id === 'By Year') this.resetDatesValue(); // RESET DATE RANGE INPUT
        if (id === 'By Date Range') this.resetYearValue(); // RESET YEAR INPUT
        // UPDATE
        const periodOptions = this.state.periodOptions.map(opt => {
            if (opt.value === id) {
                opt.checked = !opt.checked;
            } else {
                opt.checked = false;
            }
            return opt;
        });
        // SET STATE
        this.setState({
            periodOptions,
        });
    };
    onDateChange = (startDate, endDate) => {
        let issuedFromState = Object.assign({}, this.state.issuedFrom);
        let issuedToState = Object.assign({}, this.state.issuedTo);
        // UPDATE
        switch (this.state.focusedInput) {
            case 'startDate':
                issuedFromState.date = startDate;
                break;
            case 'endDate':
                issuedToState.date = endDate;
                break;
        }
        // SET STATE
        this.setState({
            issuedFrom: issuedFromState,
            issuedTo: issuedToState,
        });
    };
    onIssuedYearChange = issuedByYear => {
        this.setState({
            issuedByYear,
        });
    };
    organisationalCategoryChange = e => {
        const key = e.target.id;
        let organisationalCategoryClone = Array.from(this.state.organisationalCategoryOptions);
        // UPDATE
        var checked = null;
        if (key !== 'All') {
            organisationalCategoryClone = organisationalCategoryClone.map(opt => {
                if (opt.name === key) opt.checked = !opt.checked;
                return opt;
            });
        } else {
            checked = organisationalCategoryClone[0].checked === true ? false : true;
            organisationalCategoryClone = organisationalCategoryClone.map(opt => {
                opt.checked = checked;
                return opt;
            });
        }
        // SET STATE
        this.setState({
            organisationalCategoryOptions: organisationalCategoryClone,
        });
    };
    organisationalServiceChange = e => {
        const key = e.target.id;
        let organisationalServiceClone = Array.from(this.state.organisationalServiceTierOptions);
        // UPDATE

        var checked = null;
        if (key !== 'All') {
            organisationalServiceClone = organisationalServiceClone.map(opt => {
                if (opt.name === key) opt.checked = !opt.checked;
                return opt;
            });

            organisationalServiceClone.map(outerOption => {
                var subCategories = outerOption.subCategories;
                subCategories.map(innerOption => {
                    if (innerOption.name === key) innerOption.checked = !innerOption.checked;
                    return innerOption;
                });
            });
        } else {
            checked = organisationalServiceClone[0].subCategories[0].checked === true ? false : true;
            organisationalServiceClone.map(outerOption => {
                var subCategories = outerOption.subCategories;
                subCategories.map(innerOption => {
                    innerOption.checked = checked;
                    return innerOption;
                });
            });
        }
        // SET STATE
        this.setState({
            organisationalServiceTierOptions: organisationalServiceClone,
        });
    };

    onSelectChange = (val, type) => {
        const state = Object.assign({}, this.state);
        state[type] = val;
        this.setState(state);
    };
    getData = async (page = 1, pageSize = 10, sorted = []) => {
        const body = this.constructData(page, pageSize, sorted);
        try {
            this.showLoading(true);
            const { isExport } = this.state;
            const { showModalIf } = this.props;
            const { data, totalNoOfItems, totalNoOfPages, ok } = await this.props.getDataList(body);

            // IF NO DATA EXIST
            showModalIf('Error', `Failed to ${isExport ? 'export' : 'get'} data`, !data && !ok);
            if (!data || data.length === 0) {
                this.setState({
                    tableList: [],
                    totalNum: 0,
                    totalPages: 0,
                    isOpen: true,
                });
                return;
            }

            // IF DATA EXIST
            const isDataEmpty = data.length === 0;
            // FOR EXPORT
            if (isExport) {
                exportCSVFile(data, 'dynamic-membership-export.csv');
                this.setState({ isOpen: false });
            }
            // FOR POPULATING TABLE
            if (!isDataEmpty && !isExport && data) {
                this.setState({
                    tableList: data,
                    totalNum: totalNoOfItems,
                    totalPages: totalNoOfPages,
                    isOpen: false,
                });
            }
            // ERROR HANDLING
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    };
    fireFetchData = () => {
        this.table.fireFetchData();
    };
    render() {
        const {
            loading,
            periodOptions,
            focusedInput,
            issuedFrom,
            issuedTo,
            issuedByYear,
            yearOptions,
            organisationProfile,
            organisationProfileOptions,
            organisationalCategoryOptions,
            membershipDurationOptions,
            membershipDuration,
            membershipStatusOptions,
            membershipStatus,
            SMEOptions,
            isSME,
            SGCompanyOptions,
            isSGCompany,
            serviceCertOptions,
            serviceCert,
            productCertOptions,
            productCert,
            eventAttendedOptions,
            eventAttended,
            isOpen,
            tableList,
            totalNum,
            totalPages,
            organisationalServiceTierOptions,
        } = this.state;
        const { activeTab, yearoption } = this.props;
        const ratingOptions = this.state[`${activeTab}RatingOptions`];
        return (
            <React.Fragment>
                <div className="page-widget p-4">
                    <HeaderPanel isFilterOpen={isOpen} onClick={() => this.setState({ isOpen: !isOpen })} />
                    {isOpen ? <hr /> : null}
                    <Collapse isOpen={isOpen}>
                        <React.Fragment>
                            <InputPanelWrapper label="Period">
                                <Row>
                                    {periodOptions.map(({ value, checked }, index) => {
                                        return (
                                            <Col sm="6" key={index}>
                                                <Label check>
                                                    <Input
                                                        id={value}
                                                        type="radio"
                                                        value={value}
                                                        checked={checked}
                                                        onChange={this.onPeriodClick}
                                                    />
                                                    {value}
                                                </Label>
                                            </Col>
                                        );
                                    })}
                                </Row>
                                <Row className="m-2">
                                    <Col sm="6" className="p-0">
                                        <DateRangePicker
                                            small
                                            showClearDates
                                            showDefaultInputIcon
                                            startDate={issuedFrom.date}
                                            startDateId={issuedFrom.id}
                                            endDate={issuedTo.date}
                                            endDateId={issuedTo.id}
                                            focusedInput={focusedInput}
                                            disabled={this.shouldPeriodOptionDisabled('By Date Range')}
                                            onDatesChange={({ startDate, endDate }) => this.onDateChange(startDate, endDate)}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            isOutsideRange={day => {}}
                                        />
                                    </Col>
                                    <Col sm="3">
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            placeholder="Please Select"
                                            value={issuedByYear}
                                            options={yearoption}
                                            onChange={this.onIssuedYearChange}
                                            isDisabled={this.shouldPeriodOptionDisabled('By Year')}
                                        />
                                    </Col>
                                </Row>
                            </InputPanelWrapper>
                            <TwoInputPanelWrapper>
                                <Row label="Membership Status">
                                    <Col sm="10">
                                        <Select
                                            placeholder="Please Select"
                                            value={membershipStatus}
                                            options={membershipStatusOptions}
                                            onChange={e => this.onSelectChange(e, 'membershipStatus')}
                                        />
                                    </Col>
                                </Row>
                                <Row label="Membership Duration">
                                    <Col sm="10">
                                        <Select
                                            placeholder="Please Select"
                                            value={membershipDuration}
                                            options={membershipDurationOptions}
                                            onChange={e => this.onSelectChange(e, 'membershipDuration')}
                                        />
                                    </Col>
                                </Row>
                            </TwoInputPanelWrapper>
                            <TwoInputPanelWrapper>
                                <Row label="Is SME">
                                    <Col sm="10">
                                        <Select
                                            placeholder="Please Select"
                                            value={isSME}
                                            options={SMEOptions}
                                            onChange={e => this.onSelectChange(e, 'isSME')}
                                        />
                                    </Col>
                                </Row>
                                <Row label="Is Singapore Company">
                                    <Col sm="10">
                                        <Select
                                            placeholder="Please Select"
                                            value={isSGCompany}
                                            options={SGCompanyOptions}
                                            onChange={e => this.onSelectChange(e, 'isSGCompany')}
                                        />
                                    </Col>
                                </Row>
                            </TwoInputPanelWrapper>
                            <TwoInputPanelWrapper>
                                <Row label="Product Cert Status">
                                    <Col sm="10">
                                        <Select
                                            placeholder="Please Select"
                                            value={productCert}
                                            options={productCertOptions}
                                            onChange={e => this.onSelectChange(e, 'productCert')}
                                        />
                                    </Col>
                                </Row>
                                <Row label="Services Cert Status">
                                    <Col sm="10">
                                        <Select
                                            placeholder="Please Select"
                                            value={serviceCert}
                                            options={serviceCertOptions}
                                            onChange={e => this.onSelectChange(e, 'serviceCert')}
                                        />
                                    </Col>
                                </Row>
                            </TwoInputPanelWrapper>
                            <TwoInputPanelWrapper>
                                <Row label="Event Attended">
                                    <Col sm="10">
                                        <Select
                                            placeholder="Please Select"
                                            value={eventAttended}
                                            options={eventAttendedOptions}
                                            onChange={e => this.onSelectChange(e, 'eventAttended')}
                                        />
                                    </Col>
                                </Row>
                                <Row label="Organisation Profile">
                                    <Col sm="10">
                                        <Select
                                            placeholder="Please Select"
                                            value={organisationProfile}
                                            options={organisationProfileOptions}
                                            onChange={e => this.onSelectChange(e, 'organisationProfile')}
                                        />
                                    </Col>
                                </Row>
                            </TwoInputPanelWrapper>
                            <InputPanelWrapper
                                label={`Organisation Primary Business Activity`}
                                secondaryLabel={`(You may select more than one)`}
                            >
                                <Row>
                                    {organisationalCategoryOptions.map(({ id, name, checked }, index) => {
                                        return (
                                            <Col sm={12} key={index}>
                                                {' '}
                                                {/* 3 Rows = 4 */}
                                                <FormGroup check>
                                                    <Input
                                                        id={name}
                                                        value={id}
                                                        type="checkbox"
                                                        checked={checked}
                                                        onChange={this.organisationalCategoryChange}
                                                    />
                                                    {name}
                                                </FormGroup>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </InputPanelWrapper>
                            <InputPanelWrapper label={`Services Offered by Organisation`} secondaryLabel={`(You may select more than one)`}>
                                <Row>
                                    {organisationalServiceTierOptions.map(({ mainCategory, subCategories }, index) => {
                                        return (
                                            <Col sm={12} key={index}>
                                                <strong>
                                                    <u>{mainCategory.name}</u>
                                                </strong>
                                                {subCategories.map((option, index) => {
                                                    return (
                                                        <FormGroup key={index} check>
                                                            <Input
                                                                id={option.name}
                                                                value={option.id}
                                                                type="checkbox"
                                                                checked={option.checked}
                                                                onChange={this.organisationalServiceChange}
                                                            />
                                                            {option.name}
                                                        </FormGroup>
                                                    );
                                                })}
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </InputPanelWrapper>
                            <Row>
                                <Col sm="12" className="text-right">
                                    <Button
                                        className="primary-btn-style mr-2"
                                        onClick={() => {
                                            this.setState({ isExport: true }, () => this.table.fireFetchData());
                                        }}
                                    >
                                        Export
                                    </Button>
                                    <Button
                                        className="primary-btn-style"
                                        onClick={() => {
                                            this.setState({ isExport: false }, () => this.table.fireFetchData());
                                        }}
                                    >
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    </Collapse>
                </div>
                <TablePanel
                    activeTab={activeTab}
                    loading={loading}
                    data={tableList}
                    totalNum={totalNum}
                    totalPages={totalPages}
                    onFetchData={(state, instance) => {
                        this.getData(state.page, state.pageSize, state.sorted, state.filtered);
                    }}
                    instanceRef={instance => {
                        if (instance) this.table = instance.table;
                    }}
                />
            </React.Fragment>
        );
    }
}

const TablePanel = ({ data, loading, totalNum, totalPages, instanceRef, onFetchData }) => {
    const printData = props => props.value || '-';
    const columnStyle = {
        style: { whiteSpace: 'unset' },
        headerStyle: { whiteSpace: 'unset' },
    };
    const columns = [
        {
            Header: 'Company',
            accessor: 'companyName',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'UEN',
            accessor: 'uen',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'General Email',
            accessor: 'generalEmail',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'General Phone',
            accessor: 'generalPhone',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Website',
            accessor: 'website',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'DID',
            accessor: 'did',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Address Line 1',
            accessor: 'addressLine1',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Address Line 2',
            accessor: 'addressLine2',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Address Line 3',
            accessor: 'addressLine3',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Postal Code',
            accessor: 'postalCode',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Salutation',
            accessor: 'salutation',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Frist Name',
            accessor: 'firstName',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Designation',
            accessor: 'designation',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Email',
            accessor: 'email',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Mobile',
            accessor: 'mobile',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Duration of Membership',
            accessor: 'durationOfMembership',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Events Attended',
            accessor: 'eventsAttended',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'All SGBP Cert',
            accessor: 'allSgbpCertCount',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'All SGBS Cert',
            accessor: 'allSgbsCertCount',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Active SGBP Cert',
            accessor: 'activeSgbpCertCount',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Active SGBS Cert',
            accessor: 'activeSgbsCertCount',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Inactive SGBP Cert',
            accessor: 'inactiveSgbpCertCount',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Inactive SGBS Cert',
            accessor: 'inactiveSgbsCertCount',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'With OMR',
            accessor: 'withOmr',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Corporate Member',
            accessor: 'isCorporateMember',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Founding Member',
            accessor: 'isFoundingMember',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Singapore Company',
            accessor: 'isSingaporeCompany',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'SME',
            accessor: 'isSme',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Organisation Profile',
            accessor: 'organisationProfile',
            ...columnStyle,
            minWidth: 200,
            Cell: printData => {
                let profile = printData.original.organisationProfile;
                if (profile === 'singaporeGrown') {
                    return 'Singapore Owned Company ( >30% shares owned by Singapore)';
                } else if (profile === 'foreignCompanyBased') {
                    return 'Foreign Company - Presence/Based in Singapore';
                } else if (profile === 'foreignCompany') {
                    return 'Foreign Company';
                } else if (profile === 'professionalBodies') {
                    return 'Professional Bodies, Trade Association and Chamber';
                } else if (profile === 'researchInstitution') {
                    return 'Research and Tertiary Institution';
                } else if (profile === 'governmentAgency') {
                    return 'Government Agency';
                } else {
                    return '-';
                }
            },
        },
        {
            Header: 'Organisation Main Category',
            accessor: 'organisationMainCategory',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Product Services Description',
            accessor: 'productServiceDescription',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Organisation SubCategory',
            accessor: 'subCategory',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
    ];
    return (
        <div className={'page-widget '}>
            <CyderReactTable
                manual
                showPagination
                data={data}
                loading={loading}
                totalNum={totalNum}
                pages={totalPages}
                minRows={0}
                columns={columns}
                onFetchData={onFetchData}
                ref={instanceRef}
            />
        </div>
    );
};

const HeaderPanel = ({ isFilterOpen, onClick }) => {
    const shouldExpand = isFilterOpen ? 'collapse' : 'expand';
    const icon = isFilterOpen ? 'close' : 'filter_list';
    const style = {
        color: 'black',
        fontSize: '20px',
    };
    return (
        <Row style={{ cursor: 'pointer' }} onClick={onClick}>
            <Col>
                <p className="field-header">Dynamic Membership Export</p>
                <small>* Click here to {shouldExpand} filters</small>
            </Col>
            <Col className="text-right">
                <i className="material-icons" style={style}>
                    {icon}
                </i>
            </Col>
        </Row>
    );
};

const InputPanelWrapper = ({ label, secondaryLabel, children }) => {
    return (
        <Row className="m-4 p-1">
            <Col sm="2">
                <p className="text-bold text-capitalize">{label + ': '}</p>
                <em>{secondaryLabel ? secondaryLabel : ''}</em>
            </Col>
            <Col>{children}</Col>
        </Row>
    );
};

const TwoInputPanelWrapper = ({ label, children }) => {
    return (
        <Row className="m-4 p-1">
            <Col sm="2">
                <p className="text-bold text-capitalize">{children[0].props.label + ': '}</p>
            </Col>
            <Col sm="3">{children[0]}</Col>
            <Col sm="2">
                <p className="text-bold text-capitalize">{children[1].props.label + ': '}</p>
            </Col>
            <Col sm="3">{children[1]}</Col>
        </Row>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAllOrganisationSubCategory: () => {
            return dispatch(getAllOrganisationSubCategory());
        },
        getAllOrganisationalCategory: () => {
            return dispatch(getAllOrganisationalCategory());
        },
        getDataList: body => {
            return dispatch(getDataList(body));
        },
    };
};

export default StoredLayout(connect(mapStateToProps, mapDispatchToProps)(DynamicExportPanel));

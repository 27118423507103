import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../history';
import Select from 'react-select';
import moment from 'moment';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { Container, Input, Row, Col, Button, Card, CardBody, Table, Label, Form, FormGroup, FormText, Alert } from 'reactstrap';
import PaymentChoicesPanel from './PaymentChoicesPanel';
import DialogModal from 'cyder/modals/DialogModal';
import PaymentReceivedInfoPanel from './PaymentReceivedInfoPanel';

// import 'css/cyder/form/common-form.css';

import { eventLoadEventPaymentModes, applicationLoadEventPaymentModes } from 'actions/shared/eventRsvpAction.js';
import { getPaymentInfo, updatePayment } from 'actions/member/events/payment/action';

class PaymentPanel extends React.Component {
    constructor() {
        super();
        this.showLoadingSubmit = this.showLoadingSubmit.bind(this);
        this.showLoadingCreditCard = this.showLoadingCreditCard.bind(this);
        this.showLoadingPaymentMode = this.showLoadingPaymentMode.bind(this);
        this.setPaymentMode = this.setPaymentMode.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.cancel = this.cancel.bind(this);
        this.submit = this.submit.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            disabled: false,
            searchable: true,
            selectValue: '',
            paymentStart: false,
            clearable: true,
            paymentModes: [],
            selectedPaymentMode: {
                value: '',
                label: '',
            },
            loadingSubmit: false,
            loadingCreditCard: false,
            loadingPaymentMode: false,
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            isMemberEvent: true,
            paymentInfo: {},
        };
    }
    onDateChanged(e, field, fieldCtrl) {
        var paymentInfo = Object.assign({}, this.state.paymentInfo);
        paymentInfo[fieldCtrl] = e;
        paymentInfo[field] = e.format('YYYY-MM-DD');
        this.setState({
            paymentInfo,
        });
    }
    onTextChanged(value, field) {
        var paymentInfo = Object.assign({}, this.state.paymentInfo);
        paymentInfo[field] = value;
        this.setState({
            paymentInfo,
        });
    }
    modalAction() {
        this.modalToggle();
        if (this.state.isMemberEvent) {
            history.push(`/member/events/event`);
        } else {
            history.push(`/staff/membership/applications`);
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }

    submit() {
        this.props
            .updatePayment(this.state.paymentInfo)
            .then(res => {
                this.showModal('Confirmation', 'Payment has been successfully updated', 'update', false, true);
            })
            .catch(error => {});
    }
    cancel() {
        this.showModal(
            'Confirmation',
            this.state.isMemberEvent
                ? `You have decided to settle the payment later. Please note that your registration will be void if we 
                    have not received payment 1 week before the event starts. Thank you.`
                : `You have decided to settle the payment later. Please note that your application will be void if we 
                    have not received payment within 1 week time period. Thank you.`,
            null,
            false,
            true,
        );
    }
    back() {
        history.goBack();
    }
    next() {
        this.setState({
            paymentStart: true,
        });
    }
    setPaymentMode(e) {
        var paymentInfo = Object.assign({}, this.state.paymentInfo);
        paymentInfo.modeOfPaymentId = e.value;
        this.setState({
            selectedPaymentMode: e,
            paymentInfo,
        });
    }
    showLoadingSubmit(show) {
        this.setState({
            loadingSubmit: show,
        });
    }
    showLoadingCreditCard(show) {
        this.setState({
            loadingCreditCard: show,
        });
    }
    showLoadingPaymentMode(show) {
        this.setState({
            loadingPaymentMode: show,
        });
    }
    setComponentMode(registrationId, applicationId) {
        if (registrationId) {
            this.setState({
                isMemberEvent: true,
            });
        } else if (applicationId) {
            this.setState({
                isMemberEvent: false,
            });
        }
    }
    componentDidMount() {
        const registrationId = this.props.match.params.registrationid;
        const applicationId = this.props.match.params.applicationId;
        this.setComponentMode(registrationId, applicationId);
        this.showLoadingPaymentMode(true);

        this.props
            .getPaymentInfo(registrationId, applicationId)
            .then(paymentInfo => {
                if (
                    paymentInfo !== null &&
                    paymentInfo.data !== null &&
                    paymentInfo.data.length > 0 &&
                    paymentInfo.data[0].modeOfPaymentId != null
                ) {
                    var selectedPaymentMode = {
                        label: 'x',
                        value: paymentInfo.data[0].modeOfPaymentId,
                    };

                    try {
                        paymentInfo.data[0].datePaymentReceivedCtrl = moment(paymentInfo.data[0].datePaymentReceived, 'YYYY-MM-DD').format(
                            'DD/MM/YYYY',
                        );
                    } catch (e) {}

                    var paymentLocked = false;
                    if (paymentInfo.data[0].datePaymentReceived != null && paymentInfo.data[0].modeOfPaymentId == 4) {
                        // special case for credit payment, not allowed for further editting
                        paymentLocked = true;
                    }

                    this.setState({
                        paymentInfo: paymentInfo.data[0],
                        paymentStart: true,
                        paymentLocked,
                        selectedPaymentMode,
                    });
                    this.showLoadingPaymentMode(false);
                } else {
                    this.setState({
                        paymentInfo: paymentInfo.data[0],
                    });

                    this.props
                        .loadEventPaymentModes(registrationId, applicationId)
                        .then(paymentModes => {
                            this.showLoadingPaymentMode(false);
                            var paymentModesToAdd = [];
                            paymentModes.data.forEach(paymentMode => {
                                if (paymentMode.modeOfPaymentId < 4) {
                                    paymentModesToAdd.push({
                                        // If not undefined memberEvents, else membershipApplication
                                        value: registrationId ? paymentMode.modeOfPaymentId : paymentMode.id,
                                        label: registrationId ? paymentMode.paymentMode : paymentMode.mode,
                                    });
                                }
                            });
                            this.setState({
                                paymentModes: paymentModesToAdd,
                            });
                        })
                        .catch(error => {
                            this.showLoadingPaymentMode(false);
                        });
                }
            })
            .catch(error => {
                this.showLoadingPaymentMode(false);
            });
    }

    render() {
        return (
            <NoShadowPanel title={this.state.isMemberEvent ? 'Event Registration Payment' : 'Membership Application Payment'}>
                <PaymentReceivedInfoPanel
                    modeOfPaymentId={this.state.paymentInfo.modeOfPaymentId}
                    amountReceived={this.state.paymentInfo.amountReceived}
                    datePaymentReceived={this.state.paymentInfo.datePaymentReceived}
                />
                {!this.state.paymentStart && (
                    <React.Fragment>
                        <Row>
                            <div className="col-12 col-lg-12">
                                For payment methods other than the ones indicated, please contact SGBC at{' '}
                                <u>
                                    <a href="events@sgbc.sg">events@sgbc.sg</a>
                                </u>{' '}
                                or (65) 6732 5518
                            </div>
                        </Row>
                        <Row>
                            <div className="col-12 col-lg-12">
                                <FormGroup>
                                    <Label className="field-header">
                                        Payment Mode <MandatoryStar />
                                    </Label>
                                    {this.state.loadingPaymentMode ? (
                                        <LoadingSpinner />
                                    ) : (
                                        <Select
                                            autosize={false}
                                            autofocus
                                            options={this.state.paymentModes}
                                            simpleValue
                                            clearable={this.state.clearable}
                                            name="selected-state"
                                            disabled={this.state.disabled}
                                            value={this.state.selectedPaymentMode}
                                            onChange={this.setPaymentMode}
                                            searchable={this.state.searchable}
                                        />
                                    )}
                                    <small className="form-text text-muted">Please choose payment mode</small>
                                </FormGroup>
                            </div>
                        </Row>
                    </React.Fragment>
                )}
                <br />
                {!this.state.paymentLocked && this.state.paymentStart && (
                    <PaymentChoicesPanel
                        onTextChanged={this.onTextChanged}
                        onDateChanged={this.onDateChanged}
                        paymentInfo={this.state.paymentInfo}
                        paymentMode={this.state.selectedPaymentMode.value}
                    />
                )}
                {this.state.paymentLocked && (
                    <Row>
                        <Col className="d-flex flex-row" xs={12}>
                            <b>Payment Mode</b>
                        </Col>
                        <Col className="d-flex flex-row" xs={12}>
                            {this.state.paymentInfo.paymentMode} <br />
                            <br />
                        </Col>
                        <Col className="d-flex flex-row" xs={12}>
                            <b>Card Number</b>
                        </Col>
                        <Col className="d-flex flex-row" xs={12}>
                            {this.state.paymentInfo.referenceNumber} <br />
                            <br />
                        </Col>
                        <Col className="d-flex flex-row" xs={12}>
                            <b>Remarks</b>
                        </Col>
                        <Col className="d-flex flex-row" xs={12}>
                            {this.state.paymentInfo.remarks} <br />
                            <br />
                        </Col>
                        <Col className="d-flex flex-row" xs={12}>
                            <Button color="warning" onClick={this.back}>
                                Back
                            </Button>
                        </Col>
                    </Row>
                )}
                {!this.state.paymentLocked && !this.state.paymentStart && (
                    <Row>
                        <Col className="d-flex flex-row" xs={6}>
                            <Button color="success" onClick={this.next} size="sm">
                                Next
                            </Button>
                        </Col>
                        <Col className="d-flex flex-row-reverse" xs={6}>
                            <Button color="warning" onClick={this.back}>
                                Back
                            </Button>
                        </Col>
                    </Row>
                )}
                {!this.state.paymentLocked && this.state.paymentStart && (
                    <Row>
                        <Col className="d-flex flex-row" xs={6}>
                            <Button color="success" onClick={this.submit} size="sm">
                                Submit
                            </Button>
                        </Col>
                        <Col className="d-flex flex-row-reverse" xs={6}>
                            <Button color="warning" onClick={this.back} size="sm">
                                Back
                            </Button>
                        </Col>
                    </Row>
                )}
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
            </NoShadowPanel>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.eventsAllReducer,
        user: state.profile.user,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadEventPaymentModes: (registrationId, applicationId) => {
            if (registrationId) {
                return dispatch(eventLoadEventPaymentModes(registrationId));
            } else {
                return dispatch(applicationLoadEventPaymentModes(applicationId));
            }
        },
        getPaymentInfo: (registrationId, applicationId) => {
            if (registrationId) {
                return dispatch(getPaymentInfo(registrationId));
            } else {
                return Promise.resolve({});
            }
        },
        updatePayment: data => {
            return dispatch(updatePayment(data));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaymentPanel);

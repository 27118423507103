import React from 'react';
import { connect } from 'react-redux';
import loadsh from 'lodash';
import history from 'history.js';

import { FormGroup, Label, Col, Row, Button, Container, Card, CardBody } from 'reactstrap';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import SelectCategoryPanel from './SelectCategoryPanel';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import TermsAndConditions from './TermsAndConditions';
// import CyderApplicationStepPanel from 'cyder/panels/CyderApplicationStepPanel';
import ApplicationDetailsPanel from 'pages/member/certification/applications/product/components/ApplicationDetailsPanel';
import ManufacturerDetailsPanel from 'pages/member/certification/applications/product/components/ManufacturerDetailsPanel';
import ProductDetailsPanelV2 from 'pages/member/certification/applications/product/components/ProductDetailsPanelV2';
import ServiceDetailsPanelV2 from 'pages/member/certification/applications/service/components/ServiceDetailsPanelV2';
import ProductsListTable from '../product/components/ProductsListTableV1';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';
import ButtonPanel from './ButtonPanel';
import DialogModal from 'cyder/modals/DialogModal';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import InputFieldWithValidation from 'reactstrap-input';

import {
    getCategoryList,
    getProductTypes,
    // memberGetSavedProdCatalogue,
    // memberGetSavedProdPicture,
    // getRenewCertApplFromCertID,
    getSavedCertAppl,
    saveCertApplication,
    forceSetLoading,
    setSavedFlag,
    resetStateToDefault,
} from 'actions/member/certification/applications/memberCertNewApplAction';
import {
    getInvoiceInfo,
    // printCert,
    getPaymentInfo,
    returnMua,
    printCertificationApplication,
} from 'actions/staff/certification/staffCertificationApplicationAction';
import { getCountriesGeneric } from 'actions/misc/countryAction.js';
import { getActiveSurveyByCategory } from 'actions/staff/survey/surveyAction';
import { printCertByCertNumber, downloadCertificate, findCertByCertNumber } from 'actions/shared/certificationAction';
import { memberMembershipApplicationGetCompanyInfo } from 'actions/member/company/memberCompanyAction';
import { findByCategoryAndName } from 'actions/admin/misc/resources/action';

import {
    b64toBlob,
    removeArrayItem,
    blobDownload,
    parseContentType,
    capitalizeFirstLetter,
    sanitizeStringAndObjectNullsToEmptyStrings,
} from 'js/util';
import cyderlib from 'js/cyderlib';
import { validateFromValidationObjectPromise as validateValidationObject } from 'js/validation';
import { SGBC_S3_ROOT, SGBC_INVOICE_DOWNLOAD_ROOT, SGBC_S3_HOST, SGBC_S3_CERT_LOGO_ROOT } from 'config';

const getDefaultState = id => ({
    loading: false,
    certId: null,
    applicationDetails: {},
    applicationId: null,
    applicationType: 1,
    step: id ? 2 : 0,
    disabled: false,
    searchable: true,
    selectValue: '',
    clearable: true,
    productBrands: [],
    selectedYesNoOption: '',
    manufacturerDetails: '',
    manufacturerNameOfCompanyPlant: '',
    manufacturerFactoryAddressPlant: '',
    manufacturerNameOfCompany: '',
    manufacturerFactoryAddress: '',
    manufacturerContactPersonName: '',
    manufacturerTelephone: '',
    manufacturerIsoCode: '',
    manufacturerCountryId: '',
    manufacturerCountryName: '',
    selectedProductType: { id: '', type: '' },
    selectedProductCategory: null,
    selectedProductSubcategory: null,
    serviceCategory: null,
    selectedCategoryValue: '',
    submitting: false,
    status: 'Draft',
    remarks: '',
    countryList: [],
    greenRemarks: '',
    activeSurveys: [],
    service: null,
    companyInfo: {},
    productDetailsValidation: {
        validationObj: {},
        feedbackValidationObj: {},
    },
    manufacturerDetailsValidation: {
        validationObj: {},
        feedbackValidationObj: {},
    },
    applicantDetailsValidation: {
        validationObj: {},
        feedbackValidationObj: {},
    },
    modal: {
        showModal: false,
        modalMessage: '',
        modalHeader: '',
        positiveButtonHide: true,
        negativeButtonHide: true,
        type: 'save',
    },
    typeForSubmit: null,
    bodyForSubmit: null,
    forSubmitFlag: false,
    agreeTc: false,
    sameAsManufacturer: false,
    sameAsManufacturerIsChecked: false,
    canProceedToPreview: false,
    isDistributor: '',
});

class MemberCertNewApplicationPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.isDistributorChange = this.isDistributorChange.bind(this);
        this.sameAsManufacturerChange = this.sameAsManufacturerChange.bind(this);
        this.agreeTc = this.agreeTc.bind(this);
        this.downloadInvoice = this.downloadInvoice.bind(this);
        this.saveCertAppl = this.saveCertAppl.bind(this);
        this.constructBody = this.constructBody.bind(this);
        this.downloadProdCatalogue = this.downloadProdCatalogue.bind(this);
        this.downloadProdPicture = this.downloadProdPicture.bind(this);
        this.onProductTypeChange = this.onProductTypeChange.bind(this);
        this.onYesNoOptionChange = this.onYesNoOptionChange.bind(this);
        this.isSelfFinancingCapableSelected = this.isSelfFinancingCapableSelected.bind(this);
        this.onManufacturerDetailsChange = this.onManufacturerDetailsChange.bind(this);
        this.handleGreenRemarkChange = this.handleGreenRemarkChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputCountryChange = this.handleInputCountryChange.bind(this);
        this.onSelectCategory = this.onSelectCategory.bind(this);
        this.viewAssessment = this.viewAssessment.bind(this);
        this.setFileLength = this.setFileLength.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.onManufacturerNameOfCompanyChange = this.onManufacturerNameOfCompanyChange.bind(this);
        this.onManufacturerFactoryAddressChange = this.onManufacturerFactoryAddressChange.bind(this);
        this.onManufacturerISOChange = this.onManufacturerISOChange.bind(this);
        this.onManufacturerTelephoneChange = this.onManufacturerTelephoneChange.bind(this);
        this.onManufacturerContactPersonNameChange = this.onManufacturerContactPersonNameChange.bind(this);
        this.onChangeHandlerSetSaveFlag = this.onChangeHandlerSetSaveFlag.bind(this);
        this.state = getDefaultState(this.props.match.params.id);
        this.handleRemarkChange = this.handleRemarkChange.bind(this);
        this.feedbackSurvey = this.feedbackSurvey.bind(this);
        this.paynowLink = this.paynowLink.bind(this);
        this.printCertificate = this.printCertificate.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.getValidationObject = this.getValidationObject.bind(this);
        this.toggleModalSubmit = this.toggleModalSubmit.bind(this);
        this.submitApplication = this.submitApplication.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind();
        this.downloadTermAndConditionsDocument = this.downloadTermAndConditionsDocument.bind(this);
        this.printApplication = this.printApplication.bind(this);
        this.printCertByCertNumber = this.printCertByCertNumber.bind(this);
        this.validationBeforeSubmission = this.validationBeforeSubmission.bind(this);
    }
    componentDidMount() {
        this.props.resetStateToDefault().then(() => {
            this.getDataFromServer();
            this.getCompanyInfo();
        });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState(getDefaultState(this.props.match.params.id));
            this.componentDidMount();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoading(loading) {
        this.setState({
            loading,
        });
    }
    feedbackSurvey() {
        history.push(`/member/certification/${this.props.type}/confirm/${this.props.match.params.id}`);
    }
    async downloadTermAndConditionsDocument(type) {
        const { findByCategoryAndName, forceSetLoading } = this.props;
        try {
            forceSetLoading(true);
            let name = 'Certification Terms and Conditions';
            const res = await findByCategoryAndName(5, name);
            var url = SGBC_S3_ROOT + res.data.resource;
            window.open(url);
        } catch (error) {
            this.showModal('Error', 'Term and Conditions is not available', null, false, true);
            throw error;
        } finally {
            forceSetLoading(false);
        }
    }
    async printApplication() {
        try {
            this.setState({
                loadPrinting: true,
            });
            const res = await this.props.printCertificationApplication(this.props.match.params.id, this.props.type, this.state.muaLocked);
            window.open(SGBC_S3_HOST + '/temp/' + res.file);
        } catch (error) {
            this.showModal('Error', 'Application cannot be printed', null, false, true);
            throw error;
        } finally {
            this.setState({
                loadPrinting: false,
            });
        }
    }
    async printCertificate(certNumber) {
        const { printCert, forceSetLoading, type } = this.props;
        try {
            forceSetLoading(true);
            const res = await printCert(certNumber, type);
            const blob = b64toBlob(res.data, 'application/pdf');
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
        } catch (error) {
            this.showModal('Error', 'Certificate cannot be printed', null, false, true);
            throw error;
        } finally {
            forceSetLoading(false);
        }
    }
    async returnMua() {
        try {
            forceSetLoading(true);
            let res = await this.props.returnMua(this.props.match.params.id);
            this.showModal('Confirmation', 'Mark User Agreement has been returned', null, false, true);
        } catch (error) {
            throw error;
        } finally {
            forceSetLoading(false);
        }
    }
    async downloadInvoice() {
        const { forceSetLoading, getInvoiceInfo } = this.props;
        try {
            forceSetLoading(true);
            let invoice = await getInvoiceInfo(this.props.new.invoiceId);
            if (!invoice || !invoice.data || !invoice.data.invoiceNumber) {
                this.showModal('Error', 'Invoice is not generated. Please try again.', null, false, true);
                return;
            }
            window.location.href = `${SGBC_INVOICE_DOWNLOAD_ROOT}generate?invoiceno=${invoice.data.invoiceNumber}&entity=sgbc_pte_ltd`;
        } catch (error) {
            throw error;
        } finally {
            forceSetLoading(false);
        }
    }
    handleGreenRemarkChange(e) {
        const key = e.target.id;
        const value = e.target.value;
        const { validationObj, feedbackValidationObj } = this.getValidationObject('productDetails', key, value);
        this.setState({
            greenRemarks: value,
            productDetailsValidation: {
                validationObj,
                feedbackValidationObj,
            },
        });
    }
    handleRemarkChange(e) {
        const remarksValue = e.target.value;
        this.setState({
            remarks: remarksValue,
        });
    }
    async printCertByCertNumber(certificationNumber) {
        try {
            this.showLoading(true);
            let cert = await this.props.findCertByCertNumber(certificationNumber);
            this.props.downloadCertificate(cert.data.id);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    downloadCertLogo = (ratingId, type) => {
        const val = type === 'Product' ? 'p' + ratingId : 's';
        window.open(`${SGBC_S3_CERT_LOGO_ROOT}sgb${val}.zip`);
    };
    async getCompanyInfo() {
        const { getCompanyInfo, forceSetLoading } = this.props;
        try {
            forceSetLoading(true);
            const { data } = await getCompanyInfo();
            const companyInfo = sanitizeStringAndObjectNullsToEmptyStrings(data);
            if (this._isMounted)
                this.setState({
                    companyInfo,
                });
        } catch (error) {
            throw error;
        } finally {
            forceSetLoading(false);
        }
    }
    async getDataFromServer() {
        try {
            this.showLoading(true);
            const id = this.props.match.params.id;
            const countries = await this.props.getCountriesList();
            const activeSurveys = await this.props.getActiveSurveyByCategory(4);
            if (activeSurveys !== null && activeSurveys.data !== null && activeSurveys.data.length > 0) {
                this.setState({
                    activeSurveys: activeSurveys.data,
                });
            }
            let paymentInfo;
            if (id) {
                const data = sanitizeStringAndObjectNullsToEmptyStrings(await this.props.getRelevantData(id, this.props.type));
                if (this.props.type === 'product') await this.props.getProductTypes(data.productCategoryId);
                const selectedProductTypes = this.props.productTypes.find(item => data.productType === item.id);
                if (data.paymentId) {
                    paymentInfo = await this.props.getPaymentInfo(data.paymentId);
                } else {
                    paymentInfo = null;
                }
                this.setState({
                    fileLength: 0,
                    applicationDetails: data,
                    status: data.status,
                    applicationId: data.id,
                    productBrands: data.brands,
                    applicationType: data.applicationTypeId, //1 or 2 new or renew
                    templateId: data.templateId,
                    scoresheetId: data.assessmentId,
                    serviceCategory: data.serviceCategoryValue,
                    manufacturerDetails: data.manufacturerDetails, //name
                    manufacturerNameOfCompany: data.manufacturerNameOfCompany,
                    manufacturerFactoryAddress: data.manufacturerFactoryAddress,
                    manufacturerNameOfCompanyPlant: data.manufacturerNameOfCompanyPlant,
                    manufacturerFactoryAddressPlant: data.manufacturerFactoryAddressPlant,
                    manufacturerContactPersonName: data.manufacturerContactPersonName,
                    sameAsManufacturer: data.sameAsManufacturer === 1 ? true : false,
                    sameAsManufacturerIsChecked: data.sameAsManufacturer === 1 ? true : false,
                    manufacturerTelephone: data.manufacturerTelephone,
                    manufacturerIsoCode: data.manufacturerIsoCode,
                    manufacturerCountryId: data.manufacturerCountryId,
                    manufacturerCountryName: data.manufacturerCountryName,
                    selectedYesNoOption: this.props.type !== 'product' ? data.isSelfFinancingCapable : data.isManufacturer === 1,
                    selectedProductCategory: selectedProductTypes ? selectedProductTypes.category : null,
                    selectedProductSubcategory: selectedProductTypes ? selectedProductTypes.subcategory : null,
                    selectedProductType: data.productType && selectedProductTypes,
                    selectedCategory: data.productCategoryId || data.serviceCategoryId,
                    productCatalogue: data.productCatalogue,
                    productCatalogueFilename: data.productCatalogueFilename,
                    productPicture: data.productPicture,
                    productPictureFilename: data.productPictureFilename,
                    productCatalogueResourceId: data.productCatalogue,
                    productPictureResourceId: data.productPicture,
                    remarks: data.remarks || '',
                    greenRemarks: data.greenRemarks,
                    service: data.service,
                    productCategory: data.productCategoryValue,
                    muaLocked: data.muaLocked,
                    paymentInfo: paymentInfo != null && paymentInfo.data != null ? paymentInfo.data : null,
                    isDistributor: data.isManufacturer === 0 ? { label: 'No', value: 0 } : { label: 'Yes', value: 1 },
                });
            }

            this.setState({
                countryList: countries.data,
            });
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            /**
             * Note: This has to be implemented here, after data have set in local state; validations are done using
             * data in local state.
             * */
            this.setCanProceedToPreview(false);
            return this.props.getCategoryList(this.props.type);
        }
    }
    changeStep(e, amount = 1) {
        const { step } = this.state;
        const { id } = this.props.match.params;
        this.setState({ step: step + amount }, () => {
            if (this.state.step) this.getDataFromServer(id);
        });
    }
    onSelectCategory(item) {
        // PRODUCT & SERVICE
        const { type } = this.props;
        var state = Object.assign({}, this.state);
        state.selectedCategory = item.id;
        state.selectedCategoryValue = item.category;
        state.step = state.step + 1;
        if (type === 'product') this.props.getProductTypes(item.id);
        if (type === 'service') state.serviceCategory = item.category;
        this.setState(state);
        // reset the product type to null , because the list has changed
        this.onProductTypeChange(null);
    }
    handleInputChange() {
        const files = document.getElementById('file-input').files;
        for (let i = 0; i < files.length; i += 1) {
            const reader = new FileReader();
            reader.onload = () => {
                const b64String = reader.result.substring(reader.result.indexOf(',') + 1);
                // do whatever you want with the file content
                this.props.saveProductCatalogue(files[i].type, files[i].name, b64String);
            };
            if (files[i].length > 0) reader.readAsDataURL(files[i]);
        }
    }
    handleInputCountryChange(option) {
        this.setState({
            manufacturerCountryName: option.name,
            manufacturerCountryId: option.id,
        });
    }
    setFileLength(length) {
        this.setState({ fileLength: length });
    }
    paynowLink() {
        const { id } = this.props.match.params;
        history.push(`/member/certification/application/${id}/paynow/${this.props.type}`);
    }
    viewAssessment() {
        const { id } = this.props.match.params;
        const { templateId, scoresheetId } = this.state;
        const { type } = this.props;
        history.push(`/member/certification/${type}/viewassessment/${id}/${templateId}/${scoresheetId}`);
    }
    constructBody() {
        const { type } = this.props;
        const {
            selectedProductType,
            selectedYesNoOption,
            productBrands,
            manufacturerDetails,
            manufacturerNameOfCompanyPlant,
            manufacturerFactoryAddressPlant,
            manufacturerNameOfCompany,
            manufacturerFactoryAddress,
            manufacturerContactPersonName,
            manufacturerTelephone,
            manufacturerIsoCode,
            manufacturerCountryId,
            manufacturerCountryName,
            prevCertificateId,
            remarks,
            greenRemarks,
            selectedCategory,
            sameAsManufacturer,
            serviceCategory,
            isDistributor,
        } = this.state;
        const { productCatalogueFilename, productPictureFilename, productCatalogueResourceId, productPictureResourceId } = this.props.new;
        if (type === 'product') {
            return {
                productType: selectedProductType && selectedProductType.id,
                isManufacturer: new Number(isDistributor.value),
                productCatalogueFilename: productCatalogueFilename,
                productPictureFilename: productPictureFilename,
                brands: productBrands,
                manufacturerDetails,
                manufacturerNameOfCompany,
                manufacturerFactoryAddress,
                manufacturerNameOfCompanyPlant,
                manufacturerFactoryAddressPlant,
                sameAsManufacturer,
                manufacturerContactPersonName,
                manufacturerTelephone,
                manufacturerIsoCode,
                manufacturerCountryId,
                manufacturerCountryName,
                prevCertificateId,
                remarks,
                greenRemarks,
                productCatalogue: productCatalogueResourceId,
                productPicture: productPictureResourceId,
                isProductCertificateApplication: 1,
            };
        }
        return {
            isSelfFinancingCapable: selectedYesNoOption,
            serviceCatalogueFilename: productCatalogueFilename,
            servicePictureFilename: productPictureFilename,
            serviceCategoryId: selectedCategory,
            serviceCatalogue: productCatalogueResourceId,
            servicePicture: productPictureResourceId,
            manufacturerContactPersonName,
            manufacturerTelephone,
            prevCertificateId,
            remarks,
            greenRemarks,
            serviceCategory,
            isProductCertificateApplication: 0,
        };
    }
    /**
     * getModalMessageOnInvalidField methos return actual input used on the form based on
     * valueKey of used by the input component
     *
     * @param {*} id valueKey used by a particular input component
     */
    getModalMessageOnInvalidField(id) {
        const inputFieldName = {
            manufacturerContactPersonName: 'Main Contact Person',
            manufacturerTelephone: 'Main Contact Phone Number',
            manufacturerNameOfCompany: 'Name of Manufacturing',
            manufacturerFactoryAddress: 'Manufacturing Factory Address',
            manufacturerNameOfCompanyPlant: 'Name of Manufacturing Plant',
            manufacturerFactoryAddressPlant: 'Manafacturing Factory Plant Address',
            greenRemarks: 'Green Remarks',
            manufacturerCountryId: 'Country of Origin',
            productCatalogueFilename: 'Product Catalogue',
            productCatalogueResourceId: 'Product Catalogue',
            productPictureFilename: 'Product Picture',
            productPictureResourceId: 'Product Picture',
        };
        return inputFieldName[id] + ' cannot be empty. Please fill in a valid value.';
    }
    /**
     * validateIfValid method validate all input fields on certificate application.
     * This method will return an object containing id, value, and hasEmpty properties if
     * an input field is found invalid, else it will return obj with only hasEmpty as
     * false when all input are successfully validated.
     */
    validateIfValid() {
        // SPECIAL CASE
        const returnType = {
            hasEmpty: true,
            id: '',
            value: '',
        };
        const { productBrands, selectedYesNoOption } = this.state;
        // console.log("selectedYesNoOption ", selectedYesNoOption)
        if (this.props.type !== 'service') {
            if (productBrands.length === 0) return returnType;
            if (productBrands[0].models.length === 0) return returnType;
            const brand = productBrands[0].name;
            const models = productBrands[0].models[0].name;
            if (!brand || !models || brand === ' ' || models === ' ') return returnType;
        }
        // } else {
        //     if (selectedYesNoOption === '') return returnType;
        // }

        // KEYS OF ALL MANDATORY INPUT FIELDS
        const inputIds =
            this.props.type !== 'service'
                ? [
                      'manufacturerContactPersonName',
                      'manufacturerTelephone',
                      'manufacturerNameOfCompany',
                      'manufacturerFactoryAddress',
                      'manufacturerNameOfCompanyPlant',
                      'manufacturerFactoryAddressPlant',
                      'greenRemarks',
                      'manufacturerCountryId',
                      'productCatalogueFilename',
                      'productCatalogueResourceId',
                      'productPictureFilename',
                      'productPictureResourceId',
                  ]
                : ['manufacturerContactPersonName', 'manufacturerTelephone'];

        // VALIDATION
        let hasValidValue = false;
        for (let id in inputIds) {
            const key = inputIds[id];
            const value = this.state[key] || this.props.new[key];
            hasValidValue = value ? true : false;
            if (!hasValidValue)
                return {
                    hasEmpty: true,
                    id: key,
                    value,
                };
        }
        return {
            hasEmpty: false,
        };
    }
    /**
     * validationBeforeSubmission contain validation for invalid input value on all input fields.
     * The method return true if successfully validate all fields, else it will return false.
     */
    async validationBeforeSubmission(enableModal) {
        const applicantDetailRequiredObj = {
            manufacturerContactPersonName: true,
            manufacturerTelephone: true,
        };
        const productDetailsRequiredObj = {
            greenRemarks: this.props.type === 'product',
        };
        const manufacturerDetailsRequireObj = {
            manufacturerNameOfCompany: true,
            manufacturerFactoryAddress: true,
            manufacturerNameOfCompanyPlant: true,
            manufacturerFactoryAddressPlant: true,
        };
        const applicantDetailsValidation = Object.assign({}, this.state.applicantDetailsValidation);
        const productDetailsValidation = Object.assign({}, this.state.productDetailsValidation);
        const manufacturerDetailsValidation = Object.assign({}, this.state.manufacturerDetailsValidation);
        const checkHasErrorFunc = valid => {
            this.showModalIf('Error', 'Found invalid information. Please provide valid information', !valid);
            return !valid ? true : false;
        };

        // Validate invalid value
        const { id, hasEmpty } = this.validateIfValid();
        if (enableModal) var hasError = this.showModalIf('Error', this.getModalMessageOnInvalidField(id), hasEmpty);
        if (hasEmpty) return Promise.resolve(false);

        // Evaluate 'approved' property (within validationObject) from applicant details
        hasError = await validateValidationObject(applicantDetailsValidation.validationObj, applicantDetailRequiredObj).then(
            checkHasErrorFunc,
        );
        if (hasError) return Promise.resolve(false);

        // Evaluate 'approved' property (within validationObject) from product details
        hasError = await validateValidationObject(productDetailsValidation.validationObj, productDetailsRequiredObj).then(
            checkHasErrorFunc,
        );
        if (hasError) return Promise.resolve(false);
        if (this.props.type !== 'service') {
            // Evaluate 'approved' property (within validationObject) from manufacturer details
            hasError = await validateValidationObject(manufacturerDetailsValidation.validationObj, manufacturerDetailsRequireObj).then(
                checkHasErrorFunc,
            );
            if (hasError) return Promise.resolve(false);
        }

        // If successfully validated
        return Promise.resolve(true);
    }
    toggleModalSubmit() {
        let data = {};
        this.modalToggle();
        this.showModal('Confirmation', 'Click OK to submit the application.', 'Submit', false, false);
    }
    async setCanProceedToPreview(enableModal = true) {
        const successfullyValidated = await this.validationBeforeSubmission(enableModal);
        this.setState({
            canProceedToPreview: successfullyValidated,
        });
        return successfullyValidated;
    }
    async saveCertAppl(forSubmit, enableModalForValidation) {
        this.props.forceSetLoading(true);
        const { type, error, saveCertApplication } = this.props;
        const { applicationId, step } = this.state;
        const idObj = applicationId ? { id: applicationId } : {};
        const body = {
            ...idObj,
            ...this.constructBody(),
            certId: this.props.match.params.certId,
            certificateNumberToRenewOrAmend: this.props.match.params.certNum,
        };
        try {
            // VALIDATE BEFORE SUBMISSION
            this.setCanProceedToPreview(enableModalForValidation);

            if (forSubmit) {
                this.setState({
                    typeForSubmit: type,
                    bodyForSubmit: body,
                    forSubmitFlag: forSubmit,
                });
                this.toggleModalSubmit();
            } else {
                const { data, errno } = await saveCertApplication(type, body, forSubmit);
                if (errno) return;
                if (data.id) history.push(`/member/certification/${type}/application/` + data.id); // When successfully saved and is step 2
            }
        } catch (error) {
            throw error;
        } finally {
            this.props.forceSetLoading(false);
        }
    }
    async submitApplication() {
        const { type, error, saveCertApplication } = this.props;
        const { applicationId, step, forSubmitFlag, typeForSubmit } = this.state;
        const idObj = applicationId ? { id: applicationId } : {};
        const body = {
            ...idObj,
            ...this.constructBody(),
        };
        const { data } = await saveCertApplication(typeForSubmit, body, forSubmitFlag);
        if (!data || (error && data.errno)) return; // When saved and has error; apply for step 1
        if (this.state.step === 3 && data.id) {
            this.changeStep();
        } // When successfully submitted and is step 3
        if (data.id) {
            history.push(`/member/certification/${type}/application/` + data.id); // When successfully saved and is step 2
        }
    }
    onManufacturerDetailsChange(e) {
        //its name of company
        this.setState({ manufacturerDetails: e.target.value });
    }
    onManufacturerNameOfCompanyChange(e) {
        const key = e.target.id;
        const value = e.target.value;
        const { validationObj, feedbackValidationObj } = this.getValidationObject('manufacturerDetails', key, value);
        if (this.state.sameAsManufacturer === true) {
            this.setState({
                manufacturerNameOfCompany: value,
                manufacturerNameOfCompanyPlant: value,
                manufacturerDetailsValidation: {
                    validationObj,
                    feedbackValidationObj,
                },
            });
        } else {
            if (key === 'manufacturerNameOfCompany') {
                this.setState({
                    manufacturerNameOfCompany: value,
                    manufacturerDetailsValidation: {
                        validationObj,
                        feedbackValidationObj,
                    },
                });
            } else if (key === 'manufacturerNameOfCompanyPlant') {
                this.setState({
                    manufacturerNameOfCompanyPlant: value,
                    manufacturerDetailsValidation: {
                        validationObj,
                        feedbackValidationObj,
                    },
                });
            }
        }
    }
    onManufacturerFactoryAddressChange(e) {
        const key = e.target.id;
        const value = e.target.value;
        const { validationObj, feedbackValidationObj } = this.getValidationObject('manufacturerDetails', key, value);
        if (this.state.sameAsManufacturer === true) {
            this.setState({
                manufacturerFactoryAddress: value,
                manufacturerFactoryAddressPlant: value,
                manufacturerDetailsValidation: {
                    validationObj,
                    feedbackValidationObj,
                },
            });
        } else {
            if (key === 'manufacturerFactoryAddress') {
                this.setState({
                    manufacturerFactoryAddress: value,
                    manufacturerDetailsValidation: {
                        validationObj,
                        feedbackValidationObj,
                    },
                });
            } else if (key === 'manufacturerFactoryAddressPlant') {
                this.setState({
                    manufacturerFactoryAddressPlant: value,
                    manufacturerDetailsValidation: {
                        validationObj,
                        feedbackValidationObj,
                    },
                });
            }
        }
    }
    onManufacturerContactPersonNameChange(e) {
        const key = e.target.id; // fullname
        const value = e.target.value;
        const { validationObj, feedbackValidationObj } = this.getValidationObject('applicantDetails', key, value);
        this.setState({
            manufacturerContactPersonName: value,
            applicantDetailsValidation: {
                validationObj,
                feedbackValidationObj,
            },
        });
    }
    onManufacturerTelephoneChange(e) {
        const key = e.target.id;
        const value = e.target.value;
        const { validationObj, feedbackValidationObj } = this.getValidationObject('applicantDetails', key, value);
        this.setState({
            manufacturerTelephone: value,
            applicantDetailsValidation: {
                validationObj,
                feedbackValidationObj,
            },
        });
    }
    getValidationObject(panelName, key, value) {
        const state = Object.assign({}, this.state[`${panelName}Validation`]);
        // Get validation result for validation object
        let validationObj = state.validationObj;
        validationObj[key] = cyderlib.validate(key, value);

        // Get validation result for feedback validation object
        let feedbackValidationObj = state.feedbackValidationObj;
        feedbackValidationObj[key] = cyderlib.validate(key, value);

        return {
            validationObj,
            feedbackValidationObj,
        };
    }
    onManufacturerISOChange(e) {
        this.setState({ manufacturerIsoCode: e.target.value });
    }
    isSelfFinancingCapableSelected(val) {
        const { value, label } = val;
        this.setState({ selectedYesNoOption: value !== '' ? new Boolean(value).valueOf() : '' });
    }
    onYesNoOptionChange(e) {
        this.onChangeHandlerSetSaveFlag();
        this.setState({ selectedYesNoOption: e.target.checked });
    }
    isDistributorChange(e) {
        this.onChangeHandlerSetSaveFlag();
        this.setState({ isDistributor: e });
    }
    onProductTypeChange(val) {
        if (!val) return;
        val = sanitizeStringAndObjectNullsToEmptyStrings(val); // Sanitize option with category 'Others'; Remove react warning
        const { category, subcategory } = this.getSelectedProductData(this.props.productTypes, val);
        this.setState({
            selectedProductType: val,
            selectedProductCategory: category || this.state.category,
            selectedProductSubcategory: subcategory || this.state.subcategory,
        });
    }
    getSelectedProductData(productTypes, val) {
        return productTypes.find(x => x.Id === val.value);
    }
    downloadProdPicture() {
        this.props.downloadProdPicture().then(res => {
            const contentType = parseContentType(res.data.contenttype);
            const str = res.data.attachment;
            blobDownload(res.data.filename, contentType, str);
        });
    }
    downloadProdCatalogue() {
        this.props.downloadProdCatalogue().then(res => {
            const contentType = parseContentType(res.data.contenttype);
            const str = res.data.attachment;
            blobDownload(res.data.filename, contentType, str);
        });
    }
    onChangeHandlerSetSaveFlag() {
        this.props.setSavedFlag(false);
    }
    getAllProductCertNumber() {
        const { productBrands } = this.state;
        if (!productBrands || productBrands.length === 0 || !productBrands[0]) return;
        var models = productBrands[0].models.filter(x => x.certificationNumber);
        models = loadsh.uniqBy(models, x => x.certificationNumber); // Remove duplicates
        // If no cert number only
        if (models.length === 0) return;
        // If one cert number only
        if (models.length === 1) return models[0].certificationNumber;
        // If multiple cert numbers exist
        const list = models.map((x, i) => <li key={i}>{x.certificationNumber}</li>);
        return (
            <Col>
                <ul>{list}</ul>
            </Col>
        );
    }
    getCertApplicationTitle() {
        const { step, status, service } = this.state;
        const { type } = this.props;
        const isTypeService = type === 'service';
        const serv = service ? JSON.parse(service) : {};
        const certNum = !isTypeService ? this.getAllProductCertNumber() : serv.certificationNumber;
        let title = `1. Applicant Details of ${isTypeService ? 'services' : type} to be certified`;
        if (step > 2) title = '2. Preview Application';
        if (status !== 'Draft') {
            title = (
                <h4 className="text-bold">
                    {isTypeService ? 'services' : type} Certification {certNum ? `No. ` : ''} {certNum || ''}
                </h4>
            );
        }
        return title;
    }
    // MODAL
    modalAction() {
        const { type } = this.state.modal;
        if (type === 'Submit') this.submitApplication();
        if (type === 'Saved') history.push(`/member/certification/${this.props.type}/application/` + this.state.certId);
        this.modalToggle();
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, null, null, true);
        return evaluation;
    }
    agreeTc() {
        var state = this.state;
        state.agreeTc = !state.agreeTc;
        this.setState({
            state,
        });
    }
    sameAsManufacturerChange() {
        var state = this.state;
        state.sameAsManufacturer = !state.sameAsManufacturer;
        state.sameAsManufacturerIsChecked = !state.sameAsManufacturerIsChecked;
        if (state.sameAsManufacturer === true) {
            state.manufacturerFactoryAddressPlant = state.manufacturerFactoryAddress;
            state.manufacturerNameOfCompanyPlant = state.manufacturerNameOfCompany;
        } else {
            state.manufacturerFactoryAddressPlant = '';
            state.manufacturerNameOfCompanyPlant = '';
        }
        this.setState({
            state,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    render() {
        const {
            step,
            status,
            modal,
            companyInfo,
            countryList,
            greenRemarks,
            applicationType,
            applicationDetails,
            manufacturerContactPersonName,
            manufacturerFactoryAddress,
            manufacturerNameOfCompany,
            manufacturerFactoryAddressPlant,
            manufacturerNameOfCompanyPlant,
            manufacturerCountryName,
            manufacturerCountryId,
            manufacturerTelephone,
            selectedYesNoOption,
            selectedProductType,
            selectedProductCategory,
            selectedProductSubcategory,
            productDetailsValidation,
            applicantDetailsValidation,
            manufacturerDetailsValidation,
            selectedCategoryValue,
            productBrands,
            paymentInfo,
            isDistributor,
        } = this.state;
        const { type, loading, categories, forceLoading, productTypes } = this.props;

        // GENERAL
        const { id } = this.props.match.params;
        const mode = window.location.pathname.includes('product') ? 'product' : 'service';
        const isTypeService = type === 'service';
        const editable = (step === 2 && status === 'Draft') || false;
        const service = isTypeService && this.state.service ? JSON.parse(this.state.service) : {};

        // LOCAL LOADING && REDUCER LOADING STATE
        if (this.state.loading || forceLoading) return <LoadingSpinner center />;
        return (
            <React.Fragment>
                <DialogModal
                    modalAction={this.modalAction}
                    actionToggleExternal={this.modalToggle}
                    negativeButtonAction={this.modalToggle}
                    positiveButtonHide={modal.positiveButtonHide}
                    negativeButtonHide={modal.negativeButtonHide}
                    modalHeader={modal.modalHeader}
                    modalBody={modal.modalMessage}
                    modalOpen={modal.showModal}
                />
                {this.props.savingApplChanges && step === 3 ? (
                    <LoadingSpinner center />
                ) : (
                    <React.Fragment>
                        {step === 1 && status === 'Draft' && (
                            <SelectCategoryPanel
                                mode={mode}
                                loading={loading}
                                categories={categories}
                                onSelectCategory={this.onSelectCategory}
                            />
                        )}
                        <Container className="p-0">
                            {/* {(step === 2 || step === 3) && status === 'Draft' && <CyderApplicationStepPanel step={step} />}
                            {step === 4 && status === 'Submitted' && <CyderApplicationStepPanel step={step} />} */}
                            {(step === 2 || step === 3) && <NotePanel status={status} step={step} />}
                            <br />
                            <Col className="p-0">
                                {step === 0 && <TermsAndConditions mode={mode} changeStep={this.changeStep} />}
                                <CardWrapper step={step}>
                                    <ApplicationDetailsPanel
                                        type={type}
                                        title={this.getCertApplicationTitle()}
                                        status={status}
                                        editable={editable}
                                        service={service}
                                        companyInfo={companyInfo}
                                        visible={step === 2 || step === 3}
                                        applicationDetails={applicationDetails}
                                        viewAssessment={this.viewAssessment}
                                        downloadInvoice={this.downloadInvoice}
                                        paynow={this.paynowLink}
                                        manufacturerContactPersonName={manufacturerContactPersonName}
                                        manufacturerTelephone={manufacturerTelephone}
                                        onManufacturerContactPersonNameChange={this.onManufacturerContactPersonNameChange}
                                        onManufacturerTelephoneChange={this.onManufacturerTelephoneChange}
                                        printCertificate={this.printCertByCertNumber}
                                        returnMua={this.returnMua}
                                        feedbackSurvey={this.feedbackSurvey}
                                        selectedYesNoOption={selectedYesNoOption}
                                        onYesNoOptionChange={this.onYesNoOptionChange}
                                        feedbackValidationObj={applicantDetailsValidation.feedbackValidationObj}
                                        paymentInfo={paymentInfo}
                                        productBrands={productBrands}
                                        loadPrinting={this.state.loadPrinting}
                                        printApplication={this.printApplication}
                                        downloadCertLogo={this.downloadCertLogo}
                                        isDistributorChange={this.isDistributorChange}
                                        isDistributor={isDistributor}
                                    />
                                    {this.props.type === 'service' && (
                                        <ServiceDetailsPanelV2
                                            visible={step === 2 || step === 3}
                                            editable={editable}
                                            selectedYesNoOption={this.state.selectedYesNoOption}
                                            isSelfFinancingCapableSelected={this.isSelfFinancingCapableSelected}
                                            serviceCategory={this.state.serviceCategory}
                                            serviceCategoryId={this.state.selectedCategory}
                                            selectedCategoryValue={selectedCategoryValue}
                                        >
                                            <TermsAndConditionsPanel
                                                step={step}
                                                savedThisSession={this.props.savedThisSession}
                                                agreeTc={this.agreeTc}
                                                agreeTcValue={this.state.agreeTc}
                                                downloadTermAndConditionsDocument={() => this.downloadTermAndConditionsDocument(mode)}
                                            />
                                        </ServiceDetailsPanelV2>
                                    )}
                                    {this.props.type === 'product' && (
                                        <ProductDetailsPanelV2
                                            editable={editable}
                                            loading={loading}
                                            visible={step === 2 || step === 3}
                                            productTypes={productTypes}
                                            selectedProductType={selectedProductType}
                                            selectedProductCategory={selectedProductCategory}
                                            selectedProductSubcategory={selectedProductSubcategory}
                                            onProductTypeChange={this.onProductTypeChange}
                                            selectedCategoryValue={selectedCategoryValue}
                                        >
                                            {(step === 2 || step === 3) && (
                                                <Col>
                                                    <ProductsListTable
                                                        printCertificate={this.printCertificate}
                                                        productBrands={productBrands}
                                                        editable={editable}
                                                        status={status}
                                                    >
                                                        {productBrands => this.setState({ productBrands })}
                                                    </ProductsListTable>
                                                </Col>
                                            )}
                                            {(step === 2 || step === 3) && (
                                                <Col>
                                                    <FormGroup className="mt-4 mb-0">
                                                        <Label className="field-header">
                                                            {capitalizeFirstLetter(type)}
                                                            &nbsp;Catalogue / Information&nbsp;
                                                            {editable && <MandatoryStar />}
                                                        </Label>
                                                    </FormGroup>
                                                    {editable && (
                                                        <React.Fragment>
                                                            <div style={{ width: '300' }}>
                                                                <em>Note: Zip files are accepted.</em>
                                                            </div>
                                                            <br />
                                                            <CyderFileUpload
                                                                attachmentId={this.props.new.productCatalogueResourceId}
                                                                filename={
                                                                    this.props.new.productCatalogueFilename
                                                                        ? this.props.new.productCatalogueFilename
                                                                        : this.state.productCatalogueFilename
                                                                }
                                                                onUploadSuccess={file => {
                                                                    this.props.setProductCatalogue(file.id, file.filename);
                                                                }}
                                                                onUploadFail={e => {
                                                                    throw e;
                                                                }}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    )}
                                                    {!editable && this.props.new.productCatalogueResourceId !== null && (
                                                        <React.Fragment>
                                                            <a
                                                                target="blank"
                                                                href={SGBC_S3_ROOT + this.props.new.productCatalogueResourceId}
                                                            >
                                                                {this.props.new.productCatalogueFilename}
                                                            </a>
                                                            <br />
                                                            <br />
                                                        </React.Fragment>
                                                    )}
                                                    <FormGroup className="mb-0">
                                                        <Label className="field-header">
                                                            Picture of {capitalizeFirstLetter(type)}
                                                            &nbsp;
                                                            {editable && <MandatoryStar />}
                                                        </Label>
                                                    </FormGroup>
                                                    {editable && (
                                                        <React.Fragment>
                                                            <CyderFileUpload
                                                                attachmentId={this.props.new.productPictureResourceId}
                                                                filename={
                                                                    this.props.new.productPictureFilename
                                                                        ? this.props.new.productPictureFilename
                                                                        : this.state.productPictureFilename
                                                                }
                                                                onUploadSuccess={file => {
                                                                    this.props.setProductPicture(file.id, file.filename);
                                                                }}
                                                                onUploadFail={e => {
                                                                    throw e;
                                                                }}
                                                            />
                                                            <br />
                                                        </React.Fragment>
                                                    )}
                                                    {!editable && this.props.new.productPictureResourceId && (
                                                        <React.Fragment>
                                                            <a target="blank" href={SGBC_S3_ROOT + this.props.new.productPictureResourceId}>
                                                                {this.props.new.productPictureFilename}
                                                            </a>
                                                            <br />
                                                            <br />
                                                        </React.Fragment>
                                                    )}
                                                </Col>
                                            )}

                                            <GreenPanel
                                                className="mt-3"
                                                editable={((step === 2 || step == 1) && status === 'Draft') || false}
                                                value={greenRemarks}
                                                visible={step === 2 || step === 3}
                                                onChange={this.handleGreenRemarkChange}
                                                valueKey="greenRemarks"
                                                validationObj={productDetailsValidation.feedbackValidationObj}
                                            />
                                            <hr />
                                        </ProductDetailsPanelV2>
                                    )}
                                    {type === 'product' && (
                                        <React.Fragment>
                                            <ManufacturerDetailsPanel
                                                editable={editable}
                                                countryList={countryList}
                                                visible={step === 2 || step === 3}
                                                manufacturerNameOfCompanyPlant={manufacturerNameOfCompanyPlant}
                                                manufacturerNameOfCompany={manufacturerNameOfCompany}
                                                manufacturerCountryId={manufacturerCountryId}
                                                manufacturerNameOfCountry={manufacturerCountryName}
                                                manufacturerFactoryAddress={manufacturerFactoryAddress}
                                                manufacturerFactoryAddressPlant={manufacturerFactoryAddressPlant}
                                                handleInputCountryChange={this.handleInputCountryChange}
                                                onManufacturerNameOfCompanyChange={this.onManufacturerNameOfCompanyChange}
                                                onManufacturerFactoryAddressChange={this.onManufacturerFactoryAddressChange}
                                                feedbackValidationObj={manufacturerDetailsValidation.feedbackValidationObj}
                                                sameAsManufacturer={this.state.sameAsManufacturer}
                                                sameAsManufacturerChange={this.sameAsManufacturerChange}
                                                sameAsManufacturerIsChecked={this.state.sameAsManufacturerIsChecked}
                                                step={this.state.step}
                                            />
                                            <TermsAndConditionsPanel
                                                step={step}
                                                savedThisSession={this.props.savedThisSession}
                                                agreeTc={this.agreeTc}
                                                agreeTcValue={this.state.agreeTc}
                                                downloadTermAndConditionsDocument={() => this.downloadTermAndConditionsDocument(mode)}
                                            />
                                        </React.Fragment>
                                    )}
                                    <RemarksPanel
                                        className="mt-3"
                                        editable={true}
                                        onChange={this.handleRemarkChange}
                                        visible={this.state.step === 3 && this.state.applicationType === 2 ? true : false}
                                    />
                                </CardWrapper>
                                {this.state.status === 'Draft' && (
                                    <ButtonPanel
                                        step={step}
                                        agreeTcValue={this.state.agreeTc}
                                        renewOrNew={applicationType}
                                        error={this.props.error}
                                        changeStep={this.changeStep}
                                        setFileLength={this.setFileLength}
                                        errorMessage={this.props.errorMessage}
                                        saveApplicationChanges={this.saveCertAppl}
                                        savedThisSession={this.props.savedThisSession}
                                        savingApplChanges={this.props.savingApplChanges}
                                        validationBeforeSubmission={this.validationBeforeSubmission}
                                        canProceedToPreview={this.state.canProceedToPreview}
                                    />
                                )}
                                <ConfirmationPanel visible={step === 4} />
                            </Col>
                        </Container>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
const TermsAndConditionsPanel = ({ step, savedThisSession, agreeTc, agreeTcValue, downloadTermAndConditionsDocument }) => {
    if (!savedThisSession) return null;
    if (step !== 3) return null;
    return (
        <div>
            <hr />
            <div>
                <input type="checkbox" checked={agreeTcValue} onChange={agreeTc} />
                &nbsp;
                <label>
                    I have read and agree to the SGBC Certification
                    <a href="#" onClick={downloadTermAndConditionsDocument}>
                        &nbsp;<u>Terms and Conditions</u>&nbsp;
                    </a>
                    .
                </label>
                <p>
                    Disclaimer: All fees are subjected to prevailing Government taxes and an administrative fee of S$50 will be levied for
                    overseas payments.
                </p>
            </div>
        </div>
    );
};

const RemarksPanel = ({ editable, onChange, visible, className }) => {
    if (!visible) return null;
    return (
        <div className={className}>
            <Row>
                <Col>
                    <div className="form-group">
                        <label className="field-header">Remarks </label>
                        <textarea disabled={!editable} className="form-control" rows="6" onChange={onChange} />
                        {editable && <small className="form-text text-muted">Please provide remarks for renewal submission</small>}
                        {/* {!editable && <div className="field-label">-</div>} */}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const GreenPanel = ({ editable, onChange, visible, value, className, valueKey = '', validationObj = {} }) => {
    return (
        <Col className={className}>
            <Row>
                <Col>
                    <div className="form-group">
                        {visible && (
                            <label className="field-header">
                                {editable && (
                                    <span>
                                        Please indicate the product’s Green Feature(s) and/or Attribute(s), (e.g. low VOC level, energy
                                        saving properties, recyclability, usage of recycled content, etc.).
                                        <MandatoryStar />{' '}
                                    </span>
                                )}
                                {!editable && <span>Product Green Feature(s)/ Attribute(s)</span>}
                            </label>
                        )}
                        {visible && editable && (
                            <InputFieldWithValidation
                                rows={6}
                                editable={editable}
                                type="textarea"
                                labelStyle={{ display: 'none' }}
                                value={value}
                                valueKey={valueKey}
                                validationObj={validationObj} //ValidationObj key must be similar to valueKey
                                handleChange={onChange}
                                saveButtonPressed={true}
                            />
                        )}
                        {visible && !editable && <p>{value}</p>}
                        {visible && editable && <small className="form-text text-muted">Please provide remarks for Green submission</small>}
                    </div>
                </Col>
            </Row>
        </Col>
    );
};

const ConfirmationPanel = ({ visible }) =>
    visible && (
        <Card>
            <CardBody>
                <NoShadowPanel
                    flat
                    textLowerCase
                    title="3. Confirmation"
                    descriptionStyle={{ fontSize: '18px' }}
                    description="This application has been successfully submitted. SGBC will be in touch once your certification application has been evaluated."
                >
                    <div className="text-right mt-4">
                        <Button className="primary-btn-style primary-btn-sm" onClick={() => history.push('/member/myaccount/dashboard')}>
                            Done
                        </Button>
                    </div>
                </NoShadowPanel>
            </CardBody>
        </Card>
    );

const NotePanel = ({ status, step }) => {
    const getNoteBystep = () => {
        return step !== 2
            ? 'Applicants are advised to ensure that all information submitted is true, accurate and correct. Invalid or inaccurate information will result in delays to the processing of the Application.'
            : 'All fields marked with an * sign are required for the application to proceed. A draft of the Application can be saved and edited before submission. Incomplete information will be rejected.';
    };
    return (
        <div>
            <p className="text-justify">
                {status !== 'Draft'
                    ? 'To print this page, click on the Printer icon. You will be prompted to download a PDF file which you can save and print.'
                    : getNoteBystep()}
            </p>
        </div>
    );
};

const CardWrapper = ({ step, children }) => {
    return step && step !== 0 && step !== 1 && step !== 4 ? (
        <Card>
            <CardBody>{children}</CardBody>
        </Card>
    ) : (
        children
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.memberProductApplication,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getRelevantData: (id, type) => {
            const array = [dispatch(getCategoryList(type)), dispatch(getSavedCertAppl(id, type))];
            return Promise.all(array).then(res => res[1].data);
        },
        getCategoryList: type => {
            dispatch(getCategoryList(type));
        },
        getProductTypes: id => {
            return dispatch(getProductTypes(id));
        },
        getCountriesList: () => {
            return dispatch(getCountriesGeneric());
        },
        getCompanyInfo: () => {
            return dispatch(memberMembershipApplicationGetCompanyInfo());
        },
        forceSetLoading: loadingState => {
            dispatch(forceSetLoading(loadingState));
        },
        setSavedFlag: savedThisSession => {
            dispatch(setSavedFlag(savedThisSession));
        },
        setProductCatalogue: (resourceId, filename) => {
            return dispatch({
                type: 'MEMBER_CERTIFICATION_NEW_APPL_SET_PRODUCT_CATALOGUE',
                resourceId,
                filename,
            });
        },
        setProductPicture: (resourceId, filename) => {
            return dispatch({
                type: 'MEMBER_CERTIFICATION_NEW_APPL_SET_PRODUCT_PICTURE',
                resourceId,
                filename,
            });
        },
        saveCertApplication: (type, body, forSubmit) => {
            return dispatch(saveCertApplication(type, body, forSubmit));
        },
        getInvoiceInfo: id => {
            return dispatch(getInvoiceInfo(id));
        },
        setSavedFlag: savedThisSession => {
            dispatch(setSavedFlag(savedThisSession));
        },
        resetStateToDefault: () => {
            return dispatch(resetStateToDefault());
        },
        getActiveSurveyByCategory: surveyCategoryId => {
            return dispatch(getActiveSurveyByCategory(surveyCategoryId));
        },
        setPostSurveyUrl: postSurveyUrl => {
            return dispatch({
                type: 'SHARED_SURVEY_SET_POST_SURVEY_URL',
                postSurveyUrl,
            });
        },
        setApplicationId: applicationId => {
            return dispatch({
                type: 'SHARED_SURVEY_SET_APPLICATION_ID',
                applicationId,
            });
        },
        getPaymentInfo: id => {
            return dispatch(getPaymentInfo(id));
        },
        returnMua: id => {
            return dispatch(returnMua(id));
        },
        printCert: (certNumber, type) => {
            return dispatch(printCertByCertNumber(certNumber, type));
        },
        findByCategoryAndName: (categoryId, name) => {
            return dispatch(findByCategoryAndName(categoryId, name));
        },
        printCertificationApplication: (id, type, muaLocked) => {
            return dispatch(printCertificationApplication(id, type, muaLocked));
        },
        downloadCertificate: certificateId => {
            return dispatch(downloadCertificate(certificateId));
        },
        findCertByCertNumber: certificationNumber => {
            return dispatch(findCertByCertNumber(certificationNumber));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationLayout(MemberCertNewApplicationPage));

import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';

import { FormGroup, Label, Input, Alert, Col, Row, Button } from 'reactstrap';
import { Async } from 'react-select';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import TopNavigation2 from '../../layouts/TopNavigation2';

import './Login.css';

import { cyderLoginAction, cyderLoginResetState, cyderResetProfileAction, getCompanies } from '../../actions/security/cyderSecurityAction';
import { myCorpTFASocket } from '../../actions/tfa';
import { isMobile } from 'react-device-detect';

const loginPanelStyle1 = {
    textAlign: 'center',
    // float: 'left',
    display: 'inline',
    width: '400px',
    textRendering: 'optimizeLegibility',
    WebkitFontSmoothing: 'antialiased',
};
const loginPanelStyle2 = {
    height: '45px',
    border: '1px solid #ababab',
    borderRadius: '5px',
    marginBottom: '20px',
    padding: '10px 12px',
    width: '100%',
    backgroundColor: 'rgb(248, 248, 248)',
};
export const loginPanelStyle3 = {
    height: '45px',
    border: '1px solid #ababab',
    borderRadius: '5px',
    display: 'inline',
    padding: '10px 12px',
    transition: 'all 0.4s ease 0s',
    width: '100%',
    textRendering: 'optimizeLegibility',
    WebkitFontSmoothing: 'antialiased',
    WebkitAppearance: 'textfield',
    backgroundColor: 'white',
    WebkitRtlOrdering: 'logical',
    cursor: 'text',
    letterSpacing: 'normal',
    wordSpacing: 'normal',
    textTransform: 'none',
    textIndent: '0px',
    textShadow: 'none',
};
const loginPanelStyle4 = {
    WebkitAppearance: 'button',
    cursor: 'pointer',
    backgroundColor: '#fff',
    padding: '10px 15px',
    borderRadius: '4px',
    textTransform: 'uppercase',
    transition: 'all 0.4s ease 0s',
    border: '1px solid #78adc9',
    color: '#78adc9',
    letterSpacing: 'normal',
    wordSpacing: 'normal',
    textIndent: '0px',
    textShadow: 'none',
    display: 'inline-block',
};
export const loginPanelStyle5 = {
    //  float: 'left',
    display: 'inline',
    width: '50%',
    fontSize: '14px',
    lineHeight: '1.42857143',
    color: '#333',
};
export const asyncSelectStyle = {
    control: styles => ({ ...styles, borderColor: '#ababab', opacity: '0.9', minHeight: 32 }),
    valueContainer: styles => ({
        ...styles, // padding: '5px',
        paddingTop: '7px',
        paddingBottom: '7px',
    }),
    option: styles => ({
        ...styles,
        color: '#333',
    }),
};
class PublicLogin extends React.Component {
    constructor(props) {
        super(props);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.showLoadingCompany = this.showLoadingCompany.bind(this);
        this.showLoadingSubmitLogin = this.showLoadingSubmitLogin.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
        this.register = this.register.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
        this.showTwoFactorLoading = this.showTwoFactorLoading.bind(this);
        this.cancelTfaRequest = this.cancelTfaRequest.bind(this);
        this.state = {
            companies: [],
            selectedCompany: {},
            selectedCompanyId: 0,
            select: {
                disabled: false,
                searchable: true,
                clearable: true,
            },
            email: '',
            password: '',
            loadingCompany: false,
            loadingSubmitLogin: false,
            error: null,
            twoFactorLoading: false,
            tfaId: null,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.resetLoginPageState();
        this._isMounted = true;

        /**
         * Reset local storage of 'loginFrom'
         * This is used on MemberProfileEdit.js
         * */
        localStorage.setItem('loginFrom', '');
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getCompanies(input) {
        try {
            const inputLength = input.length;
            const companiesObj = await this.props.getCompanies(input);
            const companies = companiesObj.data
                .map(company => {
                    const { name, id } = company;
                    if (name.substring(0, inputLength).toLowerCase() === input.toLowerCase()) {
                        return {
                            label: name,
                            value: id,
                        };
                    }
                })
                .filter(x => x);
            if (this._isMounted) {
                this.setState({
                    companies,
                });
            }
            return companies;
        } catch (error) {
            throw error;
        }
    }
    showLoadingSubmitLogin(show) {
        this.setState({
            loadingSubmitLogin: show,
        });
    }
    showLoadingCompany(show) {
        this.setState({
            loadingCompany: show,
        });
    }
    onCompanyChange(e) {
        this.setState({
            selectedCompany: e,
            selectedCompanyId: e.value,
        });
    }
    onPasswordChange(e) {
        this.setState({
            password: e.target.value,
        });
    }
    onEmailChange(e) {
        this.setState({
            email: e.target.value,
        });
    }
    showAlert(message) {
        this.setState({
            error: message,
        });
    }
    cancelTfaRequest() {
        this.setState({ email: '', password: '' });
        myCorpTFASocket.cancelTfaRequest(this.state.tfaId);
    }
    showTwoFactorLoading(twoFactorLoading) {
        this.setState({
            twoFactorLoading,
        });
    }
    twoFactorAuth(res) {
        const { data, needchangepassword, firstlogin } = res;
        const { email } = data;
        myCorpTFASocket.init(email.toLowerCase(), 'sgbc', 'sgbccrm', 'TFA', () => {
            this.showTwoFactorLoading(true);
            myCorpTFASocket.sendTfaRequest(tfaResponse => {
                console.log('Get response from TFA: ', tfaResponse);
                this.setState({ tfaId: tfaResponse.id });
                this.showTwoFactorLoading(false);
                if (tfaResponse.approved === 'TRUE') {
                    if (needchangepassword === 'Y' || firstlogin === 'Y') {
                        history.push('/changepassword');
                    } else {
                        this.props.setLayout('default-sidebar-1');
                        history.push('/staff/home');
                    }
                }
            });
        });
    }
    submitLogin() {
        this.showAlert(null);
        this.showLoadingSubmitLogin(true);
        const data = {
            username: this.state.email,
            password: this.state.password,
            organization: this.state.selectedCompanyId,
        };

        this.props
            .submitLogin(data)
            .then(res => {
                this.showLoadingSubmitLogin(false);
                let errorMessage = res.errormessage ? res.errormessage : res.data ? res.data.errormessage : null;
                if (errorMessage) {
                    this.showAlert(errorMessage);
                } else {
                    if (res.data.is_staff === 1) {
                        if (res.data.tfa_enabled === 1) {
                            this.twoFactorAuth(res);
                        } else {
                            this.props.setLayout('default-sidebar-1');
                            history.push('/staff/home');
                        }
                    } else {
                        if (res.needchangepassword === 'Y' || res.firstlogin === 'Y') {
                            var navigate = '/changepassword';
                            history.push(navigate);
                        } else {
                            this.props.setLayout('top-navigation-2');
                            var navigate = this.props.location === 'renew' ? '/member/membership/overview' : '/member/home';
                            history.push(navigate);
                        }
                    }
                    //if (res.needchangepassword === 'Y' || res.firstlogin === 'Y') {
                    //    var navigate = '/changepassword';
                    //    history.push(navigate);
                    //} else {
                    //    if (res.data.is_staff) {
                    //        this.props.setLayout('default-sidebar-1');
                    //    } else {
                    //        this.props.setLayout('top-navigation-2');
                    //    }
                    //    var navigate =
                    //        res.data.is_staff === 1
                    //            ? '/staff/home'
                    //            : this.props.location === 'renew'
                    //            ? '/member/membership/overview'
                    //            : '/member/home';
                    //    history.push(navigate);
                    //}
                }
            })
            .catch(error => {
                this.showLoadingSubmitLogin(false);
                this.showAlert('Invalid username or password');
                throw error;
            });
    }
    register() {
        history.push('/public/createaccount');
    }
    render() {
        return (
            <LoginPanel
                cancelTfaRequest={this.cancelTfaRequest}
                showTwoFactorLoading={this.showTwoFactorLoading}
                twoFactorLoading={this.state.twoFactorLoading}
                onCompanyChange={this.onCompanyChange}
                email={this.state.email}
                onEmailChange={this.onEmailChange}
                password={this.state.password}
                onPasswordChange={this.onPasswordChange}
                submitLogin={this.submitLogin}
                loadingSubmitLogin={this.state.loadingSubmitLogin}
                error={this.state.error}
                getCompanies={this.getCompanies}
                changePanel={this.props.changePanel}
            />
        );
    }
}

export const InputComponentWrapper = ({ label, children, asterisk }) => {
    return (
        <FormGroup
            style={{
                color: 'white',
                textAlign: 'left',
                paddingLeft: '20px',
                paddingRight: '20px',
            }}
        >
            <Label>
                {label}
                &nbsp;
                {asterisk && <span className="asteriskField">*</span>}
            </Label>
            {children}
        </FormGroup>
    );
};

const LoginPanel = ({
    cancelTfaRequest,
    twoFactorLoading,
    onCompanyChange,
    email,
    onEmailChange,
    password,
    onPasswordChange,
    submitLogin,
    loadingSubmitLogin,
    error,
    getCompanies,
    changePanel,
    showTwoFactorLoading,
}) => {
    return (
        <div className="login-panel" style={{ ...loginPanelStyle5, paddingTop: '20px' }}>
            <Row className="text-left mb-2">
                <Col>
                    <div className="welcome">
                        <span>Log</span> In
                    </div>
                </Col>
            </Row>
            {!twoFactorLoading ? (
                <div>
                    {error && <Alert color="danger text-bold">{error}</Alert>}
                    <InputComponentWrapper label="Organisation">
                        <Async
                            name="company"
                            placeholder=""
                            cacheOptions
                            defaultOptions
                            loadOptions={getCompanies}
                            onChange={onCompanyChange}
                            styles={asyncSelectStyle}
                            className="input-transparent"
                        />
                    </InputComponentWrapper>
                    <InputComponentWrapper label="Email">
                        <Input
                            // style={loginPanelStyle3}
                            type="text"
                            name="email"
                            id="email"
                            value={email}
                            placeholder=""
                            onKeyDown={e => {
                                if (e.keyCode == 13) submitLogin();
                            }}
                            onChange={onEmailChange}
                            className="input-transparent"
                        />
                    </InputComponentWrapper>
                    <InputComponentWrapper label="Password" style={{ marginBottom: '1.5em' }}>
                        <Input
                            // style={{ ...loginPanelStyle3, marginBottom: '0px' }}
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            placeholder=""
                            onKeyDown={e => {
                                if (e.keyCode == 13) submitLogin();
                            }}
                            onChange={onPasswordChange}
                            className="input-transparent"
                        />
                        <div className="forgot" onClick={() => changePanel(5)}>
                            Forgot your password?
                        </div>
                    </InputComponentWrapper>
                    {loadingSubmitLogin ? (
                        <LoadingSpinner style={{ color: 'white' }} />
                    ) : (
                        <React.Fragment>
                            <Button onClick={() => changePanel(4)} className="primary-dark">
                                Create an account
                            </Button>{' '}
                            &nbsp;
                            <Button onClick={submitLogin} className="primary-green">
                                Login
                            </Button>
                        </React.Fragment>
                    )}
                    <br />
                    <br />
                </div>
            ) : (
                <div>
                    <div className="twofactor">
                        Please verify your login on <span>MyCorp InstaSecure</span>.
                    </div>
                    <br />
                    <Button
                        onClick={() => {
                            showTwoFactorLoading(false);
                            cancelTfaRequest();
                        }}
                        className="primary-dark"
                    >
                        Cancel
                    </Button>
                    <br />
                    <br />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {
        getCompanies: keyword => {
            return dispatch(getCompanies(keyword));
        },
        submitLogin: data => {
            return dispatch(cyderLoginAction(data));
        },
        setLayout: layout => {
            dispatch({
                type: 'SET_CONFIG',
                config: {
                    layout: layout,
                },
            });
        },
        resetLoginPageState: () => {
            dispatch(cyderLoginResetState());
            dispatch(cyderResetProfileAction());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation2(PublicLogin));

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import { withRouter } from 'react-router-dom';
import { FormGroup, Input, Row, Col, Button } from 'reactstrap';
import CyderReactTable from 'cyder/table/CyderReactTable';
import Select from 'react-select';
import { eventsAllGetEventsAction, eventsAllChangeInput } from 'actions/staff/events/events/eventsAllAction';

class EventsAllPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.state = {
            status: {
                value: 'all',
                label: 'All',
            },
            statusSelectOptions: [
                {
                    value: 'all',
                    label: 'All',
                },
                {
                    value: 'upcoming',
                    label: 'Upcoming',
                },
                {
                    value: 'past',
                    label: 'Past',
                },
            ],
            keyword: '',
        };
        //this.onSelectChange = this.onSelectChange.bind(this);
    }
    handleInputChange(event) {
        if (event.target === undefined) {
            this.setState({ status: event });
        } else {
            this.setState({ keyword: event.target.value });
        }
    }
    componentDidMount() {
        if (this.props.location.state) {
            if (this.props.location.state.type === 'upcoming') {
                this.handleInputChange({
                    value: 'upcoming',
                    label: 'Upcoming',
                });
                setTimeout(() => {
                    this.table.fireFetchData();
                }, 150);
            }
        }
    }
    render() {
        const { keyword, status, statusSelectOptions } = this.state;
        const columns = [
            {
                Header: 'Event',
                accessor: 'eventName',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 150,
            },
            {
                Header: 'Type',
                accessor: 'eventType',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 150,
                Cell: props => {
                    return props.value;
                },
            },
            {
                Header: 'Venue',
                accessor: 'eventVenue',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 100,
                Cell: props => {
                    return <div dangerouslySetInnerHTML={{ __html: props.value }} />;
                },
            },
            {
                Header: 'Dates',
                accessor: 'eventDates',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 70,
                maxWidth: 100,
                Cell: props => {
                    return <div dangerouslySetInnerHTML={{ __html: props.value }} />;
                },
            },
            {
                Header: 'Registrants',
                accessor: 'eventRegistered',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                maxWidth: 100,
                Cell: props => {
                    return (
                        <Link to={'./' + props.original.eventId + '/registrations'}>
                            <Button className="primary-btn-style primary-btn-xxs">
                                <i className="fa fa-fw fa-ticket" />
                                &nbsp;
                                {props.value}
                            </Button>
                        </Link>
                    );
                },
            },
            {
                Header: 'Attendees',
                accessor: 'eventAttended',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                maxWidth: 100,
                Cell: props => {
                    return (
                        <Link to={'./' + props.original.eventId + '/attendees'}>
                            <Button className="primary-btn-style primary-btn-xxs">
                                <i className="fa fa-fw fa-group" />
                                &nbsp;
                                {props.value}
                            </Button>
                        </Link>
                    );
                },
            },
            {
                Header: '',
                sortable: false,
                accessor: 'eventId',
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Link to={'./' + props.value + '/main'}>
                            <Button className="primary-btn-style">
                                <i className="mr-1 material-icons align-text-bottom ">edit</i>
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <div className="page-widget">
                <Row className="mb-2">
                    <Col xs={12}>
                        <div className="form-inline d-flex">
                            <FormGroup className="mb-4 mr-sm-4 mb-sm-0">
                                <Input
                                    type="text"
                                    id="keywordSearch"
                                    placeholder="Keyword"
                                    value={keyword}
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.props.getAllEvents(null, null, keyword, null, null, status.value);
                                        }
                                    }}
                                    onChange={this.handleInputChange}
                                />
                                <Select
                                    onChange={this.handleInputChange}
                                    value={status}
                                    className="w-200 ml-2"
                                    placeholder=""
                                    autofocus
                                    options={statusSelectOptions}
                                    id="status-select"
                                    simpleValue
                                    name="selected-state"
                                    searchable={true}
                                />
                            </FormGroup>
                            <Button
                                className="primary-btn-style"
                                onClick={() => this.props.getAllEvents(null, null, keyword, null, null, status.value)}
                            >
                                Search
                            </Button>
                            <div className="ml-auto">
                                <Link to="./create">
                                    <Button className="primary-btn-style">
                                        <i className="mr-1 material-icons align-text-bottom">add</i> Create New Event
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            manual
                            className="-highlight mb-2"
                            data={this.props.applications}
                            defaultPageSize={10}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            pages={this.props.tablePages}
                            totalNum={this.props.totalNum}
                            loading={this.props.loading}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            onFetchData={(state, instance) => {
                                this.props.getAllEvents(
                                    state.page,
                                    state.pageSize,
                                    '',
                                    state.sorted,
                                    state.filtered,
                                    this.state.status.value,
                                );
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ...state.eventsAllReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getAllEvents: (page, pageSize, keyword, sorted, filtered, status) => {
            dispatch(eventsAllGetEventsAction(page, pageSize, keyword, sorted, filtered, status));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(StoredLayout(EventsAllPage)));

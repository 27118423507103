import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../history';

import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import { Row, Col, Form, Button } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import { Async } from 'react-select';

import InputField, { ButtonComponent } from 'pages/admin/companies/shared/InputField';

import { getCompanies } from 'actions/security/cyderSecurityAction';
import { save, update, remove, load, updateUserId } from 'actions/staff/mailinglist/action.js';

import { actionExecutor } from 'js/util';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.companyIdChange = this.companyIdChange.bind(this);
        this.companyNameChange = this.companyNameChange.bind(this);
        this.mobileNumberChange = this.mobileNumberChange.bind(this);
        this.designationChange = this.designationChange.bind(this);
        this.lastNameChange = this.lastNameChange.bind(this);
        this.firstNameChange = this.firstNameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);

        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);

        this.onUserIdChange = this.onUserIdChange.bind(this);
        this.updateUserId = this.updateUserId.bind(this);

        this.showModal = this.showModal.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.state = {
            loading: false,
            companies: [],
            data: {
                companyId: '',
                companyName: '',
                mobileNumber: '',
                designation: '',
                lastName: '',
                firstName: '',
                email: '',
                id: '',
                isSubsidiary: false,
                subsidiaryCompany: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace('/staff/mailinglist');
    }
    componentDidMount() {
        this.getMailingListDetails();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getCompanies = input => {
        const callback = async () => {
            const companiesRes = await this.props.getCompanies(input);
            const companies = companiesRes.data.map(company => {
                return {
                    label: company.name,
                    value: company.id,
                };
            });
            this.setState({
                companies,
            });
            return companies;
        };
        return actionExecutor(() => { }, callback);
    };
    getMailingListDetails = () => {
        const callback = async () => {
            const id = this.props.match.params.id;
            if (!id || id == 0) return;
            const { data, message } = await this.props.load(id);
            if (!data && message) {
                this.showModal('Error', message, 'load', true, true);
                return;
            }
            if (this._isMounted && data.companyId !== null && data.companyId !== 0) {
                data.selectedCompany = {
                    label: data.realCompanyName,
                    value: data.companyId,
                };
            }
            this.setState({
                data,
            });
        };
        const catchCallback = error => this.showModal('Error', error.message, 'load', true, true);
        actionExecutor(this.showLoading, callback, catchCallback);
    };
    updateUserId() {
        const callback = async () => {
            const { error, message } = await this.props.updateUserId(this.state.data);
            if (error && message) {
                this.showModal('Error', message, 'save', true, true);
                return;
            } else {
                this.showModal('Success', 'User ID updated successfully', 'save', true, true);
            }
        };
        const catchCallback = error => {
            this.showModal('Error', error.message, 'update', true, true);
        };
        actionExecutor(this.showLoading, callback, catchCallback);
    }

    prepareDataBeforeSave(data) {
        var newData = data;
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        const callback = async () => {
            if (!data.isSubsidiary) {
                data.subsidiaryCompany = '';
            }
            const { error, message } = data.id ? await this.props.update(data) : await this.props.save(data);
            if (error && message) {
                this.showModal('Error', message, 'save', true, true);
                return;
            }

            if (data.id) {
                history.replace('/staff/mailinglist', 'update', true, true);
            } else {
                history.replace('/staff/mailinglist');
            }
        };
        const catchCallback = error => {
            this.showModal('Error', error.message, 'update', true, true);
        };
        actionExecutor(this.showLoading, callback, catchCallback);
    }
    remove() {
        const callback = async () => {
            const delResponse = await this.props.remove(this.state.data.id); 
            if (delResponse.data && !delResponse.data.errno) {
                this.showModal('Confirmation', 'Successfully deleted mailing list', 'listPage', null, true);
                return;
            }
            const errorDetails = delResponse.error && delResponse.error.sqlMessage ? delResponse.error.sqlMessage : '';
            this.showModal('Error', `Failed to delete mailing list. This record cannot be deleted due to existance of other reference data in the CRM ------ ${errorDetails}`, null, null, true);
        };
        const catchCallback = error => {
            this.showModal('Error', error.message, 'delete', true, true);
        };
        actionExecutor(this.showLoading, callback, catchCallback);
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    companyNameChange(e) {
        var data = this.state.data;
        data.companyName = e.target.value;
        this.setState({ data });
    }
    companyIdChange(e) {
        var data = this.state.data;
        data.companyId = e.target.value;
        this.setState({ data });
    }
    mobileNumberChange(e) {
        var data = this.state.data;
        data.mobileNumber = e.target.value;
        this.setState({ data });
    }
    onUserIdChange(e) {
        var data = this.state.data;
        data.userId = e.target.value;
        this.setState({ data });
    }
    designationChange(e) {
        var data = this.state.data;
        data.designation = e.target.value;
        this.setState({ data });
    }
    emailChange(e) {
        var data = this.state.data;
        data.email = e.target.value;
        this.setState({ data });
    }
    lastNameChange(e) {
        var data = this.state.data;
        data.lastName = e.target.value;
        this.setState({ data });
    }
    firstNameChange(e) {
        var data = this.state.data;
        data.firstName = e.target.value;
        this.setState({ data });
    }
    onCompanyChange(e) {
        var data = this.state.data;
        if (e instanceof Array) {
            data.companyId = null;
            data.companyName = null;
        } else {
            data.companyId = e.value;
            // data.companyName = e.label;
            data.selectedCompany = e;
        }

        this.setState({ data }, () => this.getCompanies(e.value));
    }
    // MODAL
    modalAction() {
        const { type } = this.state.modal;
        if (type === 'deleteConfirm') this.remove();
        if (type === 'listPage') history.push('/admin/mailinglist/');
        this.modalToggle();
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(loading) {
        if (this._isMounted)
            this.setState({
                loading,
            });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.type = type;
        modal.modalHeader = header;
        modal.modalMessage = message;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        this.setState({
            modal,
        });
    }
    render() {
        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <Col className="pt-4 pb-4 page-widget">
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                Back
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                </Form>
                <InputField
                    label="First Name"
                    inputProps={{
                        type: 'text',
                        name: 'firstName',
                        id: 'firstName',
                        value: this.state.data.firstName || '',
                        onChange: this.firstNameChange,
                        style: { width: '400px' },
                    }}
                />
                <InputField
                    label="Last Name"
                    inputProps={{
                        type: 'text',
                        name: 'lastName',
                        id: 'lastName',
                        value: this.state.data.lastName || '',
                        onChange: this.lastNameChange,
                        style: { width: '400px' },
                    }}
                />
                <InputField
                    label="Designation"
                    inputProps={{
                        type: 'text',
                        name: 'designation',
                        id: 'designation',
                        value: this.state.data.designation || '',
                        onChange: this.designationChange,
                        style: { width: '200px' },
                    }}
                />
                <InputField
                    label="Email"
                    inputProps={{
                        type: 'text',
                        name: 'email',
                        id: 'email',
                        value: this.state.data.email || '',
                        onChange: this.emailChange,
                        style: { width: '400px' },
                    }}
                />
                <InputField
                    label="Contact Number"
                    inputProps={{
                        type: 'text',
                        name: 'mobileNumber',
                        id: 'mobileNumber',
                        value: this.state.data.mobileNumber || '',
                        onChange: this.mobileNumberChange,
                        style: { width: '200px' },
                    }}
                />
                {/* <InputField
                    label="Company Id"
                    inputProps={{
                        type: 'text',
                        name: 'companyId',
                        id: 'companyId',
                        value: this.state.data.companyId,
                        onChange: this.companyIdChange,
                        style: { width: '400px' },
                    }}
                /> */}
                <InputField label="SGBC Member Organisation">
                    <Col xs="4" className="p-0">
                        <Async
                            cacheOptions
                            defaultOptions
                            loadOptions={this.getCompanies}
                            onChange={this.onCompanyChange}
                            name="company"
                            placeholder="Search Company"
                            value={this.state.data.selectedCompany}
                        />
                    </Col>
                </InputField>

                <InputField
                    label="Is a Subsidiary Company"
                    inputProps={{
                        type: 'checkbox',
                        id: 'isSubsidiary',
                        onChange: e => {
                            var data = this.state.data;
                            data.isSubsidiary = e.target.checked ? 1 : 0;
                            this.setState({
                                data,
                            });
                        },
                        checked: this.state.data.isSubsidiary,
                    }}
                />

                {this.state && this.state.data && this.state.data.isSubsidiary == 1 && (
                    <InputField
                        label="Subsidiary Company Name"
                        inputProps={{
                            type: 'text',
                            name: 'subsidiaryCompany',
                            id: 'subsidiaryCompany',
                            value: this.state.data.subsidiaryCompany || '',
                            onChange: e => {
                                var data = this.state.data;
                                data.subsidiaryCompany = e.target.value;
                                this.setState({
                                    data,
                                });
                            },
                            style: { width: '600px' },
                        }}
                    />
                )}

                <InputField
                    label="Non-SGBC Member"
                    inputProps={{
                        type: 'text',
                        name: 'companyName',
                        id: 'companyName',
                        value: this.state.data.companyName || '',
                        onChange: this.companyNameChange,
                        style: { width: '600px' },
                    }}
                />
                <hr />
                <FlexRow between="xs">
                    <ButtonComponent className="primary-btn-style" onClick={this.save} label="Save" />
                    {this.state.data.id > 0 ? (
                        <FlexCol xs={2} className="d-flex justify-content-end">
                            <ButtonComponent
                                className="primary-btn-style-outline"
                                colClassName="d-flex justify-content-end"
                                onClick={this.removeConfirm}
                                label="Delete"
                            />
                            <AuditListPopup contentTypeId={15} objectId={this.state.data.id} />
                        </FlexCol>
                    ) : null}
                </FlexRow>

                <hr />

                <Row>
                    <Col xs="2">
                        <InputField
                            label="User ID"
                            inputProps={{
                                type: 'text',
                                name: 'userId',
                                id: 'userId',
                                value: this.state.data.userId || '',
                                onChange: this.onUserIdChange,
                                style: { width: '100px' },
                            }}
                        />
                    </Col>{' '}
                    <Col xs="2" style={{ paddingTop: '37px' }}>
                        <ButtonComponent
                            className="primary-btn-style"
                            onClick={e => {
                                window.open(`/admin/auth/users/${this.state.data.userId}`);
                            }}
                            label="View"
                        />
                        <br />
                        <ButtonComponent className="primary-btn-style-outline" onClick={this.updateUserId} label="Update User ID" />
                    </Col>
                </Row>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        updateUserId: data => {
            return dispatch(updateUserId(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
        getCompanies: input => {
            return dispatch(getCompanies(input));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));

import React from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import history from '../../../../../history';
import { SGBC_S3_HOST } from 'config';

// COMPONENT
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import DialogModal from 'cyder/modals/DialogModal';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { printCertificationApplication, getAllSuperAdmin } from 'actions/staff/certification/staffCertificationApplicationAction';

// PANEL
import CertificationDetailsPanel from 'pages/staff/certification/certifications/product/StaffCertificationApplicationProductCertDetails';
import ProductsPanel from 'pages/staff/certification/certifications/product/StaffCertificationApplicationProductProducts';
import AssessmentDetailsPanel from '../../assessment/components/ScoresheetViewPanel';
import BCAFunctionalSystemPanel from 'pages/staff/certification/certifications/product/StaffCertificatificationApplicationProductFunctionalSystem';
import GreenAttributesPanel from 'pages/staff/certification/certifications/product/StaffCertificationApplicationProductGreenAttributes';
import BuildingTypesPanel from 'pages/staff/certification/certifications/product/StaffCertificationApplicationProductBuildingTypes';
import MarkUserAgreementPanel from 'pages/staff/certification/MarkUserAgreementPanel';

// ACTION
import {
    staffCertApplCertDetailsGetInfo,
    staffCertApplCertDetailsGetRating,
    staffCertApplCertDetailsSaveCertDetails,
} from 'actions/staff/certification/certifications/staffCertificationApplicationProductCertDetailsAction';
import {
    staffCertApplProductGetProductInfo,
    staffCertUpdateProductInfo,
} from 'actions/staff/certification/certifications/staffCertificationApplicationProductProductsAction';
import {
    staffCertApplFunctionalSystemGetCheckboxOptions,
    staffCertApplFunctionalSystemGetInfo,
    staffCertApplFunctionalSystemSaveOptions,
} from 'actions/staff/certification/certifications/staffCertificatificationApplcationProductFunctionalSystemAction';
import {
    staffCertApplGreenAttributesGetInfo,
    staffCertApplGreenAttributesGetCheckboxOptions,
    staffCertApplGreenAttributesSaveOptions,
} from 'actions/staff/certification/certifications/staffCertificationApplicationProductGreenAttributesAction';
import {
    staffCertApplBuildingTypesGetInfo,
    staffCertApplBuildingTypesGetDropdownOptions,
    staffCertApplBuildingTypesSaveOptions,
} from 'actions/staff/certification/certifications/staffCertificationApplicationProductBuildingTypesAction';
import { getProductTypes } from 'actions/member/certification/applications/memberCertNewApplAction';
import { loadByApplicationId } from 'actions/staff/certification/markUserAgreementAction.js';
import { printCert, handleRegenerate, handleDelete, downloadCertificate } from 'actions/shared/certificationAction';
// Util
import { b64toBlob, sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';

class StaffCertificationApplicationProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.onTextChange = this.onTextChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onRatingChange = this.onRatingChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onProductTypeChange = this.onProductTypeChange.bind(this);
        this.onCertDetailsSave = this.onCertDetailsSave.bind(this);
        this.onBCAOptionChange = this.onBCAOptionChange.bind(this);
        this.onBCAFunctionalSystemSave = this.onBCAFunctionalSystemSave.bind(this);
        this.onGreenAttributesChange = this.onGreenAttributesChange.bind(this);
        this.onGreenAttributesSave = this.onGreenAttributesSave.bind(this);
        this.onBuildingTypesChange = this.onBuildingTypesChange.bind(this);
        this.onBuildingTypesSave = this.onBuildingTypesSave.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.printCert = this.printCert.bind(this);
        this.viewUserAgreement = this.viewUserAgreement.bind(this);
        this.viewUserAgreementModalToggle = this.viewUserAgreementModalToggle.bind(this);
        this.handleRegenerate = this.handleRegenerate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.saveEditting = this.saveEditting.bind(this);
        this.startEditting = this.startEditting.bind(this);
        this.productBrandChange = this.productBrandChange.bind(this);
        this.productModelChange = this.productModelChange.bind(this);
        this.haveMUA = this.haveMUA.bind(this);
        this.printMUA = this.printMUA.bind(this);
        this.setProductCatalogue = this.setProductCatalogue.bind(this);
        this.setProductPicture = this.setProductPicture.bind(this);

        this.state = {
            haveMua: false,
            loading: false,
            isAdmin: false,
            activeTab: 'certDetails',
            productInEditting: {},
            viewUserAgreementModalShow: false,
            certDetailsState: {
                mode: 'product',
                certDetails: {},
                productTypeOptions: [],
                ratingOptions: [],
                selectedRatingName: '',
            },
            productDetailsState: {
                productDetails: [],
            },
            functionalSystemState: {
                checkboxOptions: [],
                selectedCheckboxOptions: {},
            },
            greenAttributesState: {
                checkboxOptions: [],
                selectedCheckboxOptions: {},
            },
            buildingTypesState: {
                checkboxOptions: [],
                selectedCheckboxOptions: {},
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    viewUserAgreementModalToggle() {
        this.setState({
            viewUserAgreementModalShow: !this.state.viewUserAgreementModalShow,
        });
    }
    // LIFECYCLE
    componentDidMount() {
        this._isMounted = true;
        window.scrollTo(0, 0);
        this.getRelevantStuffs();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    viewUserAgreement() {
        this.viewUserAgreementModalToggle();
    }
    // GET DATA
    async getRelevantStuffs() {
        const certId = this.props.match.params.certId;
        switch (this.state.activeTab) {
            case 'certDetails':
                this.getRelevantStuffsForCertDetails(certId);
                break;
            case 'products':
                this.getRelevantStuffsForProducts(certId);
                break;
            case 'assessmentDetails':
                break;
            case 'functionSystem':
                this.getRelevantStuffsForFunctionalSystem(certId);
                break;
            case 'greenAttributes':
                this.getRelevantStuffsForGreenAttributes(certId);
                break;
            case 'buildingTypes':
                this.getRelevantStuffsForBuildingTypes(certId);
                break;
        }
    }
    async printMUA() {
        try {
            this.setState({
                loadPrinting: true,
            });
            const res = await this.props.printCertificationApplication(
                this.state.certDetailsState.certDetails.certificateApplicationId,
                'product',
                true,
            );
            window.open(SGBC_S3_HOST + '/temp/' + res.file);
        } catch (error) {
            this.showModal('Error', 'MUA cannot be printed', null, false, true);
            throw error;
        } finally {
            this.setState({
                loadPrinting: false,
            });
        }
    }
    async getRelevantStuffsForCertDetails(certId) {
        try {
            this.showLoading(true);

            const { getCertDetails, getProductTypes, getRating, getAllSuperAdmin } = this.props;
            const ratings = await getRating(1); // isProduct true
            const certDetails = await getCertDetails(certId);
            const productTypes = await getProductTypes(certDetails.productCategoryId);
            let managersRes = await getAllSuperAdmin();

            let staffs = managersRes.filter(item => item.id == this.props.profile.id);

            if (this._isMounted) {
                this.setState({
                    certDetailsState: {
                        ...this.state.certDetailsState,
                        ratingOptions: ratings.data,
                        productTypeOptions: productTypes.data,
                        certDetails: sanitizeStringAndObjectNullsToEmptyStrings(certDetails.data),
                    },
                    isAdmin: staffs.length > 0,
                });
            }
            this.haveMUA(this.state.certDetailsState.certDetails.certificateApplicationId);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async getRelevantStuffsForProducts(certId) {
        const { getProductInfo } = this.props;
        this.showLoading(true);
        try {
            const productDetails = await getProductInfo(certId);
            if (this._isMounted) {
                var productDetailsDt = productDetails.data;
                this.setState({
                    productDetailsState: {
                        productDetails: productDetailsDt,
                    },
                });
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    async getRelevantStuffsForFunctionalSystem(certId) {
        const { getCheckboxOptions, getFunctionalSystemInfo } = this.props;
        let { selectedCheckboxOptions } = this.state.functionalSystemState;
        this.showLoading(true);
        try {
            const checkboxOptions = await getCheckboxOptions();
            const functionalSystemDetails = await getFunctionalSystemInfo(certId);
            functionalSystemDetails.data &&
                functionalSystemDetails.data.forEach(selected => {
                    selectedCheckboxOptions[selected.bcaDataOptionId] = true;
                });
            if (this._isMounted) {
                this.setState({
                    functionalSystemState: {
                        selectedCheckboxOptions,
                        checkboxOptions: checkboxOptions.data,
                    },
                });
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async getRelevantStuffsForGreenAttributes(certId) {
        const { greenAttributesGetCheckboxOptions, greenAttributesGetInfo } = this.props;
        let { selectedCheckboxOptions } = this.state.greenAttributesState;
        this.showLoading(true);
        try {
            const checkboxOptions = await greenAttributesGetCheckboxOptions();
            const greenAttributesDetails = await greenAttributesGetInfo(certId);
            greenAttributesDetails.data &&
                greenAttributesDetails.data.forEach(selected => {
                    selectedCheckboxOptions[selected.otherDataOptionId] = true;
                });
            if (this._isMounted) {
                this.setState({
                    greenAttributesState: {
                        selectedCheckboxOptions,
                        checkboxOptions: checkboxOptions.data,
                    },
                });
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async getRelevantStuffsForBuildingTypes(certId) {
        const { buildingTypesGetInfo, buildingTypesGetOptions } = this.props;
        let { selectedCheckboxOptions } = this.state.buildingTypesState;
        this.showLoading(true);
        try {
            const checkboxOptions = await buildingTypesGetOptions();
            const buildingTypeDetails = await buildingTypesGetInfo(certId);
            buildingTypeDetails.data.forEach(selected => {
                selectedCheckboxOptions[selected.buildingTypeOptionId] = true;
            });
            if (this._isMounted) {
                this.setState({
                    buildingTypesState: {
                        selectedCheckboxOptions,
                        checkboxOptions: checkboxOptions.data,
                    },
                });
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    // Actions - Cert Details
    onTextChange(e, fieldName) {
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails[fieldName] = e.target.value;
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    setProductCatalogue(fileId, fileName) {
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails.catalogueResourceId = fileId;
        certDetails.catalogueFileName = fileName;
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    setProductPicture(fileId, fileName) {
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails.pictureResourceId = fileId;
        certDetails.pictureFileName = fileName;
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    onCheckboxChange(e, fieldName) {
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails[fieldName] = new Number(e.target.checked).valueOf();
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    onDateChange(date, fieldName) {
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails[fieldName] = date.format('YYYY-MM-DD');
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    onRatingChange(selectedValue, fieldName) {
        const { value } = selectedValue;
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails[fieldName] = value;
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    onProductTypeChange(value, prodTypeName, productTypeId, prodCatIdName, prodSubCatName, productSubCategoryId) {
        const { type, categoryId, subcategory, id, subcategoryId } = value;
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails[prodTypeName] = type;
        certDetails[productTypeId] = id;
        certDetails[prodCatIdName] = categoryId;
        certDetails[prodSubCatName] = subcategory;
        certDetails[productSubCategoryId] = subcategoryId;
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    onCertDetailsSave(values) {
        this.showLoading(true);
        const { saveCertDetails } = this.props;
        const certificationDetails = {
            certificateId: values.id,
            ratingId: values.ratingId,
            certificateNumber: values.certificateNumber,
            productCategoryId: values.productCategoryId,
            productSubCategoryId: values.productSubCategoryId,
            productTypeId: values.productTypeId,
            validFrom: values.validFrom,
            validTo: values.validTo,
            brand: values.brand,
            lowVoc: values.lowVoc,
            withAppendix: values.withAppendix,
            certificateRemarks: values.certificateRemarks,
            catalogueFileName: values.catalogueFileName,
            catalogueResourceId: values.catalogueResourceId,
            pictureFileName: values.pictureFileName,
            pictureResourceId: values.pictureResourceId,
        };
        saveCertDetails(certificationDetails).then(res => {
            this.showLoading(false);
            this.showModal(res.error ? 'error' : 'confirmation', res.message, '', false, true);
        });
    }
    async haveMUA(id) {
        const { data } = await this.props.loadByApplicationId(id);
        if (data.length === 0) this.setState({ haveMua: false });
        else this.setState({ haveMua: true });
    }

    async printCert() {
        try {
            this.showLoading(true);
            const res = await this.props.downloadCertificate(this.state.certDetailsState.certDetails.id);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async handleRegenerate() {
        try {
            this.showLoading(true);
            const { data, error } = await this.props.handleRegenerate(this.state.certDetailsState.certDetails.id, 'product');
            this.showModalIf('Confirmation', 'Certificate successfully regenerated.', data);
            this.showModalIf('Error', 'Certificate cannot be regenerated', !data || error);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async confirmDelete() {
        this.showModal(
            'Are you sure you want to delete this certificate?',
            'This is a non reversible action. Deletion of certificate will also delete the service from services directory.',
            'Alert',
            false,
            false,
            () => {
                this.handleDelete();
            },
        );
    }
    async handleDelete() {
        try {
            this.showLoading(true);
            const { data, error } = await this.props.handleDelete(this.state.certDetailsState.certDetails.id, 'product');
            this.showModalIf('Confirmation', 'Certificate successfully deleted.', data, () => {
                history.push(`/staff/certification/certificates/product`);
            });
            this.showModalIf('Error', 'Certificate cannot be deleted', !data || error);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    // Action - Products
    productBrandChange(e) {
        let st = this.state.productInEditting;
        st.productBrand = e.target.value;
        this.setState({
            productInEditting: st,
        });
    }
    productModelChange(e) {
        let st = this.state.productInEditting;
        st.productModel = e.target.value;
        this.setState({
            productInEditting: st,
        });
    }
    startEditting(product) {
        this.setState({
            productInEditting: Object.assign({}, product),
        });
    }
    async saveEditting(product) {
        let res = await this.props.staffCertUpdateProductInfo(this.state.productInEditting.id, this.state.productInEditting);
        this.setState({
            productInEditting: {},
        });
        this.showModal(
            res.error ? 'error' : 'confirmation',
            res.error ? 'Product cannot be updated' : 'Product has been successfully updated',
            '',
            false,
            true,
        );
        this.getRelevantStuffsForProducts(this.props.match.params.certId);
    }
    // Action - BCA Functional System
    onBCAOptionChange(e, fieldId) {
        const { checked } = e.target;
        const state = this.state.functionalSystemState.selectedCheckboxOptions;
        let selectedCheckboxOptions = Object.assign({}, state);
        selectedCheckboxOptions[fieldId] = checked;
        this.setState({
            functionalSystemState: {
                ...this.state.functionalSystemState,
                selectedCheckboxOptions,
            },
        });
    }
    onBCAFunctionalSystemSave() {
        this.showLoading(true);
        const { saveFunctionSystem } = this.props;
        const selectedOptions = this.state.functionalSystemState.selectedCheckboxOptions;
        // Process data from obj to array
        let newOptions = [];
        Object.keys(selectedOptions).forEach(option => {
            if (selectedOptions[option]) {
                newOptions.push(option);
            }
        });
        // On Save
        saveFunctionSystem(this.props.match.params.certId, newOptions).then(res => {
            this.showLoading(false);
            this.showModal(res.error ? 'error' : 'confirmation', res.message, '', false, true);
        });
    }
    // Action - Green Attributes
    onGreenAttributesChange(e, fieldId) {
        const { checked } = e.target;
        const state = this.state.greenAttributesState.selectedCheckboxOptions;
        let selectedCheckboxOptions = Object.assign({}, state);
        selectedCheckboxOptions[fieldId] = checked;
        this.setState({
            greenAttributesState: {
                ...this.state.greenAttributesState,
                selectedCheckboxOptions,
            },
        });
    }
    onGreenAttributesSave() {
        this.showLoading(true);
        const { greeAttributesSaveCheckboxOptions } = this.props;
        const selectedOptions = this.state.greenAttributesState.selectedCheckboxOptions;
        // Process data from obj to array
        let newOptions = [];
        Object.keys(selectedOptions).forEach(option => {
            if (selectedOptions[option]) {
                newOptions.push(option);
            }
        });
        // On Save
        greeAttributesSaveCheckboxOptions(this.props.match.params.certId, newOptions).then(res => {
            this.showLoading(false);
            this.showModal(res.error ? 'error' : 'confirmation', res.message, '', false, true);
        });
    }
    // Action - Buildimg Types
    onBuildingTypesChange(e, optionId) {
        const { checked } = e.target;
        const state = this.state.buildingTypesState.selectedCheckboxOptions;
        let selectedCheckboxOptions = Object.assign({}, state);
        selectedCheckboxOptions[optionId] = checked;
        this.setState({
            buildingTypesState: {
                ...this.state.buildingTypesState,
                selectedCheckboxOptions,
            },
        });
    }
    onBuildingTypesSave() {
        this.showLoading(true);
        const { buildingTypesSaveOptions } = this.props;
        const { selectedCheckboxOptions } = this.state.buildingTypesState;
        // Process data from obj to array
        let newOptions = [];
        Object.keys(selectedCheckboxOptions).forEach(option => {
            if (selectedCheckboxOptions[option]) {
                newOptions.push(option);
            }
        });
        buildingTypesSaveOptions(this.props.match.params.certId, newOptions).then(res => {
            this.showLoading(false);
            this.showModal(res.error ? 'error' : 'confirmation', res.message, '', false, true);
        });
    }
    // TABS NAVIGATION
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                },
                () => this.componentDidMount(),
            );
        }
    }
    renderNavItem(navItems) {
        return navItems.map(navItem => {
            const { key, text, icon } = navItem;
            return (
                <NavItem key={key}>
                    <NavLink
                        className={this.state.activeTab === key ? 'active' : null}
                        onClick={() => {
                            this.toggleTab(key);
                        }}
                    >
                        <i className={'fas ' + icon} />
                        {' ' + text}
                    </NavLink>
                </NavItem>
            );
        });
    }
    // MODAL
    modalAction() {
        this.modalToggle();
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showModalIf(header, message, evaluation, postHandler) {
        if (evaluation) this.showModal(header, message, 'Alert', null, true, postHandler);
        return evaluation;
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide, postHandler) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.postHandler = postHandler;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    render() {
        const navs = [
            {
                key: 'certDetails',
                text: 'Certification Details',
                icon: 'fa-pencil-alt',
            },
            {
                key: 'products',
                text: 'Product(s)',
                icon: 'fa-map-pin',
            },
            {
                key: 'assessmentDetails',
                text: 'Assessment Details',
                icon: 'fa-edit',
            },
            {
                key: 'functionSystem',
                text: 'BCA Functional System',
                icon: 'fa-check-square',
            },
            {
                key: 'greenAttributes',
                text: 'Green Attributes',
                icon: 'fa-list',
            },
            {
                key: 'buildingTypes',
                text: 'Green Credentials',
                icon: 'fa-building',
            },
        ];
        const columns = [
            {
                Header: 'Brand',
                accessor: 'productBrand',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Model',
                accessor: 'productModel',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: '',
                accessor: 'id',
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Button
                            size="sm"
                            className="primary-btn-style"
                            onClick={e => {
                                this.startEditting(props.original);
                            }}
                        >
                            <i className="mt-1 material-icons align-text-bottom ">edit</i>
                        </Button>
                    );
                },
            },
        ];
        const {
            modal,
            loading,
            activeTab,
            certDetailsState,
            productDetailsState,
            functionalSystemState,
            greenAttributesState,
            buildingTypesState,
            productInEditting,
        } = this.state;

        // console.log("certDetailsState.certDetails.assessmentScoreSheetId: ", certDetailsState.certDetails.assessmentScoreSheetId, ", certDetailsState.certDetails.assessmentTemplateId: ", certDetailsState.certDetails.assessmentTemplateId)
        return (
            <div className="page-widget">
                <DialogModal
                    modalAction={modal.postHandler || this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    positiveButtonHide={modal.positiveButtonHide}
                    negativeButtonHide={modal.negativeButtonHide}
                    modalBody={modal.modalMessage}
                    modalHeader={modal.modalHeader}
                    modalOpen={modal.showModal}
                />
                <React.Fragment>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <Nav tabs>{this.renderNavItem(navs)}</Nav>
                        </Col>
                    </Row>
                    {loading && <LoadingSpinner center />}
                    {!loading && (
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="certDetails">
                                <CertificationDetailsPanel
                                    state={certDetailsState}
                                    onDateChange={this.onDateChange}
                                    onTextChange={this.onTextChange}
                                    onRatingChange={this.onRatingChange}
                                    onCheckboxChange={this.onCheckboxChange}
                                    onProductTypeChange={this.onProductTypeChange}
                                    onCertDetailsSave={this.onCertDetailsSave}
                                    printCert={this.printCert}
                                    handleRegenerate={this.handleRegenerate}
                                    onCertWithPrefixChange={this.onCertWithPrefixChange}
                                    viewUserAgreement={this.printMUA}
                                    haveMua={this.state.haveMua}
                                    setProductCatalogue={this.setProductCatalogue}
                                    setProductPicture={this.setProductPicture}
                                    isAdmin={this.state.isAdmin}
                                    handleDelete={this.confirmDelete}
                                />
                            </TabPane>
                            <TabPane tabId="products">
                                <ProductsPanel
                                    state={productDetailsState}
                                    productInEditting={productInEditting}
                                    startEditting={this.startEditting}
                                    saveEditting={this.saveEditting}
                                    productBrandChange={this.productBrandChange}
                                    productModelChange={this.productModelChange}
                                    tableColumns={columns}
                                />
                            </TabPane>
                            <TabPane tabId="assessmentDetails">
                                {certDetailsState.certDetails.assessmentScoreSheetId != null &&
                                certDetailsState.certDetails.assessmentScoreSheetId != '' &&
                                certDetailsState.certDetails.assessmentTemplateId != null &&
                                certDetailsState.certDetails.assessmentTemplateId != '' &&
                                certDetailsState.certDetails.certificateApplicationId != null &&
                                certDetailsState.certDetails.certificateApplicationId != '' ? (
                                    <AssessmentDetailsPanel
                                        staff
                                        type={'product'}
                                        templateId={certDetailsState.certDetails.assessmentTemplateId || ''}
                                        scoresheetId={certDetailsState.certDetails.assessmentScoreSheetId || ''}
                                        applicationId={certDetailsState.certDetails.certificateApplicationId || ''}
                                        closed
                                    />
                                ) : (
                                    `No assessment is available`
                                )}
                            </TabPane>
                            <TabPane tabId="functionSystem">
                                <BCAFunctionalSystemPanel
                                    state={functionalSystemState}
                                    onBCAOptionChange={this.onBCAOptionChange}
                                    onBCAFunctionalSystemSave={this.onBCAFunctionalSystemSave}
                                />
                            </TabPane>
                            <TabPane tabId="greenAttributes">
                                <GreenAttributesPanel
                                    state={greenAttributesState}
                                    onGreenAttributesChange={this.onGreenAttributesChange}
                                    onGreenAttributesSave={this.onGreenAttributesSave}
                                />
                            </TabPane>
                            <TabPane tabId="buildingTypes">
                                <BuildingTypesPanel
                                    state={buildingTypesState}
                                    onBuildingTypesChange={this.onBuildingTypesChange}
                                    onBuildingTypesSave={this.onBuildingTypesSave}
                                />
                            </TabPane>
                        </TabContent>
                    )}
                </React.Fragment>
                <ViewUserAgreementModal
                    viewUserAgreementModalShow={this.state.viewUserAgreementModalShow}
                    viewUserAgreementModalToggle={this.viewUserAgreementModalToggle}
                    applicationId={certDetailsState.certDetails.certificateApplicationId}
                />
            </div>
        );
    }
}

const ViewUserAgreementModal = props => (
    <Modal isOpen={props.viewUserAgreementModalShow} toggle={props.viewUserAgreementModalToggle}>
        <ModalBody>
            <MarkUserAgreementPanel
                states={{}}
                handlers={{}}
                applicationId={props.applicationId}
                readonly
                applicationSource={`Certification`}
            />
        </ModalBody>
        <ModalFooter>
            <Button className="text-bold primary-btn-style" onClick={props.viewUserAgreementModalToggle}>
                Close
            </Button>{' '}
        </ModalFooter>
    </Modal>
);

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.memberProductApplication,
        ...state.profile,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // Cert Detail
        getCertDetails: certId => {
            return dispatch(staffCertApplCertDetailsGetInfo(certId));
        },
        getRating: isProduct => {
            return dispatch(staffCertApplCertDetailsGetRating(isProduct));
        },
        getProductTypes: id => {
            return dispatch(getProductTypes(id));
        },
        saveCertDetails: data => {
            return dispatch(staffCertApplCertDetailsSaveCertDetails(data, 'product'));
        },
        // Products
        getProductInfo: id => {
            return dispatch(staffCertApplProductGetProductInfo(id));
        },
        // Functional System
        getFunctionalSystemInfo: certId => {
            return dispatch(staffCertApplFunctionalSystemGetInfo(certId));
        },
        getCheckboxOptions: () => {
            return dispatch(staffCertApplFunctionalSystemGetCheckboxOptions());
        },
        saveFunctionSystem: (certId, options) => {
            return dispatch(staffCertApplFunctionalSystemSaveOptions(certId, options));
        },
        // Gree Attributes
        greenAttributesGetInfo: certId => {
            return dispatch(staffCertApplGreenAttributesGetInfo(certId));
        },
        greenAttributesGetCheckboxOptions: () => {
            return dispatch(staffCertApplGreenAttributesGetCheckboxOptions());
        },
        greeAttributesSaveCheckboxOptions: (certId, options) => {
            return dispatch(staffCertApplGreenAttributesSaveOptions(certId, options));
        },
        // Building Types
        buildingTypesGetInfo: certId => {
            return dispatch(staffCertApplBuildingTypesGetInfo(certId));
        },
        buildingTypesGetOptions: () => {
            return dispatch(staffCertApplBuildingTypesGetDropdownOptions());
        },
        buildingTypesSaveOptions: (certId, options) => {
            return dispatch(staffCertApplBuildingTypesSaveOptions(certId, options));
        },
        printCert: (certificateId, type) => {
            return dispatch(printCert(certificateId, type));
        },
        handleRegenerate: (certificateId, type) => {
            return dispatch(handleRegenerate(certificateId, type));
        },
        handleDelete: (certificateId, type) => {
            return dispatch(handleDelete(certificateId, type));
        },
        downloadCertificate: certificateId => {
            return dispatch(downloadCertificate(certificateId));
        },
        staffCertUpdateProductInfo: (productId, productInfo) => {
            return dispatch(staffCertUpdateProductInfo(productId, productInfo));
        },
        loadByApplicationId: id => {
            return dispatch(loadByApplicationId(id));
        },
        printCertificationApplication: (id, type, muaLocked) => {
            return dispatch(printCertificationApplication(id, type, muaLocked));
        },
        getAllSuperAdmin: () => {
            return dispatch(getAllSuperAdmin());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(StoredLayout(StaffCertificationApplicationProductDetails)));

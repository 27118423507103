import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { getAuditList } from 'actions/misc/auditAction';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';

class AuditListPanel extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    load() {
        this.props.load(this.props.contentTypeId, this.props.objectId).then(res => {
            this.setState({
                data: res.data,
            });
        });
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const columns = [
            {
                Header: 'Datetime',
                accessor: 'actionTime',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['actionTime'] }),
                filterAll: true,
                maxWidth: 135,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return moment(props.value, 'YYYY-MM-DD HH:mm:SS').format('DD/MM/YYYY HH:mm');
                },
            },
            {
                Header: 'Type',
                accessor: 'actionFlag',
                maxWidth: 75,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['actionFlag'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return props.value == 1 ? 'Add' : props.value == 2 ? 'Update' : 'Delete';
                },
            },
            {
                Header: 'Message',
                accessor: 'changeMessage',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['changeMessage'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Performed by',
                accessor: 'email',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['email'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
            },
        ];

        return this.state.data == null ? (
            <LoadingSpinner />
        ) : (
            <React.Fragment>
                <div className="table-responsive">
                    <ReactTable
                        columns={columns}
                        filterable={true}
                        defaultPageSize={10}
                        minRows={0}
                        className="-striped -highlight"
                        data={this.state.data}
                    />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: (contentTypeId, objectId) => {
            return dispatch(getAuditList(contentTypeId, objectId));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AuditListPanel));

import withQuery from 'with-query';

import {
    MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_REQUEST,
    MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_SUCCESS,
    MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_FAILURE,
    MEMBER_CERTIFICATION_OVERVIEW_SET_FILTERED_BY,
} from '../../constants/actionTypesMemberCertification';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../config';
import fetchlib from '../../../js/fetchlib';

export function memberCertOverviewGetInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, { action: 'certificateListByType' });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_REQUEST,
            MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_SUCCESS,
            MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_FAILURE,
        );
    };
}
export function memberCertOverviewSetFilteredBy(status) {
    return {
        type: MEMBER_CERTIFICATION_OVERVIEW_SET_FILTERED_BY,
        status,
    };
}

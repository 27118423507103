import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import ButtonIcon from 'cyder/button/ButtonIcon';
import history from '../../history';
import CertificationIssuanceAnalyticsToolbar from 'pages/analytics/components/certification-issuance/CertificationIssuanceAnalyticsToolbar';
import CertificationIssuedChart from 'pages/analytics/components/certification-issuance/CertificationIssuedChart';
import CertificationIssuedList from 'pages/analytics/components/certification-issuance/CertificationIssuedList';
import { FormGroup, Input, Row, Col, Form, Button, Container } from 'reactstrap';

var buttonStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
};

const AnalyticsButton = ({ btnProps, colStyle, onClick, label, check }) => {
    let buttonColor = null;
    let fontColor = null;
    if (check) {
        buttonColor = 'grey';
        fontColor = 'white';
    }
    // console.log("buttonColor: ", buttonColor, ", check: ", check);
    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col style={colStyle}>
                <ButtonIcon label={label} onClick={onClick} {...btnProps} buttonColor={buttonColor} fontColor={fontColor} />
            </Col>
        </Row>
    );
};

class CertificatesIssuedAnalytics extends React.Component {
    constructor(props) {
        super(props);
        // this.handleInputChange = this.handleInputChange.bind(this);
    }

    render() {
        return (
            <Container>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={12}>
                        <Row className="float-right">
                            <AnalyticsButton
                                colStyle={buttonStyle}
                                onClick={e => {
                                    history.push('/staff/analytics/certification');
                                }}
                                label="Certification Analytics"
                            />
                            <AnalyticsButton
                                colStyle={buttonStyle}
                                onClick={e => {
                                    history.push('/staff/analytics/certification/renewalanalytics');
                                }}
                                label="Renewal Analytics"
                            />
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={12}>
                        <CertificationIssuanceAnalyticsToolbar />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={12}>
                        <CertificationIssuedChart />
                    </Col>
                    <Col xs={12}>
                        <CertificationIssuedList />
                    </Col>
                </Row>
            </Container>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertificatesIssuedAnalytics));

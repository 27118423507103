/**
 * SGBCLeftMenuItem
 *
 * Author: Fernando
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { translate } from 'react-i18next';
import history from '../../history';

import { setConfig } from 'actions/config';

class SGBCLeftMenuItem extends React.Component {
    state = {
        isOpen: false,
    };

    componentDidMount() {
        if (this.props.path === this.props.items.title.toLowerCase()) {
            this.setState({ isOpen: true });
        } else {
            this.setState({ isOpen: false });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.path !== this.props.path) {
            if (
                this.props.path === this.props.items.title.toLowerCase() ||
                (this.props.items.title === 'Events' && this.props.path === 'galadinner')
            ) {
                this.setState({ isOpen: true });
            } else {
                this.setState({ isOpen: false });
            }
        }
    }

    toggle = () => {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen,
        });
    };

    render() {
        const {
            t,
            items,
            config,
            openStaffMode,
            openAdminMode,
            handleActiveIndex,
            path,
            pathSub,
            index,
            states: { activeIndex, title },
        } = this.props;
        const { isOpen } = this.state;
        const pathMatched = path === items.title.toLowerCase();

        if (items.items.length === 0 && items.show) {
            return (
                <li>
                    {items.navToAdmin && <MenuItem onClick={e => openAdminMode(this.props.profile)} item={items} t={t} />}
                    {items.navToStaff && <MenuItem onClick={openStaffMode} item={items} t={t} />}
                    {!items.navToAdmin && !items.navToStaff && (
                        <SubMenuItem
                            isHeader={true}
                            item={items}
                            active={pathMatched}
                            onClick={() => handleActiveIndex(index, items.title)}
                            iconStyle={{ color: '#000' }}
                            className="btn btn-default btn-flat btn-sidebar btn-sidebar-1"
                            titleClassName="title"
                            t={t}
                        />
                    )}
                </li>
            );
        }
        if (items.show) {
            return (
                <ExpandableMenuItem
                    t={t}
                    item={items}
                    onClick={this.toggle}
                    className={
                        isOpen
                            ? 'btn btn-default btn-flat btn-sidebar btn-sidebar-1 has-children is-open'
                            : 'btn btn-default btn-flat btn-sidebar btn-sidebar-1 has-children'
                    }
                >
                    {({ items }) => {
                        // If submenu found in initial items then render with below component
                        return (
                            <ul className="list-unstyled">
                                {items &&
                                    items.map((item, k) => {
                                        const itemTitle =
                                            item.title === 'All Events'
                                                ? item.title.split(' ')[0]
                                                : item.title.includes(' ')
                                                ? item.title.split(' ').join('')
                                                : item.title;

                                        if (item.show) {
                                            return (
                                                <MenuLevelThree
                                                    key={k}
                                                    hasChildren={item.items.length > 0}
                                                    config={config}
                                                    item={item}
                                                    active={
                                                        pathSub === itemTitle.toLowerCase() ||
                                                        (pathSub === 'galadinner' && itemTitle === 'Gala Dinner')
                                                    }
                                                    onClick={() => handleActiveIndex(k, item.title)}
                                                    title={title}
                                                    t={t}
                                                />
                                            );
                                        }
                                        return <li key={k} />;
                                    })}
                            </ul>
                        );
                    }}
                </ExpandableMenuItem>
            );
        }
        return <li />;
    }
}
const ExpandableMenuItem = ({ style, className, onClick, children, item, t }) => {
    const { icon, badge, title, items } = item;
    return (
        <li>
            <a className={className} style={{ fontSize: 'unset', ...style }} onClick={onClick}>
                <i className="material-icons" style={{ color: '#000' }}>
                    {icon}
                </i>
                <span className="title">
                    <b>{t('sidemenu:' + title)}</b>
                </span>
                {badge && <span className={`ml-auto ${badge.className}`}>{badge.title}</span>}
            </a>
            {children && items && children({ items })}
        </li>
    );
};
class MenuLevelThree extends React.Component {
    state = {
        isOpen: false,
    };
    toggle = () => {
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen,
        });
    };

    render() {
        const { isOpen } = this.state;
        const { hasChildren, item, t, closeSideMenu, config, active, onClick, title } = this.props;
        const { items } = item;
        let className = isOpen ? 'btn btn-default btn-flat btn-sidebar is-open' : 'btn btn-default btn-flat btn-sidebar';
        if (hasChildren) className += ' has-children';
        if (config.layout.includes('default')) className += ' btn-sidebar-1';
        return (
            <li onClick={onClick} className={active ? 'active' : ``}>
                <SubMenuItem
                    isHeader={false}
                    item={item}
                    disabled={hasChildren}
                    className={className}
                    onClick={hasChildren ? this.toggle : null}
                    t={t}
                    active={active}
                />
                {isOpen && (
                    <ul className="list-unstyled">
                        {items.map((item, k) => {
                            // Third level menu component
                            if (item.show) {
                                return (
                                    <li key={k} onClick={onClick}>
                                        <SubMenuItem
                                            isHeader={false}
                                            item={item}
                                            title={title}
                                            active={active}
                                            className={`btn btn-default btn-flat btn-sidebar btn-sidebar-2`}
                                            onClick={onClick}
                                            t={t}
                                        />
                                    </li>
                                );
                            }
                            return <li key={k} />;
                        })}
                    </ul>
                )}
            </li>
        );
    }
}
// Admin Menu Item
const MenuItem = ({ onClick, className, style, item, t }) => {
    const { icon, title } = item;
    return (
        <div
            onClick={onClick}
            style={{ fontSize: 'unset', ...style }}
            className={'btn btn-default btn-flat btn-sidebar btn-sidebar-1 ' + className}
        >
            <i className="material-icons">{icon}</i>
            <span className="title">
                <b>{t('sidemenu:' + title)}</b>
            </span>
        </div>
    );
};
const SubMenuItem = ({ isHeader, disabled, className, style, iconStyle, onClick, item, titleClassName, t, active }) => {
    const { icon, title, url, badge } = item;
    return (
        <Link
            to={url || '#'}
            className={active ? `${className} active sub` : `${className}`}
            // className={active ? `${className} active sub` : `${className} no-active`}
            disabled={disabled}
            onClick={onClick}
            style={{ fontSize: 'unset', ...style }}
        >
            <i className="material-icons" style={iconStyle}>
                {icon}
            </i>
            {isHeader === true ? (
                <span className={titleClassName}>
                    <b>{t('sidemenu:' + title)}</b>
                </span>
            ) : (
                <span className={titleClassName}>{t('sidemenu:' + title)}</span>
            )}
            {badge && <span className={`ml-auto ${badge.className}`}>{badge.title}</span>}
        </Link>
    );
};
const mapStateToProps = (state, ownProps) => {
    return {
        config: state.config,
        profile: state.profile,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        closeSideMenu: () => {
            dispatch(setConfig('collapsed', false));
        },
        openAdminMode: profile => {
            dispatch({ type: 'CYDER_PROFILE_SET_ADMIN_MODE' });
            if (profile.profile.is_membership_admin == 1) {
                history.push('/admin/companies/organisation-main-category');
            } else if (profile.profile.is_event_admin == 1) {
                history.push('/admin/events/event-type');
            } else if (profile.profile.is_mailing_list_admin == 1) {
                history.push('/admin/mailinglist');
            } else if (profile.profile.is_cert_admin == 1) {
                history.push('/admin/product/product-category');
            } else if (profile.profile.is_cert_manager == 1) {
                history.push('/admin/product/product-category');
            } else if (profile.profile.is_superuser == 1) {
                history.push('/admin/auth/users');
            } else if (profile.profile.is_staff == 1) {
                history.push('/admin/survey/survey-category');
            } else {
                history.push('/admin/auth/users');
            }
        },
        openStaffMode: () => {
            dispatch({ type: 'CYDER_PROFILE_REMOVE_ADMIN_MODE' });
            history.push('/staff/home');
        },
    };
};
export default translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(SGBCLeftMenuItem),
);

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../history';

import { FormGroup, Label, Input, Alert, Col, Row, Container, Button } from 'reactstrap';
import CarouselPanel from './components/CarouselPanel';
import Select, { Async } from 'react-select';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import TopNavigation2 from '../../layouts/TopNavigation2';
import AffiliatesPanel from './components/AffiliatesPanel';
import './Login.css';
import { cyderLoginAction, cyderLoginResetState, cyderResetProfileAction } from '../../actions/security/cyderSecurityAction';
import { myCorpTFASocket } from '../../actions/tfa';

const centreAlignStyle = (breakpoint, defaultWidth) => {
    return {
        display: 'inline-block',
        justifyContent: 'center',
        width: breakpoint ? defaultWidth : '100%',
        height: '100%',
    };
};

const loginPanelStyle1 = {
    textAlign: 'center',
    float: 'left',
    display: 'inline',
    width: '100%',
    textRendering: 'optimizeLegibility',
    WebkitFontSmoothing: 'antialiased',
};
const loginPanelStyle2 = {
    height: '45px',
    border: '1px solid #ababab',
    borderRadius: '5px',
    marginBottom: '20px',
    padding: '10px 12px',
    width: '100%',
    backgroundColor: 'rgb(248, 248, 248)',
};
export const loginPanelStyle3 = {
    height: '45px',
    border: '1px solid #ababab',
    borderRadius: '5px',
    display: 'inline',
    padding: '10px 12px',
    transition: 'all 0.4s ease 0s',
    width: '100%',
    textRendering: 'optimizeLegibility',
    WebkitFontSmoothing: 'antialiased',
    WebkitAppearance: 'textfield',
    backgroundColor: 'white',
    WebkitRtlOrdering: 'logical',
    cursor: 'text',
    letterSpacing: 'normal',
    wordSpacing: 'normal',
    textTransform: 'none',
    textIndent: '0px',
    textShadow: 'none',
};
const loginPanelStyle4 = {
    WebkitAppearance: 'button',
    cursor: 'pointer',
    backgroundColor: '#fff',
    padding: '10px 15px',
    borderRadius: '4px',
    textTransform: 'uppercase',
    transition: 'all 0.4s ease 0s',
    border: '1px solid #78adc9',
    color: '#78adc9',
    letterSpacing: 'normal',
    wordSpacing: 'normal',
    textIndent: '0px',
    textShadow: 'none',
    display: 'inline-block',
};
export const loginPanelStyle5 = {
    //float: 'left',
    display: 'inline',
    width: '100%',
    fontSize: '14px',
    lineHeight: '1.42857143',
    color: '#333',
};
export const asyncSelectStyle = {
    control: styles => ({
        ...styles,
        backgroundColor: 'white',
        borderColor: '#ababab',
    }),
    valueContainer: styles => ({
        ...styles,
        padding: '10px',
        paddingTop: '7px',
        paddingBottom: '7px',
    }),
};
export const carouselItems = [
    {
        src: '/assets/images/login.png',
        header: 'Login',
    },
];

class PublicLogin extends React.Component {
    constructor(props) {
        super(props);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.showLoadingSubmitLogin = this.showLoadingSubmitLogin.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
        this.register = this.register.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.showTwoFactorLoading = this.showTwoFactorLoading.bind(this);
        this.cancelTfaRequest = this.cancelTfaRequest.bind(this);
        this.state = {
            twoFactorLoading: false,
            companies: [],
            selectedCompany: {},
            selectedCompanyId: 0,
            select: {
                disabled: false,
                searchable: true,
                clearable: true,
            },
            email: '',
            password: '',
            loadingCompany: false,
            loadingSubmitLogin: false,
            error: null,
            windowSize: 0,
            tfaId: null,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.setWindowSize);
        this.setWindowSize();
        this.props.resetLoginPageState();
        this._isMounted = true;
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.setWindowSize);
        this._isMounted = false;
    }
    setWindowSize() {
        this.setState({
            windowSize: window.innerWidth,
        });
    }
    showLoadingSubmitLogin(show) {
        this.setState({
            loadingSubmitLogin: show,
        });
    }
    onPasswordChange(e) {
        this.setState({
            password: e.target.value,
        });
    }
    onEmailChange(e) {
        this.setState({
            email: e.target.value,
        });
    }
    showAlert(message) {
        this.setState({
            error: message,
        });
    }
    cancelTfaRequest() {
        this.setState({ email: '', password: '' });
        myCorpTFASocket.cancelTfaRequest(this.state.tfaId);
    }
    showTwoFactorLoading(twoFactorLoading) {
        this.setState({
            twoFactorLoading,
        });
    }
    twoFactorAuth(res) {
        const { data, needchangepassword, firstlogin } = res;
        const { email } = data;
        myCorpTFASocket.init(email.toLowerCase(), 'sgbc', 'sgbccrm', 'TFA', () => {
            this.showTwoFactorLoading(true);
            myCorpTFASocket.sendTfaRequest(tfaResponse => {
                console.log('Get response from TFA: ', tfaResponse);
                this.setState({ tfaId: tfaResponse.id });
                this.showTwoFactorLoading(false);
                if (tfaResponse.approved === 'TRUE') {
                    if (needchangepassword === 'Y' || firstlogin === 'Y') {
                        history.push('/changepassword');
                    } else {
                        this.props.setLayout('default-sidebar-1');
                        history.push('/staff/home');
                    }
                }
            });
        });
    }
    submitLogin() {
        this.showAlert(null);
        this.showLoadingSubmitLogin(true);
        const data = {
            username: this.state.email,
            password: this.state.password,
            organization: 0,
        };
        this.props
            .submitLogin(data)
            .then(res => {
                this.showLoadingSubmitLogin(false);
                let errorMessage = res.errormessage ? res.errormessage : res.data ? res.data.errormessage : null;
                if (errorMessage) {
                    this.showAlert('Invalid username or password');
                } else {
                    if (res.data.tfa_enabled === 1) this.twoFactorAuth(res);
                    else {
                        this.props.setLayout('default-sidebar-1');
                        history.push('/staff/home');
                    }
                    //if (res.needchangepassword === 'Y' || res.firstlogin === 'Y') {
                    //    var navigate = '/changepassword';
                    //    history.push(navigate);
                    //} else {
                    //    if (res.data.is_staff) {
                    //        this.props.setLayout('default-sidebar-1');
                    //    } else {
                    //        this.props.setLayout('top-navigation-2');
                    //    }
                    //    var navigate = res.data.is_staff === 1 ? '/staff/home' : '/member/home';
                    //    history.push(navigate);
                    //}
                }
            })
            .catch(error => {
                this.showLoadingSubmitLogin(false);
                this.showAlert('Invalid username or password');
                throw error;
            });
    }
    register() {
        history.push('/public/createaccount');
    }
    render() {
        const breakpoint = this.state.windowSize > 1100;
        return (
            <React.Fragment>
                <div style={{ top: '5vw' }} className={'hero-section d-none d-md-block d-flex flex-column'}>
                    <div className="description text-center" style={{ minHeight: '400px' }}>
                        <div
                            style={{
                                ...centreAlignStyle(breakpoint, '35%'),
                            }}
                        >
                            <LoginPanel
                                cancelTfaRequest={this.cancelTfaRequest}
                                showTwoFactorLoading={this.showTwoFactorLoading}
                                twoFactorLoading={this.state.twoFactorLoading}
                                email={this.state.email}
                                onEmailChange={this.onEmailChange}
                                password={this.state.password}
                                onPasswordChange={this.onPasswordChange}
                                submitLogin={this.submitLogin}
                                loadingSubmitLogin={this.state.loadingSubmitLogin}
                                error={this.state.error}
                            />
                        </div>
                    </div>
                </div>
                <AffiliatesPanel />
            </React.Fragment>
        );
    }
}

export const InputComponentWrapper = ({ label, children, asterisk }) => {
    return (
        <FormGroup style={{ textAlign: 'left', marginTop: '20px' }}>
            <Label>
                <strong>{label}</strong> {asterisk && <span className="asteriskField">*</span>}
            </Label>
            {children}
        </FormGroup>
    );
};

const LoginPanel = ({
    cancelTfaRequest,
    showTwoFactorLoading,
    twoFactorLoading,
    email,
    onEmailChange,
    password,
    onPasswordChange,
    submitLogin,
    loadingSubmitLogin,
    error,
}) => {
    return (
        <div
            className="col-lg-5 col-md-5 col-sm-5 mt-4 pt-4 login-panel staff"
            style={{ ...loginPanelStyle5, margin: '0 auto', top: '3vw' }}
        >
            <Row className="text-left mb-2">
                <Col>
                    <div className="welcome">
                        <span>Log</span> In
                    </div>
                </Col>
            </Row>
            {!twoFactorLoading ? (
                <div>
                    {error && <Alert color="danger">{error}</Alert>}
                    <InputComponentWrapper label="Email">
                        <Input
                            // style={loginPanelStyle3}
                            className="input-transparent"
                            type="text"
                            name="email"
                            id="email"
                            value={email}
                            onKeyDown={e => {
                                if (e.keyCode == 13) {
                                    submitLogin();
                                }
                            }}
                            placeholder=""
                            onChange={onEmailChange}
                        />
                    </InputComponentWrapper>
                    <InputComponentWrapper label="Password">
                        <Input
                            style={{ marginBottom: 10 }}
                            className="input-transparent"
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            onKeyDown={e => {
                                if (e.keyCode == 13) {
                                    submitLogin();
                                }
                            }}
                            placeholder=""
                            onChange={onPasswordChange}
                        />
                        <Link className="p-1" className="forgot" to="/public/forgotpassword">
                            Forgot your password?
                        </Link>
                    </InputComponentWrapper>
                    <br />
                    {loadingSubmitLogin ? (
                        <LoadingSpinner />
                    ) : (
                        <Button block size="lg" value="Sign In" onClick={submitLogin} className="primary-green">
                            Login
                        </Button>
                    )}
                    <br />
                    <br />
                </div>
            ) : (
                <div>
                    <div className="twofactor">
                        Please verify your login on <span>MyCorp InstaSecure</span>.
                    </div>
                    <br />
                    <Button
                        onClick={() => {
                            showTwoFactorLoading(false);
                            cancelTfaRequest();
                        }}
                        className="primary-dark"
                    >
                        Cancel
                    </Button>
                    <br />
                    <br />
                </div>
            )}
        </div>
    );
};
const SignUpPanel = ({ register }) => {
    return (
        <div
            className="col-lg-4 col-md-4 col-sm-4"
            style={{
                marginLeft: '20px',
            }}
        >
            <FormGroup
                style={{
                    textAlign: 'left',
                }}
            >
                <FormGroup style={loginPanelStyle5}>
                    <Row>
                        <Col xs={12} sm={12}>
                            <h2>Sign Up</h2>
                        </Col>
                    </Row>
                    <br />
                    <div>
                        <Label for="register">Don't have an account?</Label>
                    </div>
                    <Button onClick={register} className="primary-btn-style">
                        Create an account
                    </Button>
                </FormGroup>
            </FormGroup>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {
        submitLogin: data => {
            return dispatch(cyderLoginAction(data));
        },
        setLayout: layout => {
            dispatch({
                type: 'SET_CONFIG',
                config: {
                    layout: layout,
                },
            });
        },
        resetLoginPageState: () => {
            dispatch(cyderLoginResetState());
            dispatch(cyderResetProfileAction());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation2(PublicLogin));

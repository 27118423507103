import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import moment from 'moment';

import { FormGroup, Input, Row, Col, Button } from 'reactstrap';
import LegendIcon from 'cyder/media/LegendIcon';
import EditButton from 'cyder/media/EditButton';
import CyderReactTable from 'cyder/table/CyderReactTable';
import ButtonIcon from 'cyder/button/ButtonIcon';
import {
    staffMembershipApplicationsGetList,
    staffMembershipApplicationsGetStatusCount,
    staffMembershipApplicationsSetFilterStatus,
} from 'actions/staff/membership/applications/staffMembershipApplicationsAction';
import cyderlib from 'js/cyderlib';

import { SGBC_DATA_CUTOVER_DATE } from 'config';

class StaffMembershipApplicationsPage extends React.Component {
    constructor(props) {
        super(props);
        this.setFilterBy = this.setFilterBy.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
            keyword: '',
        };
    }
    setFilterBy(id) {
        let selected = 'All';
        switch (id) {
            case '1':
                selected = 'Draft';
                break;
            case '2':
                selected = 'Submitted';
                break;
            case '3':
                selected = 'Processing';
                break;
            case '4':
                selected = 'Approved & Pending Payment';
                break;
            case '5':
                selected = 'Complete';
                break;
            case '6':
                selected = 'Not Approved';
                break;
            case '8':
                selected = 'Withdrawn';
                break;
            case '9':
                selected = 'Payment Received (Cert Preparation)';
                break;
        }
        this.props.setFilterStatus(id, selected).then(() => {
            const { filterBy } = this.props;
            this.props.getList(filterBy, this.state.keyword, 0);
            this.props.getApplicationCount(filterBy);
        });
    }
    handleInputChange(event) {
        const value = event.target.value;
        this.setState({ keyword: value });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.membershipStatusSubmitted === 1) {
                this.props.setFilterStatus(2, 'Submitted');
                this.props.getApplicationCount('2');
                return;
            }
            return;
        }
        this.props.getApplicationCount('');
    }
    render() {
        const columns = [
            {
                Header: 'Company',
                accessor: 'company',
                minWidth: 150,
            },
            {
                Header: 'Type',
                accessor: 'applicationType',
                minWidth: 75,
            },
            {
                Header: 'Submitted On',
                accessor: 'submittedOn',
                Cell: props => moment(props.value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'),
                maxWidth: 100,
            },
            {
                Header: 'Package',
                accessor: 'package',
                minWidth: 200,
            },
            {
                Header: 'Status',
                accessor: 'status',
                minWidth: 100,
                Cell: props => {
                    return (
                        props.value || (
                            <div className={'badge badge-rounded badge-' + cyderlib.getApplicationStatusAttr(props.value, 'color')}>
                                <i className={'material-icons align-text-bottom text-white'}>
                                    {cyderlib.getApplicationStatusAttr(props.value, 'icon')}
                                </i>
                            </div>
                        )
                    );
                },
            },
            {
                Header: 'Officer',
                accessor: 'officer',
                maxWidth: 110,
            },
            {
                Header: '',
                sortable: false,
                accessor: '',
                maxWidth: 100,
                Cell: props => {
                    return (
                        <Link to={`./applications/${props.original.applicationId}`}>
                            {/* <EditButton text="Open" /> */}
                            <ButtonIcon icon="folder_open" label="Open" />
                        </Link>
                    );
                },
            },
        ];
        return (
            <div className="page-widget">
                <Row className="mb-2">
                    <Col xs={12}>
                        <div className="form-inline">
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    id="search-keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) this.props.getList(this.props.filterBy, this.state.keyword);
                                    }}
                                    placeholder="Keyword"
                                    onChange={this.handleInputChange}
                                />
                            </FormGroup>
                            <Button
                                className="primary-btn-style"
                                onClick={() => this.props.getList(this.props.filterBy, this.state.keyword)}
                            >
                                <strong>Search</strong>
                            </Button>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row className="mb-2">
                    <Col xs={12}>
                        <LegendIcon
                            clicked={this.props.selected}
                            status="all"
                            color="#ed8a8a"
                            module="membership"
                            text="All"
                            onClick={() => this.setFilterBy('All')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.props.selected}
                            status="draft"
                            module="membership"
                            text="Draft"
                            onClick={() => this.setFilterBy('1')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.props.selected}
                            status="submitted"
                            module="membership"
                            text="Submitted"
                            onClick={() => this.setFilterBy('2')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.props.selected}
                            status="processing"
                            module="membership"
                            text="Processing"
                            onClick={() => this.setFilterBy('3')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.props.selected}
                            status="approved & pending payment"
                            module="membership"
                            text="Approved & Pending Payment"
                            onClick={() => this.setFilterBy('4')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.props.selected}
                            status="payment received (cert preparation)"
                            module="membership"
                            text="Payment Received (Cert Preparation)"
                            onClick={() => this.setFilterBy('9')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.props.selected}
                            status="complete"
                            module="membership"
                            text="Complete"
                            onClick={() => this.setFilterBy('5')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.props.selected}
                            status="notApproved"
                            module="membership"
                            text="Not Approved"
                            onClick={() => this.setFilterBy('6')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.props.selected}
                            status="withdrawn"
                            module="membership"
                            text="Withdrawn"
                            onClick={() => this.setFilterBy('8')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            getTdProps={() => ({
                                style: { whiteSpace: 'unset' },
                            })}
                            className="-highlight mb-2"
                            data={this.props.applications}
                            columns={columns}
                            minRows={0}
                            defaultPageSize={20}
                            filterable={false}
                            manual
                            totalNum={this.props.totalCount}
                            pages={this.props.tablePages}
                            loading={this.props.loading}
                            ref={instance => {
                                if (instance != null) this.table = instance.table;
                            }}
                            onFetchData={(state, instance) => {
                                this.props.getList(
                                    this.props.location.state && this.props.location.state.membershipStatusSubmitted === 1
                                        ? '2'
                                        : this.props.filterBy,
                                    state.keyword,
                                    state.page,
                                    state.pageSize,
                                    state.sorted,
                                    state.filtered,
                                );
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.staffMembershipApplicationsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getApplicationCount(statusId) {
            dispatch(staffMembershipApplicationsGetStatusCount(statusId));
        },
        getList: (filterBy, keyword, page, pageSize, sorted, filtered) => {
            dispatch(staffMembershipApplicationsGetList(page, pageSize, sorted, filtered, keyword, filterBy));
        },
        setFilterStatus: (filterBtnId, filterBtnName) => {
            return dispatch(staffMembershipApplicationsSetFilterStatus(filterBtnId, filterBtnName));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(StaffMembershipApplicationsPage));

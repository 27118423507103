import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getOrganisationPrimaryBusinessAnalytic, membershipIssuedChartAnalytics } from 'actions/analytics/memberAnalyticsAction';
import { Chart, Bar } from 'react-chartjs-2';

class MembershipIssuedChart extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    componentDidMount() {
        this.load();

        Chart.pluginService.register({
            afterDraw: () => {
                if (this.refs != null && this.refs.chart != null && this.refs.chart.chartInstance != null) {
                    let ctx = this.refs.chart.chartInstance.chart.ctx;
                    ctx.font = '10px Verdana';
                    ctx.fillStyle = 'gray';
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    let total = this.refs.chart.chartInstance.chart.config.data.total;
                    let total2 = this.refs.chart.chartInstance.chart.config.data.total2;
                    let idx = 0;
                    this.refs.chart.chartInstance.chart.config.data.datasets.forEach(function(dataset) {
                        idx++;
                        if (dataset.showBarValue) {
                            const dataArray = dataset.data;
                            let metaKey = 0;
                            for (var k in dataset._meta) {
                                metaKey = k;
                            }
                            var meta = dataset._meta[metaKey];
                            meta != null &&
                                meta.data.forEach(function(bar, index) {
                                    let percentage = Math.round((dataArray[index] / (idx == 1 ? total : total2)) * 100);
                                    let minTreshold = 20;
                                    let maxTreshold = 90;
                                    let yPos = percentage < minTreshold ? -20 : percentage > maxTreshold ? 20 : 20;
                                    let xPos = 0;
                                    ctx.fillText(dataArray[index] + '(' + percentage + '%)', bar._view.x + xPos, bar._view.y + yPos);
                                });
                        }
                    });
                }
            },
        });
    }
    componentDidUpdate() {
        if (this.state.refreshState != this.props.membership.refresh) {
            this.load();
            this.setState({
                refreshState: this.props.membership.refresh,
            });
        }
    }
    load() {
        this.props.load().then(res => {
            let labels = [];

            let dataSetLabel = '';
            let dataSet2Label = '';

            let data = [];
            let data2 = [];

            let total = 0;
            let total2 = 0;

            // Populate labels, differently for yearly and monthly chart type
            // and also remove duplication of the label values
            if (this.props.membership.interval == 'yearly') {
                res.data.forEach(element => {
                    if (labels.filter(value => value == element.issuedYear).length <= 0) {
                        labels.push(element.issuedYear);
                    }
                });
            } else {
                res.data.forEach(element => {
                    if (labels.filter(value => value == element.issuedMonth + ' ' + element.issuedYear).length <= 0) {
                        labels.push(element.issuedMonth + ' ' + element.issuedYear);
                    }
                });
            }

            if (this.props.membership.chart == 'all') {
                res.data.forEach(element => {
                    data.push(element.count);
                    total += element.count;
                });
                dataSetLabel = `All (${total})`;
            } else {
                res.data.forEach(element => {
                    if (element.applicationType == 'New') {
                        data.push(element.count);
                        total += element.count;
                    } else {
                        data2.push(element.count);
                        total2 += element.count;
                    }
                });
                dataSetLabel = `New (${total})`;
                dataSet2Label = `Renewed (${total2})`;
            }

            let chartData = {
                labels,
                total,
                datasets: [
                    {
                        label: dataSetLabel,
                        data,
                        backgroundColor: 'rgba(28,169,223, 0.5)',
                        borderColor: 'rgba(28,169,223, 0.8)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(28,169,223, 0.8)',
                        hoverBorderColor: 'rgba(28,169,223, 0.8)',
                        showBarValue: true,
                    },
                ],
            };

            if (this.props.membership.chart != 'all') {
                chartData.total2 = total2;
                chartData.datasets.push({
                    label: dataSet2Label,
                    data: data2,
                    backgroundColor: 'rgba(27,213,110, 0.5)',
                    borderColor: 'rgba(27,213,110, 0.8)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(27,213,110, 0.8)',
                    hoverBorderColor: 'rgba(27,213,110, 0.8)',
                    showBarValue: true,
                });
            }
            this.setState({
                data: chartData,
            });
        });
    }

    render() {
        const chartOptions = {
            scaleOverride: true,
            // scaleSteps: 2,
            // scaleStepWidth: Math.ceil(max / steps),
            scaleStartValue: 0,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            callback: function(label, index, labels) {
                                return Math.round(label);
                            },
                            scaleIntegersOnly: true,
                            beginAtZero: true,
                        },
                    },
                ],
            },
        };
        return (
            <AnalyticsCard
                title={`${
                    this.state.data != null
                        ? `Memberships Issued within ${moment(this.props.membership.startDate, 'DD/MM/YYYY').format(
                              'DD MMMM YYYY',
                          )} - ${moment(this.props.membership.endDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}`
                        : `...`
                }`}
            >
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <Bar ref="chart" data={this.state.data} options={chartOptions} />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        membership: state.membershipIssuanceAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load2: () => {
            return dispatch(getOrganisationPrimaryBusinessAnalytic());
        },
        load: () => {
            return dispatch(membershipIssuedChartAnalytics());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MembershipIssuedChart));

/**
 * Cyder Rich Text Editor (powered by DraftJS)
 * v1.0
 *
 * Author: Fernando Karnagi
 */
import React from 'react';
import { Editor, EditorState, RichUtils, getDefaultKeyBinding, ContentState, Draft } from 'draft-js';
import ReactDOM from 'react-dom';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import RichTextEditor from 'react-rte';

class CyderRichText extends React.Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);

        this.state = {
            value: RichTextEditor.createEmptyValue(),
        };
    }
    componentDidMount() {
        var initialData = this.props.data;
        if (initialData != null) {
            let contentState = RichTextEditor.createValueFromString(initialData, 'html');
            this.setState({
                value: contentState,
            });
        }
    }
    onChange(value) {
        this.setState({ value });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChange(value.toString('html'));
        }
    }
    render() {
        return (
            <RichTextEditor
                editorStyle={{ fontSize: '14px', fontFamily: 'Roboto, san-serif' }}
                readOnly={this.props.disabled ? true : false}
                value={this.state.value}
                onChange={this.onChange}
            />
        );
    }
}
export default CyderRichText;

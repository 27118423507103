import withQuery from 'with-query';

import {
    STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_REQUEST,
    STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_SUCCESS,
    STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_FAILURE,
} from '../../constants/actionTypesStaffMembership';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../config';
import fetchlib from '../../../js/fetchlib';

export function staffMembershipOverviewGetInfo(type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getmembershipoverviewforstaff',
        });
        const body = {
            type,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_REQUEST,
            STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_SUCCESS,
            STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_FAILURE,
        );
    };
}
export function setProductApplicationStatus(status) {
    return {
        type: 'MEMBER_PRODUCT_SET_APPLICATION_STATUS',
        status,
    };
}

/**
 * Member Product renew action
 *
 * Author: Fernando Karnagi
 */
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import {
    MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_REQUEST,
    MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_SUCCESS,
    MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_FAILURE,
} from '../../../constants/actionTypesMemberCertification';
export function getCertificateInfoById(certId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'find-by-id',
            certificateId: certId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function setBrandEditable(editable) {
    return {
        type: 'MEMBERSHIP_PRODUCT_CERTIFICATION_RENEW_SET_BRAND_EDITABLE',
        editable,
    };
}
export function saveRenewCertApplication(type, body, forSubmit) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: `${forSubmit === true ? 'submit' : 'renew'}-${type}-cert-application`,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_REQUEST,
            MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_SUCCESS,
            MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_FAILURE,
        );
    };
}
export function setUpdateAssessment(update) {
    return {
        type: 'MEMBERSHIP_PRODUCT_CERTIFICATION_RENEW_SET_ASSESSMENT_UPDATE',
        update,
    };
}

import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { membershipDiscontinueReasonDetailAnalytics } from 'actions/analytics/memberAnalyticsAction';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

class DiscontinueReasonDetailsList extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }
    load() {
        this.props.load(this.props.reasonId).then(res => {
            this.setState({
                data: res.data,
            });
        });
    }
    componentDidMount() {
        this.load();
    }
    render() {
        const columns = [
            {
                Header: 'Company',
                accessor: 'companyName',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['companyName'] }),
                filterAll: true,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return <Link to={'/staff/company/' + props.original.companyId}>{props.value}</Link>;
                },
            },
            {
                Header: 'Primary Business',
                accessor: 'primaryBusiness',
                headerStyle: { whiteSpace: 'unset' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['primaryBusiness'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Organisation Profile',
                accessor: 'organisationProfile',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['organisationProfile'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Years of Membership',
                width: 100,
                accessor: 'yearOfMembership',
                headerStyle: { whiteSpace: 'unset' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['yearOfMembership'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
            },
        ];

        return this.state.data == null ? (
            <LoadingSpinner />
        ) : (
            <React.Fragment>
                <div className="table-responsive">
                    <ReactTable
                        columns={columns}
                        filterable={true}
                        minRows={0}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        data={this.state.data}
                    />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: reasonId => {
            return dispatch(membershipDiscontinueReasonDetailAnalytics(reasonId));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(DiscontinueReasonDetailsList));

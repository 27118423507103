import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

// import '../../css/cyder/panel/standard-panel.css'
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane, Badge } from 'reactstrap';

class CompanyDetailsPageOverviewPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'products',
        };
    }
    changeNav(newNav) {
        this.setState({ activeTab: newNav });
    }
    render() {
        const dataServices = [
            {
                certNo: 'SGBS 2016-069',
                category: 'Environment Sustainability Design',
                validTill: '31 October 2018',
            },
            {
                certNo: 'SGBS 2016-068',
                category: 'Mechanical & Electrical',
                validTill: '31 October 2018',
            },
        ];
        const dataProducts = [
            {
                certNo: 'SGBS 2016-072',
                category: 'Green Product Certification 4 ticks',
                validTill: '31 October 2018',
            },
            {
                certNo: 'SGBS 2016-073',
                category: 'Very Green Product Certification 4 ticks',
                validTill: '31 October 2018',
            },
            {
                certNo: 'SGBS 2016-073',
                category: 'Extremely Green Product Certification 5 ticks',
                validTill: '31 October 2018',
            },
        ];
        const columns = [
            {
                Header: 'Certificate No.',
                accessor: 'certNo',
            },
            {
                Header: 'Category',
                accessor: 'category',
            },
            {
                Header: 'Valid Till',
                accessor: 'validTill',
            },
        ];
        return (
            <Container>
                <Row>
                    <Col md={12}>
                        <Card className="mb-2">
                            <CardTitle className="p-3 d-flex">
                                <span>
                                    <i className="fa fa-info-circle" /> Corporate Information
                                </span>
                                <span className="ml-auto">
                                    <Badge className="badge-sm" color="info">
                                        SME
                                    </Badge>
                                </span>
                            </CardTitle>
                            <CardBody>
                                <Row>
                                    <Col xs={12} sm={12} md={12}>
                                        <h3>ABB Pte. Ltd.</h3>
                                        <hr />
                                        <h6>
                                            <i className="fa fa-globe" />
                                            &nbsp;&nbsp;Singapore
                                        </h6>
                                        <h6>
                                            <i className="fa fa-key" />
                                            &nbsp;&nbsp;197000334W
                                        </h6>
                                        <h6>
                                            <i className="fa fa-chrome" />
                                            &nbsp;&nbsp;
                                            <Link to="http://http://new.abb.com/sg">http://new.abb.com/sg</Link>
                                        </h6>
                                        <h6>
                                            <i className="fa fa-phone" />
                                            &nbsp;&nbsp;6776 5711
                                        </h6>
                                        <h6>
                                            <i className="fa fa-envelope-o" />
                                            &nbsp;&nbsp;bryan.loh@sg.abb.com
                                        </h6>
                                        <h6>
                                            <i className="fa fa-map-marker" />
                                            &nbsp;&nbsp;2 Ayer Rajah Crescent
                                            <br />
                                            Singapore
                                            <br /> 139935
                                        </h6>
                                        <hr />
                                        <strong>Organisation Primary Business Activity</strong>
                                        <br />
                                        Product Companies
                                        <br />
                                        <br />
                                        <strong>Services offered by organisation</strong>
                                        <br />
                                        Manufacturer / Distributor / Reseller
                                        <br />
                                        <br />
                                        <i className="fa fa-quote-left fa-2x faded-less" />
                                        &nbsp;&nbsp;An emphasis on green building products.
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="mb-2">
                            <CardTitle className="p-3">
                                <i className="fa fa-leaf" /> Membership Information
                            </CardTitle>
                            <CardBody>
                                <h4>Corporate Member</h4>
                                <Badge color="success" className="mr-1">
                                    Since 2017
                                </Badge>
                            </CardBody>
                        </Card>
                        <Card className="mb-2">
                            <CardTitle className="p-3">
                                <i className="fa fa-certificate" /> Certifications
                            </CardTitle>
                            <CardBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.activeTab === 'products' ? 'active' : null}
                                            onClick={() => {
                                                this.changeNav('products');
                                            }}
                                        >
                                            Products
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.activeTab === 'services' ? 'active' : null}
                                            onClick={() => {
                                                this.changeNav('services');
                                            }}
                                        >
                                            Services
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <CardBody>
                                    <ReactTable
                                        className="-highlight mb-2"
                                        data={this.state.activeTab === 'services' ? dataServices : dataProducts}
                                        columns={columns}
                                        sortable={false}
                                        minRows={0}
                                        showPagination={false}
                                        defaultPageSize={20}
                                        filterable={false}
                                    />
                                </CardBody>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default CompanyDetailsPageOverviewPanel;

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';

import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import CyderReactTable from 'cyder/table/CyderReactTable';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Tooltip } from 'reactstrap';

import { BrandDetailComponent } from './StaffCertificationApplicationList';
import { fetchCertificationRenewals, fetchRenewalStatus } from 'actions/staff/certification/renewalActions';

const status = [
    { key: 'all', value: 'all', text: 'All' },
    { key: 1, value: 1, text: 'Yet to Contact' },
    { key: 2, value: 2, text: 'Unsure if want to renew' },
    { key: 3, value: 3, text: 'Renewal-in-Progress' },
    { key: 4, value: 4, text: 'Terminated (Fees High)' },
    { key: 5, value: 5, text: 'Terminated (Not beneficial)' },
    { key: 6, value: 6, text: 'Terminated (Product Obsolete)' },
    { key: 9, value: 9, text: 'Terminated (Other Reasons)' },
    { key: 10, value: 10, text: 'Renewed' },
    { key: 11, value: 11, text: 'Intend to Renew' },
];

const expiring = [
    { key: 'all', value: 'all', text: 'All' },
    { key: 'sixMonthsExpiring', value: 'sixMonthsExpiring', text: 'Expiring (6 months)' },
    { key: 'oneYearExpired', value: 'oneYearExpired', text: 'Expired within past 1 year' },
];

class RenewalPage extends React.Component {
    state = {
        itemsPerPage: 20,
        page: 1,
        expiringStatus: 'all',
        renewalStatusId: 'all',
        type: 'product',
        keyword: '',
        tooltip: false,
    };
    componentDidMount() {
        const { tooltip, ...rest } = this.state;
        this.props.fetchCertificationRenewals(rest);
        this.props.fetchRenewalStatus();
    }

    handleTooltip = () => this.setState(prevState => ({ tooltip: !prevState.tooltip }));
    handleChange = e => {
        const name = e.target.name;
        this.setState({ [name]: e.target.value });
    };
    handleSubmit = e => {
        // e.preventDefault();
        const data = { ...this.state };
        this.props.fetchCertificationRenewals(data);
    };
    render() {
        const { statusOptions, renewals, pages, length, loading } = this.props;
        const columns = [
            {
                Header: 'Type',
                accessor: 'type',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => (props.value == 'product' ? 'Product' : 'Services'),
            },
            {
                Header: 'Company',
                accessor: 'companyName',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 150,
            },
            {
                Header: 'Description',
                accessor: 'category',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 200,
                Cell: props => {
                    var products = [];
                    try {
                        products = JSON.parse(props.original.brands);
                    } catch (e) {
                        products = [];
                    }
                    if (products == null) {
                        products = [];
                    }
                    if (props.original.type == 'service') {
                        return <b>{props.value}</b>;
                    } else {
                        return (
                            <div>
                                <b>{props.value}</b>
                                {products.constructor === Array && products.length > 0 ? (
                                    <React.Fragment>
                                        <BrandDetailComponent items={products} />
                                    </React.Fragment>
                                ) : (
                                    <p>{props.original.brand}</p>
                                )}
                            </div>
                        );
                    }
                },
            },
            {
                Header: 'Certificate Number',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                accessor: 'certNumber',
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: 'Issued On',
                accessor: 'issuedOn',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return moment(props.value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
                },
            },
            {
                Header: 'Expired On',
                accessor: 'expiryDate',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return moment(props.value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
                },
            },
            {
                Header: '',
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                width: 100,
                Cell: props => {
                    const url = `/staff/certification/certificates/${props.original.type}/certdetails/${props.original.certificateId}`;
                    return (
                        <Link to={url}>
                            <Button className="ml-2 align-middle primary-btn-style primary-btn-xs">
                                <i className="fas fa-folder-open mr-1" />
                                Open
                            </Button>
                        </Link>
                    );
                },
            },
            {
                Header: '',
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },

                width: 100,
                Cell: ({ original }) => {
                    const url = `/staff/certification/renewal/${original.certificateId}`;
                    return (
                        <Link to={url}>
                            <Button className="ml-2 align-middle primary-btn-style primary-btn-xs">
                                <i className="mr-1 material-icons align-text-bottom">edit</i>
                                Edit
                            </Button>
                        </Link>
                    );
                },
            },
        ];

        return (
            <div className={`page-widget renewal ${loading ? 'loading' : ''}`}>
                <Row className="main-header">
                    <Col>
                        <NoShadowPanel title={'Renewal'} borderSize={2} flat />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="form-inline" style={{ flexFlow: 'row nowrap' }}>
                            <FormGroup onChange={this.handleChange}>
                                <legend>Choose type</legend>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="type" value="product" defaultChecked /> Product
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="type" value="service" /> Services
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="type" value="all" /> All
                                    </Label>
                                </FormGroup>
                            </FormGroup>
                            <FormGroup>
                                <legend>Status</legend>
                                <Input onChange={this.handleChange} type="select" name="renewalStatusId" placeholder="Status of renewal">
                                    <option key="all" value="all">
                                        All
                                    </option>
                                    {statusOptions &&
                                        statusOptions.map(item => (
                                            <option key={item.key} value={item.value}>
                                                {item.text}
                                            </option>
                                        ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <legend>Expiring</legend>
                                <Input type="select" name="expiringStatus" onChange={this.handleChange} placeholder="Expiring certs...">
                                    {expiring.map(item => (
                                        <option key={item.key} value={item.value}>
                                            {item.text}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup style={{ marginRight: 25 }}>
                                <legend>Find Certificate</legend>
                                <Input
                                    type="text"
                                    name="keyword"
                                    onChange={this.handleChange}
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.handleSubmit();
                                        }
                                    }}
                                />
                            </FormGroup>
                        </div>
                        <br />
                        <Button type="button" className="primary-btn-style" onClick={this.handleSubmit}>
                            <i className="material-icons align-text-bottom">search</i>
                            &nbsp;Search
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CyderReactTable
                            data={renewals}
                            className="-highlight mb-2"
                            columns={columns}
                            filterable={false}
                            minRows={0}
                            manual
                            showPagination={true}
                            pages={pages}
                            totalNum={length}
                            defaultPageSize={this.state.itemsPerPage}
                            ref={instance => {
                                if (instance !== null) this.table = instance.table;
                            }}
                            onFetchData={state => {
                                this.props.fetchCertificationRenewals({
                                    ...this.state,
                                    page: state.page + 1,
                                    sorted: state.sorted,
                                    pageSize: state.pageSize,
                                });
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapState = ({ staffCertificationApplicationReducer: certReducer }) => {
    const statusOptions = certReducer.status ? certReducer.status.map(item => ({ key: item.id, value: item.id, text: item.status })) : [];

    return {
        renewals: certReducer.renewals,
        pages: certReducer.pages,
        length: certReducer.length,
        loading: certReducer.loading,
        statusOptions,
    };
};

// prettier-ignore
export default connect(mapState, { fetchCertificationRenewals, fetchRenewalStatus })(StoredLayout(RenewalPage))

/**
 * Product certification renewal panel
 *
 * Author: Fernando Karnagi
 */
import React from 'react';
import moment from 'moment';

import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import ProductListTable from './ProductsListTable';
import { AssessmentPanel } from './AssessmentPanel';

const InputField = ({ header, label }) => (
    <Row>
        <Col xs="6">
            <div className="form-group">
                <label className="field-header">{header + ':'}</label>
            </div>
        </Col>
        <Col xs="6">
            <div className="form-group">
                <div className="field-label">{label}</div>
            </div>
        </Col>
    </Row>
);

export const ProductCertificationRenewPanel = ({
    certificate,
    productBrands,
    assessmentBeingUpdated,
    submitRenewal,
    viewAssessment,
    shouldSubmitAssessmentDisabled,
    // ProductListTable - ACTIONS
    editBrand,
    saveBrand,
    addBrand,
    addModel,
    removeBrand,
    removeModel,
    toggleAddBrandForm,
    toggleAddModelForm,
    addBrandFormVisible,
    addModelFormVisible,
    onGenericTextFieldChange,
    // ProductListTable - FIELDS
    currentBrandToAddModel,
    brandNameField,
    modelNameField,
    modelDescriptionField,
    brandEditable,
}) => {
    const mapDate = date => moment(date, 'YYYY-MM-DD').format('DD MMM YYYY');
    return (
        <React.Fragment>
            <Card className="p-2">
                <CardBody>
                    <Row>
                        <Col xs="6">
                            <InputField header="Certificate Number" label={certificate && certificate.certificateNumber} />
                            <InputField header="Valid From" label={certificate && mapDate(certificate.validFrom)} />
                            <InputField header="Valid Until" label={certificate && mapDate(certificate.validTo)} />
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    {!brandEditable && !assessmentBeingUpdated && (
                                        <ButtonIcon icon="send" width="220px" onClick={submitRenewal} label="Start Renewal" />
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <br />
            <Card>
                <CardBody>
                    <NoShadowPanel title={'Product Information'}>
                        <br />
                        <Row>
                            <Col xs="3">
                                <div className="form-group">
                                    <label className="field-header">Product Category</label>
                                </div>
                            </Col>
                            <Col xs="6">
                                <div className="form-group">
                                    <div className="field-label">{certificate && certificate.category}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="3">
                                <div className="form-group">
                                    <label className="field-header">Product Type</label>
                                </div>
                            </Col>
                            <Col xs="6">
                                <div className="form-group">
                                    <div className="field-label">{certificate && certificate.productType}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="3">
                                <div className="form-group">
                                    <label className="field-header">Product Sub Category</label>
                                </div>
                            </Col>
                            <Col xs="6">
                                <div className="form-group">
                                    <div className="field-label">{certificate && certificate.productTypeSubCat}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ProductListTable
                                    editable={brandEditable}
                                    addBrand={addBrand}
                                    addModel={addModel}
                                    removeBrand={removeBrand}
                                    removeModel={removeModel}
                                    productBrands={productBrands}
                                    toggleAddBrandForm={toggleAddBrandForm}
                                    toggleAddModelForm={toggleAddModelForm}
                                    addBrandFormVisible={addBrandFormVisible}
                                    addModelFormVisible={addModelFormVisible}
                                    onGenericTextFieldChange={onGenericTextFieldChange}
                                    currentBrandToAddModel={currentBrandToAddModel}
                                    brandNameField={brandNameField}
                                    modelNameField={modelNameField}
                                    modelDescriptionField={modelDescriptionField}
                                />
                            </Col>
                        </Row>

                        <br />
                        {productBrands.length === 0 && <div>No product information were found</div>}
                    </NoShadowPanel>
                </CardBody>
            </Card>
            {/* {!shouldSubmitAssessmentDisabled && (
                <Row className="text-right">
                <Col>
                    <ButtonIcon
                        icon="list"
                        disabled={shouldSubmitAssessmentDisabled} // temp disable because templateId and assessmentId is null, undefined or empty string
                        onClick={viewAssessment}
                        label="Start Assessment"
                        className="primary-btn-style mt-3"
                    />
                </Col>
            </Row>
            )} */}
        </React.Fragment>
    );
};

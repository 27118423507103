/**
 * Product tagging panel
 *
 * Author: Fernando
 */
import React from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import ButtonIcon from 'cyder/button/ButtonIcon';
import { Row, Col, Button } from 'reactstrap';
import Select from 'react-select';
import MultiSelect from '@kenshooui/react-multi-select';

import { getBCAOptions, getOtherOptions, updateTagging } from 'actions/staff/certification/staffCertificationApplicationAction';

class ProductTaggingPanel extends React.Component {
    constructor(props) {
        super(props);

        this.updateTagging = this.updateTagging.bind(this);
        this.bcaDataOptionChange = this.bcaDataOptionChange.bind(this);
        this.otherDataOptionChange = this.otherDataOptionChange.bind(this);

        this.state = {
            step: 0,
            loading: false,
            disabled: false,
            searchable: true,
            bcaDataOptions: [],
            selectedBcaDataOptions: [],
            otherDataOptions: [],
            selectedOtherDataOptions: [],
        };
    }
    async componentDidMount() {
        var bcaDataOptions = await this.props.getBCAOptions();
        var otherDataOptions = await this.props.getOtherOptions();

        this.setState({
            bcaDataOptions: bcaDataOptions.data.map(option => {
                return {
                    id: option.id,
                    label: option.bcaDataOption,
                };
            }),
            otherDataOptions: otherDataOptions.data.map(option => {
                return {
                    id: option.id,
                    label: option.otherDataOption,
                };
            }),
        });
        var applicationTagging = this.props.applicationTagging;
        if (applicationTagging != null) {
            try {
                var applicationTaggingJSON = JSON.parse(applicationTagging);
                this.setState({
                    selectedBcaDataOptions: applicationTaggingJSON.bca,
                    selectedOtherDataOptions: applicationTaggingJSON.other,
                });
            } catch (e) {}
        }
    }
    bcaDataOptionChange(selectedItems) {
        this.setState({ selectedBcaDataOptions: selectedItems });
    }
    otherDataOptionChange(selectedItems) {
        this.setState({ selectedOtherDataOptions: selectedItems });
    }
    async updateTagging() {
        var r = window.confirm('Are you sure you want update this certificate tagging?');
        if (r == true) {
            var tagging = {
                bca: this.state.selectedBcaDataOptions,
                other: this.state.selectedOtherDataOptions,
            };

            let res = await this.props.updateTagging(this.props.applicationId, tagging);
            if (res != null && res.data != null && res.data.affectedRows > 0) {
                window.alert('Tagging has been successfully updated');
            } else {
                window.alert('Tagging cannot be updated');
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <h5 className="text-bold">BCA Tagging</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <MultiSelect
                            items={this.state.bcaDataOptions}
                            selectedItems={this.state.selectedBcaDataOptions}
                            onChange={this.bcaDataOptionChange}
                        />
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col>
                        <h5 className="text-bold">Green Attributes</h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <MultiSelect
                            items={this.state.otherDataOptions}
                            selectedItems={this.state.selectedOtherDataOptions}
                            onChange={this.otherDataOptionChange}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Button
                            color="success"
                            onClick={() => {
                                this.updateTagging();
                            }}
                        >
                            <i className="mr-1 material-icons align-text-bottom ">save</i>
                            Update Tagging
                        </Button>
                    </Col>
                </Row>
                <br />
                <hr />
                <br />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getBCAOptions: () => {
            return dispatch(getBCAOptions());
        },
        getOtherOptions: () => {
            return dispatch(getOtherOptions());
        },
        updateTagging: (id, tagging) => {
            return dispatch(updateTagging(id, tagging));
        },
    };
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ProductTaggingPanel),
);

/**
 * Product Application view page
 *
 * Author: Fernando Karnagi
 */

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import history from '../../../../../history';
import TopNavigationLayout from 'layouts/TopNavigationLayout';

import { Col, Button, Container, Card, CardBody } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import ProductsListTable from '../product/components/ProductsListTable';
import SurveysDetailPage from 'pages/surveys/SurveysDetailPage';
import MarkUserAgreementPanel from 'pages/staff/certification/MarkUserAgreementPanel';
import DialogModal from 'cyder/modals/DialogModal';

import {
    getCategoryList,
    getProductTypes,
    getSavedCertAppl,
    saveCertApplication,
    forceSetLoading,
    setSavedFlag,
    resetStateToDefault,
} from 'actions/member/certification/applications/memberCertNewApplAction';
import {
    getInvoiceInfo,
    completeApplication,
    updateApplicationFeedback,
    updateGreenRemarks,
    updateBrandsByMember,
} from 'actions/staff/certification/staffCertificationApplicationAction';
import { printCertByCertNumber } from 'actions/shared/certificationAction';
import { getActiveSurveyByCategory } from 'actions/staff/survey/surveyAction';
import { getCountriesGeneric } from 'actions/misc/countryAction.js';

import { sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';

class MemberCertSurveyPage extends React.Component {
    constructor(props) {
        super(props);
        this.viewAssessment = this.viewAssessment.bind(this);
        this.setProductCatalogue = this.setProductCatalogue.bind(this);
        this.setProductPicture = this.setProductPicture.bind(this);
        this.setWhyGreen = this.setWhyGreen.bind(this);
        this.setWhyGreenService = this.setWhyGreenService.bind(this);
        this.confirmCert = this.confirmCert.bind(this);
        this.updateMarkUserAgreementStates = this.updateMarkUserAgreementStates.bind(this);
        this.updateGreenRemarks = this.updateGreenRemarks.bind(this);
        this.updateInternalModelField = this.updateInternalModelField.bind(this);
        this.state = {
            step: 0,
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                modalAction: '',
                type: 'save',
            },
            muaNameError: '',
            muaDesignationError: '',
            muaEmailError: '',
            muaContactNumberError: '',
            muaAgreedTermsError: '1', // default as with error
            muaAgreedError: '1', // default as with error
        };
    }
    componentDidMount() {
        this.props.resetStateToDefault().then(() => {
            this.getDataFromServer();
            // this.setStageOfForm();
        });
    }
    setWhyGreenService(value) {
        let service = this.state.service;
        service.greenRemarks = value;
        this.setState({
            service,
        });
    }
    updateMarkUserAgreementStates(newState) {
        this.setState(newState);
    }
    muaContactNumberChange = e => {
        if (!e.target.value) {
            this.setState({ muaContactNumber: '', muaContactNumberError: 'This field is mandatory' });
        } else {
            this.setState({ muaContactNumber: e.target.value, muaContactNumberError: '' });
        }
    };
    muaEmailChange = e => {
        if (!e.target.value) {
            this.setState({ muaEmail: '', muaEmailError: 'This field is mandatory' });
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
            this.setState({ muaEmail: e.target.value, muaEmailError: 'Please enter a valid email address' });
        } else {
            this.setState({ muaEmail: e.target.value, muaEmailError: '' });
        }
    };
    muaDesignationChange = e => {
        if (!e.target.value) {
            this.setState({ muaDesignation: '', muaDesignationError: 'This field is mandatory' });
        } else {
            this.setState({ muaDesignation: e.target.value, muaDesignationError: '' });
        }
    };
    muaAgreedChange = e => {
        if (!e.target.checked) {
            this.setState({ muaAgreedError: 'This field is mandatory' });
        } else {
            this.setState({ muaAgreedError: '' });
        }
    };
    muaAgreedTermsChanged = e => {
        if (!e.target.checked) {
            this.setState({ muaAgreedTermsError: 'This field is mandatory' });
        } else {
            this.setState({ muaAgreedTermsError: '' });
        }
    };
    muaNameChange = e => {
        if (e.target.value && e.target.value.length > 0) {
            this.setState({ muaName: e.target.value, muaNameError: '' });
        } else {
            this.setState({ muaName: '', muaNameError: 'This field is mandatory' });
        }
    };
    async updateGreenRemarks(greenRemarks) {
        const { type, updateGreenRemarks } = this.props;
        const { id } = this.props.match.params;
        const { ok } = await updateGreenRemarks(id, greenRemarks);
    }
    async updateBrands(brands) {
        const { type, updateBrands } = this.props;
        const { id } = this.props.match.params;
        const { ok } = await updateBrands(id, brands);
    }
    async confirmCert() {
        const { applicationId, productBrands, muaContactNumberError, muaDesignationError, muaEmailError, muaNameError } = this.state;
        const { type, forceSetLoading, updateApplicationFeedback } = this.props;
        const { id } = this.props.match.params;

        if (muaContactNumberError === '' && muaDesignationError === '' && muaEmailError === '' && muaNameError === '') {
            forceSetLoading(true);
            this.markUserAgreement.save();

            if (type === 'product') {
                var brands = productBrands;
                // take the first model why green as the application my green
                const productRes = await this.updateGreenRemarks(brands[0].models[0].greenRemarks);
                // update brands now
                const productBrandRes = await this.updateBrands(brands);
            } else {
                const serviceRes = await this.updateGreenRemarks(this.state.service.greenRemarks);
            }

            const pushTo = () => history.push(`/member/certification/${type}/application/${id}`);
            this.props.forceSetLoading(false);
            this.showModalIf('Confirmation', `You ${type} information has been successfully updated`, true, pushTo);
        }
    }
    updateInternalModelField(model, fieldName, value) {
        var brands = this.state.productBrands;
        if (brands[0].models != null && brands[0].models.length > 0) {
            let models = brands[0].models.map((modelEl, i) => {
                let newModel = Object.assign({}, modelEl);
                if (newModel.name == model.name) {
                    newModel[fieldName] = value;
                }
                return newModel;
            });
            brands[0].models = models;
        }
        this.setState({
            productBrands: brands,
        });
    }
    setWhyGreen(newWhyGreenValue, model) {
        this.updateInternalModelField(model, 'greenRemarks', newWhyGreenValue);
    }
    setProductCatalogue(fileId, filename, model) {
        this.updateInternalModelField(model, 'productCatalogue', fileId);
        this.updateInternalModelField(model, 'productCatalogueFilename', filename);
    }
    setProductPicture(fileId, filename, model) {
        this.updateInternalModelField(model, 'productPicture', fileId);
        this.updateInternalModelField(model, 'productPictureFilename', filename);
    }
    async getDataFromServer() {
        try {
            this.props.forceSetLoading(true);
            const id = this.props.match.params.id;
            const applicationId = this.props.match.params.applicationId;
            const countries = await this.props.getCountriesList();
            //const activeSurveys = await this.props.getActiveSurveyByCategory(4);
            // if (activeSurveys != null && activeSurveys.data != null && activeSurveys.data.length > 0) {
            //     this.setState({
            //         activeSurveys: activeSurveys.data,
            //     });
            // }
            if (applicationId) {
                const data = sanitizeStringAndObjectNullsToEmptyStrings(await this.props.getRelevantData(applicationId, this.props.type));
                if (this.props.type === 'product') {
                    await this.props.getProductTypes(data.productCategoryId);
                }
                let brands = data.brands;

                if (brands && brands.length > 0) {
                    if (data.brands[0].models && data.brands[0].models.length > 0) {
                        // Merging product catalogue, product picture, and why green into brand json
                        let models = data.brands[0].models.map((model, i) => {
                            let newModel = Object.assign({}, model);
                            if (!newModel.productCatalogue) {
                                newModel.productCatalogue = data.productCatalogue;
                            }
                            if (!newModel.productCatalogueFilename) {
                                newModel.productCatalogueFilename = data.productCatalogueFilename;
                            }
                            if (!newModel.productPicture) {
                                newModel.productPicture = data.productPicture;
                            }
                            if (!newModel.productPictureFilename) {
                                newModel.productPictureFilename = data.productPictureFilename;
                            }
                            if (!newModel.greenRemarks) {
                                newModel.greenRemarks = data.greenRemarks;
                            }
                            return newModel;
                        });
                        data.brands[0].models = models;
                    }
                }

                let serviceJson = data.service;
                if (this.props.type === 'service' && serviceJson) {
                    serviceJson = JSON.parse(serviceJson);
                    if (!serviceJson.greenRemarks) {
                        serviceJson.greenRemarks = data.greenRemarks;
                    }
                }

                this.setState({
                    fileLength: 0,
                    status: data.status,
                    applicationId: data.id,
                    productBrands: brands,
                    applicationType: data.applicationTypeId, //1 or 2 new or renew
                    templateId: data.templateId,
                    scoresheetId: data.assessmentId,
                    serviceCategory: data.serviceCategoryValue,
                    manufacturerDetails: data.manufacturerDetails, //name
                    manufacturerNameOfCompany: data.manufacturerNameOfCompany,
                    manufacturerFactoryAddress: data.manufacturerFactoryAddress,
                    manufacturerContactPersonName: data.manufacturerContactPersonName,
                    manufacturerTelephone: data.manufacturerTelephone,
                    manufacturerIsoCode: data.manufacturerIsoCode,
                    manufacturerCountryId: data.manufacturerCountryId,
                    manufacturerCountryName: data.manufacturerCountryName,
                    selectedCategory: data.productCategoryId || data.serviceCategoryId,
                    productCatalogue: data.productCatalogue,
                    productCatalogueFilename: data.productCatalogueFilename,
                    productPicture: data.productPicture,
                    productPictureFilename: data.productPictureFilename,
                    remarks: !data.remarks ? '' : data.remarks,
                    greenRemarks: data.greenRemarks,
                    service: serviceJson,
                });
            }
            this.setState({
                countryList: countries.data,
            });
        } catch (error) {
            throw error;
        } finally {
            this.props.forceSetLoading(false);
            return this.props.getCategoryList(this.props.type);
        }
    }
    viewAssessment() {}
    // MODAL
    modalAction = () => {
        this.modalToggle();
    };
    modalToggle = () => {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    };
    negativeModalAction = () => {
        this.modalToggle();
    };
    showModal = (header, message, type, positiveButtonHide, negativeButtonHide, modalAction) => {
        var modal = this.state.modal;
        modal.modalAction = modalAction || this.modalAction;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    };
    showModalIf(header, message, evaluation, modalAction) {
        if (evaluation) this.showModal(header, message, null, null, true, modalAction);
        return evaluation;
    }
    render() {
        const {
            modal,
            muaNameError,
            muaContactNumberError,
            muaEmailError,
            muaDesignationError,
            muaName,
            muaContactNumber,
            muaDesignation,
            muaEmail,
            muaAgreedError,
            muaAgreedTermsError,
        } = this.state;
        const noError =
            muaContactNumberError === '' &&
            muaDesignationError === '' &&
            muaEmailError === '' &&
            muaNameError === '' &&
            muaAgreedTermsError === '' &&
            muaAgreedError === '' &&
            muaName &&
            muaContactNumber &&
            muaDesignation &&
            muaEmail;
        const title = this.props.type === 'product' ? 'Product' : 'Service';
        if (this.props.application.loading || this.props.application.forceLoading) return <LoadingSpinner center />;
        return (
            <React.Fragment>
                <DialogModal
                    modalAction={modal.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    positiveButtonHide={modal.positiveButtonHide}
                    negativeButtonHide={modal.negativeButtonHide}
                    modalBody={modal.modalMessage}
                    modalHeader={modal.modalHeader}
                    modalOpen={modal.showModal}
                />
                <SurveysDetailPage
                    applicationId={this.props.match.params.applicationId}
                    surveyid={this.props.match.params.id}
                    embedded={true}
                    onRef={ref => {
                        this.surveyComponent = ref;
                    }}
                />
                {/*
                <Container className="mt-4" style={{ marginTop: '30px' }}>
                    <Button disabled={!noError} className={'primary-btn-style'} onClick={this.confirmCert}>
                        <i className="mr-1 material-icons align-text-bottom ">send</i>
                        Submit
                    </Button>
                </Container>
                */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        application: state.memberProductApplication,
        assessment: state.memberProductAssessment,
        certifications: state.memberCertifications,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getRelevantData: (id, type) => {
            const array = [dispatch(getCategoryList(type)), dispatch(getSavedCertAppl(id, type))];
            return Promise.all(array).then(res => res[1].data);
        },
        getCategoryList: type => {
            dispatch(getCategoryList(type));
        },
        getProductTypes: id => {
            return dispatch(getProductTypes(id));
        },
        getCountriesList: () => {
            return dispatch(getCountriesGeneric());
        },
        forceSetLoading: loadingState => {
            dispatch(forceSetLoading(loadingState));
        },
        setSavedFlag: savedThisSession => {
            dispatch(setSavedFlag(savedThisSession));
        },
        setProductCatalogue: (resourceId, filename) => {
            return dispatch({
                type: 'MEMBER_CERTIFICATION_NEW_APPL_SET_PRODUCT_CATALOGUE',
                resourceId,
                filename,
            });
        },
        setProductPicture: (resourceId, filename) => {
            return dispatch({
                type: 'MEMBER_CERTIFICATION_NEW_APPL_SET_PRODUCT_PICTURE',
                resourceId,
                filename,
            });
        },
        saveCertApplication: (type, body, forSubmit) => {
            return dispatch(saveCertApplication(type, body, forSubmit));
        },
        getInvoiceInfo: id => {
            return dispatch(getInvoiceInfo(id));
        },
        setSavedFlag: savedThisSession => {
            dispatch(setSavedFlag(savedThisSession));
        },
        resetStateToDefault: () => {
            return dispatch(resetStateToDefault());
        },
        // getActiveSurveyByCategory: surveyCategoryId => {
        //     return dispatch(getActiveSurveyByCategory(surveyCategoryId));
        // },
        // setPostSurveyUrl: postSurveyUrl => {
        //     return dispatch({
        //         type: 'SHARED_SURVEY_SET_POST_SURVEY_URL',
        //         postSurveyUrl,
        //     });
        // },
        // setApplicationId: applicationId => {
        //     return dispatch({
        //         type: 'SHARED_SURVEY_SET_APPLICATION_ID',
        //         applicationId,
        //     });
        // },
        printCert: (certNumber, type) => {
            return dispatch(printCertByCertNumber(certNumber, type));
        },
        completeApplication: (id, brands, type, service) => {
            return dispatch(completeApplication(id, brands, type, service));
        },
        updateApplicationFeedback: (id, brands, type, service) => {
            return dispatch(updateApplicationFeedback(id, brands, type, service));
        },
        updateGreenRemarks: (id, remarks) => {
            return dispatch(updateGreenRemarks(id, remarks));
        },
        updateBrands: (id, brands) => {
            return dispatch(updateBrandsByMember(id, brands));
        },
    };
};
export default TopNavigationLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(MemberCertSurveyPage),
);

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';

import Select from 'react-select';
import LegendIcon from 'cyder/media/LegendIcon';
import CyderReactTable from 'cyder/table/CyderReactTable';
import SurveyDashboard from './SurveyDashboard';
import { FormGroup, Input, Row, Col, Button } from 'reactstrap';
import { getSurveys, deleteSurvey } from 'actions/staff/survey/surveyAction';

import 'css/cyder/widget/page-widget.css';
import 'css/forms/default-forms.css';

const surveyStatuses = [
    {
        value: 'Current',
        label: 'Current',
    },
    {
        value: 'Past',
        label: 'Past',
    },
    {
        value: 'All',
        label: 'All',
    },
];
class SurveysAllPage extends React.Component {
    updateTableData = () => this.table.fireFetchData();

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            searchable: true,
            clearable: true,
        };
    }

    render() {
        console.log('Category', this.props.survey);
        const columns = [
            {
                Header: 'Name',
                accessor: 'surveyName',
                style: { whiteSpace: 'unset' },
                minWidth: 150,
                maxWidth: 200,
            },
            {
                Header: 'Description',
                accessor: 'surveyDescription',
                style: { whiteSpace: 'unset' },
                minWidth: 200,
            },
            {
                Header: 'Period',
                style: { whiteSpace: 'unset' },
                accessor: 'period',
                maxWidth: 200,
            },
            {
                Header: '',
                sortable: false,
                accessor: 'id',
                maxWidth: 100,
                Cell: props => {
                    return (
                        <Link to={'./surveys/' + props.value}>
                            <Button className="primary-btn-style">
                                <i className="mr-1 material-icons align-text-bottom ">edit</i>
                            </Button>
                        </Link>
                    );
                },
            },
        ];

        return (
            <div className="page-widget">
                <Row>
                    <Col>
                        <SurveyDashboard reloadSurvey={e => this.table.fireFetchData()} />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12}>
                        <div className="form-inline d-flex">
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    value={this.props.survey.control.keyword}
                                    id="search-keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.updateTableData();
                                        }
                                    }}
                                    onChange={this.props.onKeywordChanged}
                                    placeholder="Keyword"
                                />
                            </FormGroup>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Select
                                    className="w-200 ml-2"
                                    autosize={false}
                                    options={surveyStatuses}
                                    value={this.props.survey.control.status}
                                    clearable={this.state.clearable}
                                    onChange={this.props.onStatusChanged}
                                    name="selected-state"
                                    disabled={this.state.disabled}
                                    searchable={this.state.searchable}
                                />
                            </FormGroup>
                            <Button className="ml-2 align-middle primary-btn-style" onClick={this.updateTableData}>
                                <i className="material-icons align-text-bottom">search</i>
                                &nbsp;Search
                            </Button>
                            <div className="ml-auto">
                                <Link to="./surveys/create">
                                    <Button className="primary-btn-style">
                                        <i className="mr-1 material-icons align-text-bottom">add</i> Create New Survey
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            manual
                            className="-highlight mb-2"
                            data={this.props.survey.data.surveys}
                            columns={columns}
                            minRows={0}
                            showPagination={true}
                            defaultPageSize={this.props.survey.control.pageSize}
                            ref={instance => {
                                if (instance != null) this.table = instance.table;
                            }}
                            totalNum={this.props.survey.data.totalSurveyCount}
                            filterable={false}
                            pages={this.props.survey.data.totalSurveyPages}
                            loading={this.props.survey.control.loading}
                            onFetchData={(state, instance) => {
                                this.props.getSurveys(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const StatusRow = () => (
    <Row className="mb-2">
        <Col xs={12}>
            <LegendIcon status="draft" text="Draft" />
            <LegendIcon status="submitted" text="Submitted" />
            <LegendIcon status="processing" text="Processing" />
            <LegendIcon status="approved_pending_payment" text="Approved & Pending Payment" />
            <LegendIcon status="complete" text="Complete" />
            <LegendIcon status="not_approved" text="Not Approved" />
            <LegendIcon status="withdrawn" text="Withdrawn" />
            <LegendIcon status="discontinued" text="Discontinued" />
        </Col>
    </Row>
);

const mapStateToProps = (state, ownProps) => {
    return {
        survey: state.staffSurvey,
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onKeywordChanged: e => {
            const keyword = e.target.value;
            dispatch({ type: 'STAFF_SURVEY_SET_KEYWORD', keyword });
        },
        onStatusChanged: newStatus => {
            dispatch({ type: 'STAFF_SURVEY_SET_STATUS', status: newStatus });
        },
        deleteSurvey: id => {
            dispatch(deleteSurvey(id));
        },
        getSurveys: (page, pageSize, sorted, filtered) => {
            dispatch(getSurveys(page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(SurveysAllPage));

import React from 'react';
// import validationlib from '../../../js/validation.js'
import {
    Input,
    // Table,
    Col,
    FormFeedback,
    Button,
    ListGroup,
    ListGroupItem,
} from 'reactstrap';

const ChosenPermissionsPanel = ({
    permissionsList,
    removePermission,
    // this.props.fields.permissions
}) => {
    return (
        <div>
            <h6>Chosen permissions</h6>
            <ListGroup>
                {permissionsList.map((item, i) => (
                    <ListGroupItem className="d-flex" key={i}>
                        {item.label}
                        <Button
                            color="danger"
                            size="sm"
                            className="ml-auto"
                            onClick={() => removePermission(item.value)}
                        >
                            Remove
                        </Button>
                    </ListGroupItem>
                ))}
            </ListGroup>
        </div>
    );
};

export default ChosenPermissionsPanel;

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import moment from 'moment';
import withQuery from 'with-query';

import { Card, CardBody, Container, FormGroup, Label, Input, Row, Col, Form, Button, Badge } from 'reactstrap';
import DefaultSidebar1 from '../../../layouts/DefaultSidebar1';
import LoadingSpinner from '../../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../cyder/modals/DialogModal';
import LegendIcon from '../../../cyder/media/LegendIcon';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

import { search } from 'actions/staff/election/action';

class ElectionsAllPage extends React.Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.onKeywordChange = this.onKeywordChange.bind(this);
        this.state = {
            list: [],
            loading: false,
            pagination: true,
            pageSize: 10,
            pageCount: 0,
            keyword: '',
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    onKeywordChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    async search(page, pageSize, sorted, filtered) {
        this.showLoading(true);
        try {
            const res = await this.props.search(this.state.keyword, page, pageSize, sorted, filtered);
            if (this._isMounted) {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: res.totalNoOfPages,
                    totalNum: res.totalNoOfItems,
                });
            }
        } catch (error) {
            this.showLoading(false);
            throw error;
        }
    }
    render() {
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 300,
            },
            {
                Header: 'Start Date',
                accessor: 'startDate',
                style: { whiteSpace: 'unset' },
                minWidth: 100,
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    return moment(props.value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
                },
            },
            {
                Header: 'End Date',
                accessor: 'endDate',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                minWidth: 100,
                Cell: props => {
                    return moment(props.value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
                },
            },
            {
                Header: 'Public Sector Vacancies',
                accessor: 'publicSectorVacancies',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                minWidth: 100,
            },
            {
                Header: 'Private Sector Vacancies',
                headerStyle: { whiteSpace: 'unset' },
                accessor: 'privateSectorVacancies',
                style: { whiteSpace: 'unset' },
                minWidth: 100,
            },
            {
                Header: '',
                sortable: false,
                accessor: 'id',
                maxWidth: 100,
                Cell: props => {
                    return (
                        // <Link to={groupDetailsUrlPrefix + props.row.id}>
                        <Link to={'./edit/' + props.value}>
                            <Button className="ml-2 align-middle primary-btn-style primary-btn-xs">
                                <i className="mr-1 material-icons align-text-bottom ">edit</i>
                                Edit
                            </Button>
                        </Link>
                    );
                },
            },
            {
                Header: '',
                sortable: false,
                accessor: 'id',
                maxWidth: 100,
                Cell: props => {
                    return (
                        // <Link to={groupDetailsUrlPrefix + props.row.id}>
                        <Link to={'./view/' + props.value}>
                            <Button className="ml-2 align-middle primary-btn-style primary-btn-xs">
                                <i className="mr-1 material-icons align-text-bottom ">folder_open</i>
                                View
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <div className="page-widget">
                <Row>
                    <Col xs={12}>
                        <h3>All Elections</h3>
                    </Col>
                </Row>
                <br />
                <Row className="mb-2">
                    <Col xs={12}>
                        <div className="form-inline d-flex">
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    id="search-keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) this.table.fireFetchData();
                                    }}
                                    placeholder="Keyword"
                                    value={this.state.keyword}
                                    onChange={this.onKeywordChange}
                                />
                            </FormGroup>
                            <Button className="ml-2 align-middle primary-btn-style" onClick={e => this.table.fireFetchData()}>
                                <i className="material-icons align-text-bottom">search</i>
                                Search
                            </Button>
                            <div className="ml-auto">
                                <Link to="./create">
                                    <Button className="ml-2 align-middle primary-btn-style">
                                        <i className="mr-1 material-icons align-text-bottom">add</i> Create New Election
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const StatusRow = () => (
    <Row className="mb-2">
        <Col xs={12}>
            <LegendIcon status="draft" text="Draft" />
            <LegendIcon status="submitted" text="Submitted" />
            <LegendIcon status="processing" text="Processing" />
            <LegendIcon status="approved_pending_payment" text="Approved & Pending Payment" />
            <LegendIcon status="complete" text="Complete" />
            <LegendIcon status="not_approved" text="Not Approved" />
            <LegendIcon status="withdrawn" text="Withdrawn" />
            <LegendIcon status="discontinued" text="Discontinued" />
        </Col>
    </Row>
);

const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, page, pageSize, sorted, filtered) => {
            return dispatch(search(keyword, page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DefaultSidebar1(ElectionsAllPage));
